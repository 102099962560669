import { ReactNode } from "react";

export interface HeaderDefinition<T extends object> {
  id: number | string;
  title: string;
  isAdminOnly: boolean;
  isActive?: boolean;
  isDefaultActive?: boolean;
  custom_field_id?: number;
  format: (data: T) => ReactNode;
}

export enum DefaultHeaderTitles {
  EID = "Equipment ID",
  TYPE = "Type",
  MANUFACTURER = "Manufacturer",
  DATE = "Date",
  SERIAL = "Serial Number",
  PART_NUMBER = "Part Number",
  MATERIAL = "Material of Construction",
}

export interface RowDefinition {
  id: number;
}
