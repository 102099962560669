import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import {
  conditionNameMap,
  getConditionsForType,
} from "../../../Settings/CustomAlertsSection/helpers";
import { FieldProps } from "../../../Settings/CustomAlertsSection/types";

type Props = {
  fieldType: FieldProps["type"];
  onChange: (cond: string) => void;
  selectedCondition: string | null;
};

const ConditionSelect: FC<Props> = ({
  fieldType,
  onChange,
  selectedCondition,
}) => {
  return (
    <Box>
      <FormControl margin="dense" fullWidth>
        <InputLabel>Condition</InputLabel>
        <Select
          value={selectedCondition}
          label="Condition"
          onChange={(e) => onChange(e.target.value as string)}
        >
          {getConditionsForType(fieldType).map((cond) => (
            <MenuItem value={cond} key={cond}>
              {conditionNameMap[cond]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ConditionSelect;
