import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MultiSectionDigitalClock,
} from "@mui/x-date-pickers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const BasicDatePicker: React.FC<{
  label: string;
  value: Dayjs | null;
  handleOnChange: (newValue: Dayjs | null) => void;
  type?: "date" | "dateTime" | "time" | "multiSectionDigitalClock";
}> = ({ label, value, handleOnChange, type = "date" }) => {
  const [openSectionDigitalClock, setOpenSectionDigitalClock] =
    React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth>
        <FormLabel htmlFor={`clock-${label}`}>{label}</FormLabel>
        {type === "dateTime" && (
          <DateTimePicker
            value={value || null}
            onChange={(newValue: any) => {
              handleOnChange(newValue);
            }}
            format="DD-MMM-YYYY HH:mm"
          />
        )}
        {type === "date" && (
          <DatePicker
            sx={{ width: "100%" }}
            value={value ? dayjs(value) : null}
            onChange={(newValue: any) => {
              handleOnChange(newValue);
            }}
            format="DD-MMM-YYYY"
          />
        )}
        {type === "time" && (
          <TimePicker
            label={label}
            value={value}
            onChange={(newValue: any) => {
              handleOnChange(newValue);
            }}
            format="HH:mm"
          />
        )}
        {type === "multiSectionDigitalClock" && (
          <Box>
            <Box
              sx={{
                border: "1px solid #c4c4c4",
                borderRadius: "4px",
                padding: "8px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                setOpenSectionDigitalClock(true);
              }}
            >
              {value ? dayjs(value).format("HH:mm") : "00:00"}
              <AccessTimeIcon
                style={{
                  color: "#7f7f7f",
                }}
              />
            </Box>
            <Dialog
              open={openSectionDigitalClock}
              onClose={() => {
                setOpenSectionDigitalClock(false);
              }}
            >
              <DialogTitle>{label}</DialogTitle>
              <DialogContent>
                <MultiSectionDigitalClock
                  slotProps={{
                    digitalClockSectionItem: {
                      id: `clock-${label}`,
                    },
                  }}
                  value={value}
                  onChange={(newValue: any) => {
                    handleOnChange(newValue);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenSectionDigitalClock(false);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </FormControl>
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
