import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { CommonParams } from "../../types/common";
import { EquipmentTableHeaders } from "../../pages/Equipments/types";
import useFieldFilters from "./useFieldFilters";
import useSortBy from "./useSortBy";
import { getDefaultFieldName } from "../../pages/Equipments/helpers";

const useActiveFields = () => {
  const { filters, getFilterString } = useFieldFilters();
  const { sortBy } = useSortBy();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeFields = useMemo(() => {
    const activeFieldsParam = searchParams.get(CommonParams.ActiveFields) || "";

    const split = activeFieldsParam.split(",").filter(Boolean);
    const activeFields = new Set(split.map((p) => p.split(":")[0]));

    return activeFields;
  }, [searchParams]);

  const addActiveFieldsInParams = (headers: EquipmentTableHeaders) => {
    const activeFieldsParams = headers
      .filter((h) => h.isActive)
      .map((h) => `${h.title}:${h.id}`);

    const activeFieldsSet = new Set([
      ...activeFieldsParams.map((p) => p.split(":")[0]),
      ...activeFieldsParams
        .map((p) => getDefaultFieldName(p.split(":")[0]))
        .filter((v) => !!v),
    ]);

    // remove filters related to hidden columns
    const possibleFilters = filters.filter((f) => activeFieldsSet.has(f.name));
    if (possibleFilters.length) {
      searchParams.set(
        CommonParams.Filters,
        possibleFilters
          .map((f) => getFilterString(f.name, f.cond, f.value, f.type))
          .join(",")
      );
    } else {
      searchParams.delete(CommonParams.Filters);
    }

    // remove sort by items related to hidden columns
    const possibleSortBy = sortBy.filter((s) =>
      activeFieldsSet.has(s.split(":")[0])
    );

    if (possibleSortBy.length) {
      searchParams.set(CommonParams.SortBy, possibleSortBy.join(","));
    } else {
      searchParams.delete(CommonParams.SortBy);
    }

    searchParams.set(CommonParams.ActiveFields, activeFieldsParams.join(","));
    setSearchParams(searchParams);
  };

  const removeActiveFieldsParam = () => {
    searchParams.delete(CommonParams.ActiveFields);
    setSearchParams(searchParams);
  };

  return {
    activeFields,
    addActiveFieldsInParams,
    removeActiveFieldsParam,
  };
};

export default useActiveFields;
