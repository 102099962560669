import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";

import {
  CREATE_PROCESS_AREA,
  UPDATE_PROCESS_AREA,
  DELETE_PROCESS_AREA,
} from "./graphql";
import { useMutation } from "@apollo/client";
import ConfirmDialog from "../../components/ConfirmDialog";
import { ProcessArea, ProcessAreaRole } from "./types";
import { LogType } from "../../../types/logger";
import { useLogger } from "../../../hooks/useLogger";

const emptyProcessArea: ProcessArea = {
  id: 0,
  name: "",
  process_area_roles: [],
};

const AreaModal = ({
  open,
  onClose,
  extProcessArea,
  onSubmitCB,
  availableRoles,
}: {
  open: boolean;
  onClose: () => void;
  extProcessArea: ProcessArea | null;
  onSubmitCB?: () => void;
  availableRoles: ProcessAreaRole[];
}) => {
  const { logEvent } = useLogger();

  const isEditing = Boolean(extProcessArea);

  const [processArea, setProcessArea] = useState<ProcessArea>(emptyProcessArea);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const closeModal = async () => {
    onClose();
    setOpenConfirmModal(false);
    setProcessArea(emptyProcessArea);

    setTimeout(() => {
      setLoading(false);
    }, 350);
  };

  const beforeOnSubmitCB = () => {
    if (onSubmitCB) onSubmitCB();

    closeModal();
  };

  const [updateProcessArea] = useMutation(UPDATE_PROCESS_AREA, {
    onCompleted: () => {
      logEvent(LogType.PROCESS_AREA, {
        message: "Process area updated",
        resourceId: extProcessArea?.id,
      });
    },
  });
  const [createProcessArea] = useMutation(CREATE_PROCESS_AREA, {
    onCompleted: ({ createProcessArea: { data } }) => {
      logEvent(LogType.PROCESS_AREA, {
        message: "Process area created",
        resourceId: data?.id,
      });
    },
  });

  const [deleteField] = useMutation(DELETE_PROCESS_AREA, {
    onCompleted: () => {
      logEvent(LogType.PROCESS_AREA, {
        message: "Process area deleted",
        resourceId: extProcessArea?.id,
      });

      beforeOnSubmitCB();
    },
  });

  const saveProcessArea = async () => {
    setLoading(true);

    if (isEditing) {
      await updateProcessArea({
        variables: {
          id: processArea.id,
          name: processArea.name,
          process_area_roles:
            processArea.process_area_roles?.map((a) => a.id) || [],
        },
      });
    } else {
      await createProcessArea({
        variables: {
          name: processArea.name,
          process_area_roles:
            processArea.process_area_roles?.map((a) => a.id) || [],
        },
      });
    }

    beforeOnSubmitCB();
  };

  React.useEffect(() => {
    if (extProcessArea) {
      setProcessArea(extProcessArea);
    } else {
      setProcessArea(emptyProcessArea);
    }
  }, [extProcessArea, open]);

  const renderDialogBody = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
            width: 400,
            maxWidth: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <TextField
              label="Name"
              autoFocus
              value={processArea.name}
              onChange={(event) => {
                setProcessArea({
                  ...processArea,
                  name: event.target.value,
                });
              }}
              margin="dense"
              fullWidth
            />
          </FormControl>
          <FormControl margin="dense" fullWidth>
            <Autocomplete
              disabled={loading}
              multiple
              value={processArea.process_area_roles}
              onChange={(_, v) => {
                if (v) {
                  setProcessArea((prev) => ({
                    ...prev,
                    process_area_roles: v,
                  }));
                }
              }}
              options={availableRoles}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Roles" />}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterSelectedOptions
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={closeModal}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{isEditing ? "Edit" : "Create"} Process Area</span>
        </DialogTitle>
        <DialogContent
          style={{
            width: "400px",
            maxWidth: "100%",
          }}
        >
          {renderDialogBody()}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 24px 20px",
          }}
        >
          <div>
            {isEditing && (
              <Button
                disabled={loading}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenConfirmModal(true);
                }}
                color="error"
              >
                Delete
              </Button>
            )}
          </div>
          <div>
            <Button
              disabled={loading}
              onClick={(event) => {
                event.stopPropagation();
                closeModal();
              }}
            >
              Cancel
            </Button>
            <span>
              <Button
                disabled={loading}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  saveProcessArea();
                }}
              >
                {isEditing ? "Update" : "Create"}
              </Button>
            </span>
          </div>
        </DialogActions>
        <ConfirmDialog
          openModal={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          confirmHandler={() => {
            if (isEditing) {
              setLoading(true);
              deleteField({
                variables: {
                  id: processArea.id,
                },
              });

              setOpenConfirmModal(false);
            }
          }}
          text="Do you really want to delete this process area?"
        />
      </Dialog>
    </div>
  );
};

export default AreaModal;
