import React from "react";

export enum SStorageKey {
  EQ_TABLE_PARAMS = "eq-table-params",
}

export const useSessionStorage = (storageKey: SStorageKey) => {
  const initialValue = React.useMemo(() => {
    const stored = window.sessionStorage.getItem(storageKey);
    return stored;
  }, [storageKey]);

  const [stored, setStored] = React.useState(initialValue);

  React.useEffect(() => {
    if (stored) {
      window.sessionStorage.setItem(storageKey, stored);
    } else {
      window.sessionStorage.removeItem(storageKey);
    }
  }, [stored, storageKey]);

  return {
    stored,
    setStored,
    clearStorage: () => window.sessionStorage.clear(),
    clearItem: () => window.sessionStorage.removeItem(storageKey),
  };
};
