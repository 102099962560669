import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  FieldProps,
  FieldsMap,
} from "../../../Settings/CustomAlertsSection/types";
import { FC, useMemo } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { selectCustomFields } from "../../../../redux/reducers/custom-field";
import { getFieldsMap } from "../../../Settings/CustomAlertsSection/helpers";
import { DEFAULT_FIELDS_MAP } from "../../constants";

type Props = {
  onChange: (field: FieldProps) => void;
  selectedField: FieldProps | null;
  disabled?: boolean;
};

const FieldSelect: FC<Props> = ({
  onChange,
  selectedField,
  disabled = false,
}) => {
  const customEquipmentFields = useAppSelector(selectCustomFields);
  const fieldsMap = useMemo(
    () => getFieldsMap(customEquipmentFields),
    [customEquipmentFields]
  );

  return (
    <Box>
      <FormControl margin="dense" fullWidth>
        <InputLabel>Field</InputLabel>
        <Select
          disabled={disabled}
          label="Field"
          value={
            selectedField?.relation === "equipment"
              ? DEFAULT_FIELDS_MAP[
                  selectedField.name as keyof typeof DEFAULT_FIELDS_MAP
                ]
              : `${selectedField?.id}-${selectedField?.name}`
          }
          onChange={(e) => {
            const field = fieldsMap[e.target.value as keyof FieldsMap];
            onChange(field);
          }}
        >
          {Object.keys(fieldsMap).map((key) => (
            <MenuItem value={key} key={key}>
              {fieldsMap[key].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FieldSelect;
