import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { ROUTES } from "../../Router";
import MuiLink from "../components/MuiLink";

const theme = createTheme();

const ForgotPassword = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Box sx={{ mt: 1 }}>
            <Typography component="h1" variant="h5">
              Check your email
            </Typography>
            <Typography component="h2" variant="body1">
              We've sent you an email with instructions to reset your password.
            </Typography>

            <MuiLink to={ROUTES.SIGN_IN} variant="body2">
              Sign in
            </MuiLink>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ForgotPassword;
