import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EQUIPMENT_HEADERS } from "../Equipments/constants";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_DEFAULT_FIELDS_CONFIG,
  UPDATE_DEFAULT_FIELDS_CONFIG,
} from "./graphql";
import { FC, useRef, useState } from "react";
import { Organization } from "../../redux/reducers/user/types";
import { DefaultHeaderTitles } from "../../types/table";
import { LogType } from "../../types/logger";
import { useLogger } from "../../hooks/useLogger";

type Props = {
  selectedOrgId: number;
};

const EqFieldsSection: FC<Props> = ({ selectedOrgId }) => {
  const { logEvent } = useLogger();
  const [defaultFieldsMap, setDefaultFieldsMap] = useState<
    Record<string | number, { title: string; isAdminOnly: boolean }>
  >({});

  const isConfigModified = useRef(false);

  const { loading, refetch } = useQuery(
    GET_DEFAULT_FIELDS_CONFIG(selectedOrgId),
    {
      onCompleted: (data) => {
        const { default_fields_config: fieldsConfig } =
          data.organization.data.attributes;

        setDefaultFieldsMap(
          EQUIPMENT_HEADERS.filter(
            (h) => h.title !== DefaultHeaderTitles.EID
          ).reduce<
            Record<string | number, { title: string; isAdminOnly: boolean }>
          >((acc, curr) => {
            acc[curr.id] = {
              title: curr.title,
              isAdminOnly: fieldsConfig?.[curr.title].admin_only || false,
            };

            return acc;
          }, {})
        );
      },
    }
  );

  const [updateConfig] = useMutation(UPDATE_DEFAULT_FIELDS_CONFIG, {
    onCompleted: () => {
      logEvent(LogType.EQUIPMENT, {
        message: "Default fields config updated",
      });

      refetch();
      isConfigModified.current = false;
    },
  });

  const handleConfigSave = () => {
    const newConfig = Object.values(defaultFieldsMap).reduce<
      NonNullable<Organization["default_fields_config"]>
    >((acc, curr) => {
      acc[curr.title] = { admin_only: curr.isAdminOnly };
      return acc;
    }, {});

    updateConfig({
      variables: { id: selectedOrgId, newConfig },
    });
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            padding="20px"
            justifyContent="space-between"
            alignItems="center"
            gap="8px"
          >
            <Typography variant="h6" component="h6">
              Default Equipment Fields
            </Typography>
            <Button
              disabled={!isConfigModified.current}
              variant="contained"
              color="primary"
              onClick={handleConfigSave}
            >
              Save changes
            </Button>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="Equipments table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Visibility</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(defaultFieldsMap).map(
              ([id, { title, isAdminOnly }]) => (
                <TableRow key={id}>
                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(_, isAdminOnly) => {
                            setDefaultFieldsMap((prev) => ({
                              ...prev,
                              [id]: { ...prev[id], isAdminOnly },
                            }));
                            isConfigModified.current = true;
                          }}
                          checked={isAdminOnly}
                        />
                      }
                      label="Administrators Only"
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EqFieldsSection;
