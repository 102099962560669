import { FC } from "react";
import parse from "html-react-parser";
import useSearchQuery from "../../../hooks/useSearchQuery";
import { useAppSelector } from "../../../redux/hooks";
import { selectUserState } from "../../../redux/reducers/user";
import { obfuscateString } from "../helpers";

type Props = {
  children: string;
  isFieldAdminOnly?: boolean;
};

const HighlightedText: FC<Props> = ({ children, isFieldAdminOnly = false }) => {
  const { user } = useAppSelector(selectUserState);
  const { queryString, isQueryInParams } = useSearchQuery();

  const isAdmin = user?.role.type === "administrator";

  if (!isAdmin && isFieldAdminOnly) return obfuscateString(children);

  return (
    <span style={{ color: isFieldAdminOnly ? "darkgray" : "inherit" }}>
      {isQueryInParams ? highlightText(children, queryString) : children}
    </span>
  );
};

const highlightText = (text: string, query: string) => {
  if (!text) return;

  const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(escapedQuery, "gi");

  const highlightedText = text.replace(
    regex,
    (match) => `<span style="background-color: yellow">${match}</span>`
  );

  return parse(highlightedText);
};

export default HighlightedText;
