import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AlertColorPicker from "./AlertColorPicker";

import {
  CREATE_CUSTOM_ALERT,
  UPDATE_CUSTOM_ALERT,
  DELETE_CUSTOM_ALERT,
} from "./graphql";
import { useMutation } from "@apollo/client";
import ConfirmDialog from "../../components/ConfirmDialog";
import { CustomAlertProps, FieldsMap, NotificationType } from "./types";
import { getConditionName, getConditionsForType } from "./helpers";
import BasicDatePicker from "../../components/BasicDatePicker";
import { LogType } from "../../../types/logger";
import { useLogger } from "../../../hooks/useLogger";

const emptyAlert: CustomAlertProps = {
  id: 0,
  relation: "equipment",
  type: "text",
  condition: "equals",
  field_key: "",
  value: "",
  color: "#d32f2f",
  enable_notifications: false,
  notification_type: NotificationType.EMAIL,
  notification_recipients: "",
  notification_subject: "",
  notification_body: "",
};

const CustomAlertModal = ({
  open,
  onClose,
  extAlert,
  onSubmitCB,
  fieldsMap,
}: {
  open: boolean;
  onClose: () => void;
  extAlert: CustomAlertProps | null;
  onSubmitCB?: () => void;
  fieldsMap: FieldsMap;
}) => {
  const { logEvent } = useLogger();

  const isEditing = Boolean(extAlert);

  const [alert, setAlert] = useState<CustomAlertProps>(emptyAlert);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const closeModal = async () => {
    onClose();
    setOpenConfirmModal(false);
    setAlert(emptyAlert);

    setTimeout(() => {
      setLoading(false);
    }, 350);
  };

  const beforeOnSubmitCB = () => {
    if (onSubmitCB) onSubmitCB();

    closeModal();
  };

  const [updateAlert] = useMutation(UPDATE_CUSTOM_ALERT, {
    onCompleted: () => {
      logEvent(LogType.CUSTOM_ALERT, {
        message: "Alert updated",
        resourceId: extAlert?.id,
      });
    },
  });
  const [createAlert] = useMutation(CREATE_CUSTOM_ALERT, {
    onCompleted: ({ createCustomAlert: { data } }) => {
      logEvent(LogType.CUSTOM_ALERT, {
        message: "Alert created",
        resourceId: data?.id,
      });
    },
  });

  const [deleteField] = useMutation(DELETE_CUSTOM_ALERT, {
    onCompleted: () => {
      logEvent(LogType.CUSTOM_ALERT, {
        message: "Alert deleted",
        resourceId: extAlert?.id,
      });

      beforeOnSubmitCB();
    },
  });

  const saveAlert = async () => {
    setLoading(true);

    if (isEditing) {
      await updateAlert({
        variables: {
          id: alert.id,
          relation: alert.relation,
          type: alert.type,
          condition: alert.condition,
          field_key: alert.field_key,
          value: alert.value,
          color: alert.color,
          custom_equipment_field: alert.custom_equipment_field?.id || null,
          enable_notifications: alert.enable_notifications,
          notification_type: alert.notification_type,
          notification_recipients: alert.notification_recipients,
          notification_subject: alert.notification_subject,
          notification_body: alert.notification_body,
        },
      });
    } else {
      await createAlert({
        variables: {
          relation: alert.relation,
          type: alert.type,
          condition: alert.condition,
          field_key: alert.field_key,
          value: alert.value,
          color: alert.color,
          custom_equipment_field: alert.custom_equipment_field?.id || null,
          enable_notifications: alert.enable_notifications,
          notification_type: alert.notification_type,
          notification_recipients: alert.notification_recipients,
          notification_subject: alert.notification_subject,
          notification_body: alert.notification_body,
        },
      });
    }

    beforeOnSubmitCB();
  };

  React.useEffect(() => {
    if (extAlert) {
      setAlert(extAlert);
    } else {
      setAlert(emptyAlert);
    }
  }, [extAlert, open]);

  const renderValueInput = () => {
    if (!alert.field_key) return null;

    switch (alert.type) {
      case "text":
        return (
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth>
              <TextField
                id="value"
                label="Value"
                value={alert.value || ""}
                onChange={(newValue: any) => {
                  setAlert({ ...alert, value: newValue.target.value });
                }}
              />
            </FormControl>
          </Grid>
        );
      case "date":
        return (
          <Grid item xs={12}>
            <BasicDatePicker
              label="Value"
              value={(alert.value as any) || null}
              handleOnChange={(newValue: any) => {
                setAlert({ ...alert, value: newValue });
              }}
            />
          </Grid>
        );
      case "number":
        return (
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth>
              <TextField
                id="value"
                label="Value"
                type="number"
                value={alert.value || ""}
                onChange={(newValue: any) => {
                  setAlert({ ...alert, value: newValue.target.value });
                }}
              />
            </FormControl>
          </Grid>
        );
      case "boolean":
        let label = "Value";

        if (alert.relation === "maintenance_request") {
          label = "Requires maintenance";
        }

        return (
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth>
              <InputLabel id="value-label">{label}</InputLabel>
              <Select
                labelId="value"
                id="value"
                value={alert.value}
                label={label}
                onChange={(newValue: any) => {
                  setAlert({ ...alert, value: newValue.target.value });
                }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      default:
        return null;
    }
  };

  const renderNotificationSection = () => {
    if (!alert.enable_notifications) return null;

    return (
      <Grid container>
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <InputLabel id="notification-type-label">Type</InputLabel>
            <Select
              labelId="notification-type"
              id="notification-type"
              value={alert.notification_type}
              label="Type"
              onChange={(newValue: any) => {
                setAlert({
                  ...alert,
                  notification_type: newValue.target.value,
                });
              }}
            >
              {Object.keys(NotificationType).map((key) => (
                <MenuItem value={key} key={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <TextField
              id="notification-recipients"
              placeholder="Comma separated list of recipients"
              label="Recipients"
              value={alert.notification_recipients || ""}
              onChange={(newValue: any) => {
                setAlert({
                  ...alert,
                  notification_recipients: newValue.target.value,
                });
              }}
              onBlur={(event) => {
                if (
                  alert.notification_type === NotificationType.SMS &&
                  event.target.value
                ) {
                  const recipients = event.target.value.split(",").map((r) => {
                    r = r.trim();

                    if (!r.startsWith("+")) {
                      return `+1${r}`;
                    }

                    return r;
                  });

                  setAlert({
                    ...alert,
                    notification_recipients: recipients.join(", "),
                  });
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <TextField
              id="notification-subject"
              label="Subject"
              value={alert.notification_subject || ""}
              onChange={(newValue: any) => {
                setAlert({
                  ...alert,
                  notification_subject: newValue.target.value,
                });
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <TextField
              id="notification-body"
              label="Message"
              multiline
              rows={4}
              value={alert.notification_body || ""}
              onChange={(newValue: any) => {
                setAlert({
                  ...alert,
                  notification_body: newValue.target.value,
                });
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const renderDialogBody = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
            width: 400,
            maxWidth: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <InputLabel id="relation-label">Field</InputLabel>
            <Select
              labelId="field"
              id="field"
              value={alert.field_key}
              label="Relation"
              onChange={(newValue: any) => {
                const field = fieldsMap[newValue.target.value];

                setAlert({
                  ...alert,
                  relation: field.relation,
                  field_key: newValue.target.value,
                  custom_equipment_field: field.custom_equipment_field,
                  type: field.type,
                  condition: getConditionsForType(field.type)[0],
                  value: "",
                });
              }}
            >
              {Object.keys(fieldsMap).map((key) => (
                <MenuItem value={key} key={key}>
                  {fieldsMap[key].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {alert.field_key && (
          <Grid item xs={12}>
            <FormControl margin="dense" fullWidth>
              <InputLabel id="condition-label">Condition</InputLabel>
              <Select
                labelId="condition"
                id="condition"
                value={alert.condition}
                label="Condition"
                disabled={getConditionsForType(alert.type).length < 2}
                onChange={(newValue: any) => {
                  setAlert({
                    ...alert,
                    condition: newValue.target.value,
                  });
                }}
              >
                {getConditionsForType(alert.type).map((condition) => (
                  <MenuItem value={condition} key={condition}>
                    {getConditionName(condition)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {renderValueInput()}
        <Grid item xs={12}>
          {alert.field_key && (
            <AlertColorPicker
              selectedColor={alert.color}
              setSelectedColor={(newColor: string) => {
                setAlert({
                  ...alert,
                  color: newColor,
                });
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={alert.enable_notifications || false}
                onChange={(event) => {
                  setAlert({
                    ...alert,
                    enable_notifications: event.target.checked,
                  });
                }}
                name="enable_notifications"
                color="primary"
              />
            }
            label="Enable Notifications"
          />
          {renderNotificationSection()}
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={closeModal}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{isEditing ? "Edit" : "Create"} Custom Alert</span>
          <ErrorOutlineIcon
            style={{
              color: alert.color,
              marginLeft: 10,
            }}
            fontSize="large"
          />
        </DialogTitle>
        <DialogContent
          style={{
            width: "400px",
            maxWidth: "100%",
          }}
        >
          {renderDialogBody()}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 24px 20px",
          }}
        >
          <div>
            {isEditing && (
              <Button
                disabled={loading}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenConfirmModal(true);
                }}
                color="error"
              >
                Delete
              </Button>
            )}
          </div>
          <div>
            <Button
              disabled={loading}
              onClick={(event) => {
                event.stopPropagation();
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Tooltip
              title={
                !alert.field_key
                  ? "Please select a field"
                  : !alert.value
                  ? "Please enter a value"
                  : ""
              }
              placement="top"
            >
              <span>
                <Button
                  disabled={loading || !alert.value}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    saveAlert();
                  }}
                >
                  {isEditing ? "Update" : "Create"}
                </Button>
              </span>
            </Tooltip>
          </div>
        </DialogActions>
        <ConfirmDialog
          openModal={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          confirmHandler={() => {
            if (isEditing) {
              setLoading(true);
              deleteField({
                variables: {
                  id: alert.id,
                },
              });

              setOpenConfirmModal(false);
            }
          }}
          text="Do you really want to delete this custom alert?"
        />
      </Dialog>
    </div>
  );
};

export default CustomAlertModal;
