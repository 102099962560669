import { useCallback, useState } from "react";
import { RowDefinition } from "../../types/table";

const useCheckboxSelection = (rows: RowDefinition[]) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleSelectAllRowsClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);

        setSelectedRows(newSelected);
        return;
      }

      setSelectedRows([]);
    },
    [rows]
  );

  const handleSelectRowClick = useCallback(
    (e: React.MouseEvent<unknown>, id: number) => {
      e.stopPropagation();

      const selectedIndex = selectedRows.indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }
      setSelectedRows(newSelected);
    },
    [selectedRows]
  );

  const getIsSelectedRow = (id: number) => selectedRows.indexOf(id) !== -1;

  return {
    selectedRows,
    handleSelectAllRowsClick,
    handleSelectRowClick,
    getIsSelectedRow,
  };
};

export default useCheckboxSelection;
