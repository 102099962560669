import React, { useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import * as XLSX from "xlsx";

import { selectCustomFields } from "../../../redux/reducers/custom-field";
import { useAppSelector } from "../../../redux/hooks";
import { UPLOAD_EQUIPMENTS } from "../graphql";
import PreviewDialog from "./PreviewDialog";
import { useLogger } from "../../../hooks/useLogger";
import { LogType } from "../../../types/logger";

const UploadDialog: React.FC<{
  onUpload: (data: any) => void;
}> = ({ onUpload }) => {
  const { logEvent } = useLogger();

  const customFields = useAppSelector(selectCustomFields);
  const equipmentRowHeader = React.useMemo(() => {
    return [
      "Equipment ID",
      "Type",
      "Manufacturer",
      "Date of manufacture",
      "Serial number",
      "Part number",
      "Material of construction",
      ...customFields.map((field) => {
        return field.name;
      }),
    ];
  }, [customFields]);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [showUploadPreview, setShowUploadPreview] = useState<boolean>(false);
  const [modalErrMsg, setModalErrMsg] = useState<string>("");

  const handleFileChange = (file: any) => {
    setLoading(true);

    const startLoadingTime = Date.now();
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileData = event.target?.result as any;

      if (fileData) {
        const workbook = XLSX.read(fileData, { type: "binary" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = XLSX.utils.sheet_to_json(sheet);

        setData(sheetData);
        setShowUploadPreview(true);
      }

      const endLoadingTime = Date.now();
      const timeDiff = endLoadingTime - startLoadingTime;

      // Loading should be at least 0.5 second long to avoid flickering
      if (timeDiff < 500) {
        setTimeout(() => {
          setLoading(false);
        }, 500 - timeDiff);
      } else {
        setLoading(false);
      }
    };
    reader.readAsBinaryString(file);
  };

  const [uploadEquipmentsReq] = useMutation(UPLOAD_EQUIPMENTS, {
    onError: (e) => {
      console.error(e);
      setModalErrMsg(e.message);
      setLoading(false);
    },
    onCompleted: () => {
      logEvent(LogType.EQUIPMENT, { message: "Equipment import" });
    },
  });

  const importEquipment = async () => {
    setLoading(true);

    await uploadEquipmentsReq({
      variables: {
        equipments: JSON.stringify(data),
      },
    });

    setLoading(false);
    setDialogOpen(false);
    setShowUploadPreview(false);
    setModalErrMsg("");
    onUpload(data);
    setData([]);
  };

  const renderDialogContent = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      );
    }

    if (showUploadPreview) {
      const previewData = data.slice(0, 10);

      return (
        <PreviewDialog
          open={showUploadPreview}
          data={previewData}
          onClose={() => {
            setShowUploadPreview(false);
          }}
          onConfirm={importEquipment}
          equipmentRowHeader={equipmentRowHeader}
          customFields={customFields}
        />
      );
    }

    return (
      <Box>
        <Typography variant="body1">
          Select an Excel spreadsheet to import equipment.
        </Typography>
        <Typography variant="body1">
          <Link
            href="#"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              const template = XLSX.utils.book_new();
              const sheet = XLSX.utils.aoa_to_sheet([equipmentRowHeader]);

              XLSX.utils.book_append_sheet(template, sheet, "Sheet1");
              XLSX.writeFile(template, "template.xlsx");
            }}
          >
            Click here to download a template.
          </Link>
        </Typography>

        <Button
          variant="outlined"
          component="label"
          sx={{ mt: 2 }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            const fileInput = document.createElement("input");
            fileInput.type = "file";
            fileInput.accept = ".xlsx";
            fileInput.onchange = (event: any) => {
              const file = event.target?.files?.[0];

              if (file) {
                handleFileChange(file);
              }
            };

            fileInput.click();
          }}
        >
          Select File...
        </Button>
      </Box>
    );
  };

  return (
    <Box sx={{ display: "inline" }}>
      <Tooltip title="Import from Excel">
        <IconButton
          color="primary"
          component="label"
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          <UploadFileIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Import Equipment From Excel</DialogTitle>
        <DialogContent>
          {renderDialogContent()}
          {modalErrMsg && (
            <Typography variant="body1" color="error" mt={2}>
              {modalErrMsg}
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "0px 24px 20px",
          }}
        >
          <Button
            onClick={(event) => {
              event.stopPropagation();
              setDialogOpen(false);
            }}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UploadDialog;
