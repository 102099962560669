import { Box, Button, Collapse, IconButton, Popover } from "@mui/material";
import { FC, FormEventHandler, MouseEventHandler, useState } from "react";
import { AddOutlined, Close } from "@mui/icons-material";
import { FieldProps } from "../../../Settings/CustomAlertsSection/types";
import FieldSelect from "./FieldSelect";
import ConditionSelect from "./ConditionSelect";
import ValueInput from "./ValueInput";
import useFieldFilters from "../../../../hooks/table/useFieldFilters";

type Props = {
  editMode?: boolean;
  field?: FieldProps | null;
  condition?: string | null;
  value?: string | null;
  label?: string;
};

const FiltersPopover: FC<Props> = ({
  editMode = false,
  field = null,
  condition = null,
  value = null,
  label = "",
}) => {
  const { addFilterInParams, removeFilterFromParams, updateFilterInParams } =
    useFieldFilters({ withPageReset: true });

  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);

  const [selectedField, setSelectedField] = useState<FieldProps | null>(
    editMode ? field : null
  );
  const [selectedCondition, setSelectedCondition] = useState<string | null>(
    editMode ? condition : null
  );
  const [selectedValue, setSelectedValue] = useState<string | null>(
    editMode ? value : null
  );

  const reset = () => {
    setSelectedField(editMode ? field : null);
    setSelectedCondition(editMode ? condition : null);
    setSelectedValue(editMode ? value : null);
  };

  const handleNewFilter: FormEventHandler = (e) => {
    e.preventDefault();
    if (!selectedField || !selectedCondition || !selectedValue) return;

    const { name, type } = selectedField;
    addFilterInParams(name, selectedCondition, selectedValue, type);

    setAnchorEl(null);
    reset();
  };

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!field || !value || !condition) return;

    removeFilterFromParams({
      cond: condition,
      name: field.name,
      value: value,
      type: field.type,
    });

    setAnchorEl(null);
  };

  const handleUpdate: FormEventHandler = (e) => {
    e.preventDefault();
    const initialValuesExist = !!field && !!value && !!condition;
    if (!initialValuesExist || !selectedCondition || !selectedValue) {
      return;
    }

    const old = {
      name: field.name,
      type: field.type,
      cond: condition,
      value,
    };
    const updated = {
      name: field.name,
      type: field.type,
      cond: selectedCondition,
      value: selectedValue,
    };
    updateFilterInParams(old, updated);

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filters-popover" : undefined;

  return (
    <>
      {!editMode ? (
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          aria-describedby={id}
          variant="contained"
        >
          Filters
        </Button>
      ) : (
        <Box
          onClick={(e) => setAnchorEl(e.currentTarget)}
          width="content-fit"
          sx={{
            cursor: "pointer",
            bgcolor: "#ebebeb",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: 2,
            paddingX: 1,
            paddingY: 0.5,
            gap: 2,
          }}
        >
          <label style={{ fontSize: "14px", cursor: "pointer" }}>{label}</label>
          <IconButton onClick={handleDelete} sx={{ padding: 0 }}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          reset();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box padding={2} minWidth={300}>
          <form onSubmit={editMode ? handleUpdate : handleNewFilter}>
            <FieldSelect
              disabled={editMode}
              selectedField={selectedField}
              onChange={setSelectedField}
            />

            <Collapse in={!!selectedField}>
              {selectedField && (
                <>
                  <ConditionSelect
                    fieldType={selectedField.type}
                    selectedCondition={selectedCondition}
                    onChange={setSelectedCondition}
                  />
                  <ValueInput
                    fieldType={selectedField.type}
                    value={selectedValue || ""}
                    onChange={setSelectedValue}
                  />
                </>
              )}
            </Collapse>

            <Box
              marginTop={2}
              display="flex"
              gap={1}
              justifyContent="space-between"
            >
              {editMode ? (
                <Button
                  onClick={handleDelete}
                  type="button"
                  disabled={
                    !selectedField || !selectedCondition || !selectedValue
                  }
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span style={{ marginTop: 4 }}>Delete</span>
                </Button>
              ) : (
                <span />
              )}
              <Button
                type="submit"
                size="small"
                variant="contained"
                disabled={
                  !selectedField || !selectedCondition || !selectedValue
                }
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {editMode ? (
                  <span style={{ marginTop: 4 }}>Update</span>
                ) : (
                  <>
                    <AddOutlined />
                    <span style={{ marginTop: 4 }}>Add filter</span>
                  </>
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Popover>
    </>
  );
};

export default FiltersPopover;
