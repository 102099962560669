import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Toolbar, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import HandymanIcon from "@mui/icons-material/Handyman";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled } from "@mui/material/styles";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUserState, signOut } from "../../redux/reducers/user";
import QuneUpLog from "../../assets/img/QuneUp_logo.png";
import theme from "../../theme";
import { ROUTES } from "../../Router";
import { selectLayoutState } from "../../redux/reducers/leyout";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";
import { SStorageKey, useSessionStorage } from "../../hooks/useSessionStorage";

interface HeaderProps {
  enableBackBtn: boolean;
  backButtonLink?: string;
  printQRCode?: () => void;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Header = (props: HeaderProps) => {
  const { logEventAsync } = useLogger();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUserState);
  const { layout } = useAppSelector(selectLayoutState);
  const isAdmin = user?.role.type === "administrator";

  const { stored: eqTableParams } = useSessionStorage(
    SStorageKey.EQ_TABLE_PARAMS,
  );

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <MuiAppBar
      position="absolute"
      elevation={0}
      sx={{
        position: "relative",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: theme.color.secondaryA200,
        }}
      >
        <Box style={{ width: 200 }}>
          {Boolean(user) && (
            <Button onClick={handleDrawerOpen}>
              <MenuOpenIcon
                style={{
                  fontSize: 30,
                  color: theme.color.primary,
                }}
              />
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {!layout.removeBranding && (
            <img
              style={{ width: 80, marginBottom: 7 }}
              src={QuneUpLog}
              alt="QuneUp logo"
            />
          )}
        </Box>
        <Box
          style={{ width: 200, justifyContent: "flex-end", display: "flex" }}
        >
          {Boolean(user) && props.enableBackBtn && (
            <IconButton
              onClick={() => {
                if (props.backButtonLink) {
                  navigate(props.backButtonLink);
                } else {
                  navigate(-1);
                }
              }}
            >
              <ArrowBackIcon
                style={{ color: theme.color.primary, fontSize: 30 }}
              />
            </IconButton>
          )}
          {Boolean(props.printQRCode) && (
            <IconButton
              onClick={() => {
                if (props.printQRCode) {
                  props.printQRCode();
                }
              }}
            >
              <PrintIcon style={{ color: theme.color.primary, fontSize: 30 }} />
            </IconButton>
          )}
        </Box>
      </Toolbar>
      <Box>
        <Drawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          className="app-main-drawer"
        >
          <Box>
            <DrawerHeader
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(
                      eqTableParams
                        ? `${ROUTES.EQUIPMENT}?${eqTableParams}`
                        : ROUTES.EQUIPMENT,
                    );
                  }}
                >
                  <ListItemIcon>
                    <BuildCircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Equipment" />
                </ListItemButton>
              </ListItem>

              {isAdmin && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.MAINTANENCE_REQUESTS);
                    }}
                  >
                    <ListItemIcon>
                      <HandymanIcon />
                    </ListItemIcon>
                    <ListItemText primary="Maintenance Requests" />
                  </ListItemButton>
                </ListItem>
              )}

              {isAdmin && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.USERS);
                    }}
                  >
                    <ListItemIcon>
                      <PeopleAltRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItemButton>
                </ListItem>
              )}

              {isAdmin && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.SETTINGS);
                    }}
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Box>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleDrawerClose}
            onKeyDown={handleDrawerClose}
          >
            <Box
              onClick={(e) => e.stopPropagation()}
              style={{ padding: "0px 16px" }}
            >
              <Typography>{user?.email}</Typography>
            </Box>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    logEventAsync(LogType.USER, {
                      message: "Sign out",
                    }).finally(() => {
                      dispatch(signOut());
                    });
                  }}
                >
                  <ListItemIcon>
                    <ExitToAppOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Box>
    </MuiAppBar>
  );
};

export default Header;
