import { useSearchParams } from "react-router-dom";
import { CommonParams } from "../types/common";
import { useMemo } from "react";
import { PaginationSearchParams } from "./table/usePagination";

type SearchConfig = {
  withPageReset?: boolean;
};

const useSearchQuery = (config?: SearchConfig) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { queryString, isQueryInParams } = useMemo(() => {
    const queryString = searchParams.get(CommonParams.SearchQuery) || "";
    const isQueryInParams = searchParams.has(CommonParams.SearchQuery);

    return { queryString, isQueryInParams };
  }, [searchParams]);

  const populateParamsWithQuery = (q: string) => {
    searchParams.set(CommonParams.SearchQuery, q.trim());

    if (config?.withPageReset) {
      searchParams.delete(PaginationSearchParams.PAGE);
    }

    setSearchParams(searchParams);
  };

  const removeQueryParam = () => {
    searchParams.delete(CommonParams.SearchQuery);

    if (config?.withPageReset) {
      searchParams.delete(PaginationSearchParams.PAGE);
    }

    setSearchParams(searchParams);
  };

  return {
    queryString,
    isQueryInParams,
    populateParamsWithQuery,
    removeQueryParam,
  };
};

export default useSearchQuery;
