import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Checkbox, IconButton, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import TablePagination from "@mui/material/TablePagination";
import { AddCircle } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import SettingsIcon from "@mui/icons-material/Settings";
import { Container } from "@mui/system";

import {
  CustomEquipmentField,
  CustomEquipmentFieldDTO,
  EquipmentTableHeaders,
} from "./types";
import Header from "../components/Header";
import EquipmentModal from "./modal";
import { GET_ORGANIZATION_BY_ID } from "./graphql";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUserState } from "../../redux/reducers/user";
import UploadDialog from "./UploadDialog";
import ColumnCustomizationModal from "./components/ColumnCustomizationModal";
import { EQUIPMENT_HEADERS } from "./constants";
import { GET_CUSTOM_EQUIPMENT_FIELDS } from "../Settings/graphql";
import { processCustomFields } from "./helpers";
import { parseCustomEquipmentFields } from "./api/parsers";
import {
  getFieldsMap,
  useDefaultFieldsAlerts,
  useEqFieldsWithAlerts,
} from "../Settings/CustomAlertsSection/helpers";
import {
  CustomAlertProps,
  FieldsMap,
} from "../Settings/CustomAlertsSection/types";
import EqpAlertRow from "../Settings/CustomAlertsSection/EqpAlertRow";
import useCheckboxSelection from "../../hooks/table/useCheckboxSelection";
import ResponsiveQRCode from "../components/ResponsiveQRCode";
import { useReactToPrint } from "react-to-print";
import usePagination, {
  DEFAULT_PAGE_OPTIONS,
} from "../../hooks/table/usePagination";
import EquipmentSearch from "./components/EquipmentSearch";
import HighlightedText from "./components/HighlightedText";
import useSearchQuery from "../../hooks/useSearchQuery";
import FiltersPopover from "./components/FiltersPopover";
import FilterChips from "./components/FilterChips";
import useFieldFilters from "../../hooks/table/useFieldFilters";
import { Organization } from "../../redux/reducers/user/types";
import useActiveFields from "../../hooks/table/useActiveFields";
import { usePrevious } from "../../hooks/usePrevious";
import { GET_CUSTOM_ALERTS_BY_RELATION } from "../Settings/CustomAlertsSection/graphql";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";
import SortPopover from "./components/SortPopover";
import useSortBy from "../../hooks/table/useSortBy";
import { fetchQueriedEquipment } from "./rest";
import {
  selectEquipment,
  setFetchedEquipment,
  setLoading,
} from "../../redux/reducers/equipment";
import { useSearchParams } from "react-router-dom";
import { SStorageKey, useSessionStorage } from "../../hooks/useSessionStorage";
import { GET_PROCESS_AREAS_ONLY } from "../Settings/ProcessAreasAndRolesSection/graphql";
import { ProcessArea } from "../Settings/ProcessAreasAndRolesSection/types";

const Equipments = () => {
  const { logEvent } = useLogger();
  const { setStored } = useSessionStorage(SStorageKey.EQ_TABLE_PARAMS);
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const paramsString = searchParams.toString();
    setStored(paramsString ? paramsString : null);
  }, [searchParams, setStored]);

  const { queryString } = useSearchQuery();
  const { filters } = useFieldFilters();
  const { sortBy } = useSortBy();
  const { activeFields, addActiveFieldsInParams } = useActiveFields();

  const { user } = useAppSelector(selectUserState);
  const isAdmin = user?.role.type === "administrator";
  const { equipment, pagination, loading } = useAppSelector(selectEquipment);

  const dispatch = useAppDispatch();

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const { loading: loadingOrg } = useQuery(
    GET_ORGANIZATION_BY_ID(user?.selected_organization_id),
    {
      onCompleted: ({ organization: { data } }) => {
        const { id, attributes } = data;
        setSelectedOrg({
          id: id,
          ...attributes,
        });
      },
      skip: !user?.selected_organization_id,
    }
  );

  const [getCustomAlertsByRelation] = useLazyQuery(
    GET_CUSTOM_ALERTS_BY_RELATION,
    {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        setMaintenanceAlerts(
          data.customAlerts.data.map((alert: any) => ({
            id: alert.id,
            ...alert.attributes,
          }))
        );
      },
    }
  );

  useEffect(() => {
    if (user?.selected_organization_id) {
      getCustomAlertsByRelation({
        variables: {
          relation: "maintenance_request",
        },
      });
    }
  }, [user?.selected_organization_id, getCustomAlertsByRelation]);

  const [equipmentHeaders, setEquipmentHeaders] =
    useState<EquipmentTableHeaders>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCustomizeModal, setOpenCustomizeModal] = useState(false);
  const [maintenanceAlerts, setMaintenanceAlerts] = useState<
    CustomAlertProps[]
  >([]);
  const defaultHeadersRef = useRef<EquipmentTableHeaders | null>(null);
  const printContainerRef = useRef<HTMLDivElement | null>(null);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();

  const { data: customFieldsData, loading: customFieldsLoading } =
    useQuery<CustomEquipmentFieldDTO>(GET_CUSTOM_EQUIPMENT_FIELDS);

  const customFieldHeaders = useMemo(
    () =>
      parseCustomEquipmentFields(
        customFieldsData?.customEquipmentFields
      ) as CustomEquipmentField[],
    [customFieldsData]
  );

  const [processAreas, setProcessAreas] = useState<ProcessArea[]>([]);

  const [getProcessAreas] = useLazyQuery(GET_PROCESS_AREAS_ONLY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const processAreas = data.processAreas.data.map((processArea: any) => {
        return {
          id: processArea.id,
          name: processArea.attributes.name,
        };
      });

      setProcessAreas(processAreas);
    },
  });

  useEffect(() => {
    if (isAdmin && selectedOrg?.enable_process_areas) {
      getProcessAreas();
    }
  }, [isAdmin, getProcessAreas, selectedOrg?.enable_process_areas]);

  const fetchEquipment = useCallback(() => {
    dispatch(setLoading(true));
    fetchQueriedEquipment({
      pagination: {
        page,
        pageSize: rowsPerPage,
      },
      query: queryString,
      filters,
      sortBy,
      fields: Array.from(activeFields),
    })
      .then((data) => {
        dispatch(setFetchedEquipment(data));
      })
      .catch(() => dispatch(setLoading(false)));
  }, [activeFields, dispatch, filters, page, queryString, rowsPerPage, sortBy]);

  React.useEffect(() => {
    if (!user || loading) return;
    fetchEquipment();
    // ? avoid excessive reqs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEquipment]);

  const { eqFieldsWithAlerts } = useEqFieldsWithAlerts({
    fetchPolicy: "network-only",
  });
  const { defaultFieldAlertsMap } = useDefaultFieldsAlerts({
    fetchPolicy: "network-only",
  });
  const fieldsMapAll: FieldsMap = useMemo(() => {
    return getFieldsMap(eqFieldsWithAlerts);
  }, [eqFieldsWithAlerts]);
  const fieldsMap = useMemo(() => {
    return Object.entries(fieldsMapAll).reduce((acc, [key, value]) => {
      if (value.custom_equipment_field?.custom_alerts?.length) {
        acc[key] = value;
      } else if (defaultFieldAlertsMap[key]) {
        value.custom_alerts = defaultFieldAlertsMap[key];
        acc[key] = value;
      }

      return acc;
    }, {} as FieldsMap);
  }, [fieldsMapAll, defaultFieldAlertsMap]);

  const [hasHeaderCustomizations, setHasHeaderCustomizations] = useState(false);

  useEffect(() => {
    if (equipmentHeaders.length && defaultHeadersRef.current)
      setHasHeaderCustomizations(
        JSON.stringify(
          defaultHeadersRef.current?.map(({ isAdminOnly, ...rest }) => rest)
        ) !==
        JSON.stringify(
          equipmentHeaders.map(({ isAdminOnly, ...rest }) => rest)
        )
      );
  }, [equipmentHeaders]);

  const dataWithCustomFieldValues = useMemo(() => {
    if (!equipment || customFieldsLoading) {
      return [];
    }
    return processCustomFields(equipment);
  }, [equipment, customFieldsLoading]);

  const {
    selectedRows,
    handleSelectAllRowsClick,
    handleSelectRowClick,
    getIsSelectedRow,
  } = useCheckboxSelection(dataWithCustomFieldValues);

  const selectedEquipments = useMemo(
    () => dataWithCustomFieldValues.filter((r) => selectedRows.includes(r.id)),
    [dataWithCustomFieldValues, selectedRows]
  );

  const isAdministrator = user?.role.type === "administrator";

  const toggleCustomizeModal = () => setOpenCustomizeModal((prev) => !prev);

  const handleCustomizationSave = (headers: EquipmentTableHeaders) => {
    setEquipmentHeaders(headers);
    addActiveFieldsInParams(headers);
    toggleCustomizeModal();
  };

  const print = useReactToPrint({
    content: () => printContainerRef.current,
  });

  const handlePrint = () => {
    logEvent(LogType.ACTION, { message: "Equipment print" });
    print();
  };

  const totalCountPrevious = usePrevious(pagination.total);
  const totalCount = pagination ? pagination.total : totalCountPrevious || 0;

  useEffect(() => {
    if (customFieldsLoading) return;

    const customHeaders: EquipmentTableHeaders =
      customFieldHeaders?.map((f, idx) => ({
        id: EQUIPMENT_HEADERS.length + idx,
        title: f.name,
        format: (x) => {
          const isFieldAdminOnly =
            x.fields?.find(
              (field: any) => field.custom_equipment_field.name === f.name
            )?.admin_only || false;
          return (
            <HighlightedText isFieldAdminOnly={isFieldAdminOnly}>
              {x[f.name]}
            </HighlightedText>
          );
        },

        isDefaultActive: false,
        isActive: false,
        custom_field_id: f.id,
        isAdminOnly: f.admin_only,
      })) ?? [];

    if (defaultHeadersRef.current === null) {
      defaultHeadersRef.current = [...EQUIPMENT_HEADERS, ...customHeaders];
    }

    const initialHeaders = [...EQUIPMENT_HEADERS, ...customHeaders].map(
      (header) => ({
        ...header,
        isActive: activeFields.size
          ? activeFields.has(header.title)
          : header.isDefaultActive,
        isAdminOnly: selectedOrg?.enable_field_visibility_toggle
          ? selectedOrg?.default_fields_config?.[header.title]?.admin_only ||
          header.isAdminOnly
          : header.isAdminOnly,
      })
    );

    const activeFieldsSortMap = Array.from(activeFields).reduce<
      Record<string, number>
    >((acc, curr, idx) => {
      acc[curr] = idx;
      return acc;
    }, {});

    setEquipmentHeaders([
      ...initialHeaders
        .filter((h) => h.isActive)
        .sort(
          (a, b) => activeFieldsSortMap[a.title] - activeFieldsSortMap[b.title]
        ),
      ...initialHeaders.filter((h) => !h.isActive),
    ]);
    // ? we dont want to run this effect on active fields param changes
    // ? as far as this effect only used for initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customFieldHeaders,
    customFieldsLoading,
    selectedOrg?.default_fields_config,
    selectedOrg?.enable_field_visibility_toggle,
  ]);

  const isTableDataLoading = loading || customFieldsLoading || loadingOrg;

  const shouldHideFields =
    !isAdministrator && !!selectedOrg?.enable_field_visibility_toggle;

  const renderTable = () => {
    if (isTableDataLoading) {
      return (
        <Box display={"flex"} justifyContent={"center"} mt="40px">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="Equipments table">
          {!dataWithCustomFieldValues.length && (
            <caption>Nothing was found</caption>
          )}
          <TableHead>
            <TableRow>
              {isAdministrator && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onChange={handleSelectAllRowsClick}
                  />
                </TableCell>
              )}
              {equipmentHeaders.map((h, idx) => {
                if (!h.isActive || (h.isAdminOnly && shouldHideFields)) {
                  return null;
                }

                return (
                  <TableCell key={idx} align={idx ? "right" : undefined}>
                    {h.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataWithCustomFieldValues.map((row) => {
              const isRowSelected = getIsSelectedRow(row.id);

              return (
                <EqpAlertRow
                  key={row.id}
                  equipment={row}
                  fieldsMap={fieldsMap}
                  equipmentHeaders={equipmentHeaders}
                  isAdministrator={isAdministrator}
                  handleSelectRowClick={handleSelectRowClick}
                  isRowSelected={isRowSelected}
                  selectedOrg={selectedOrg || undefined}
                  maintenanceAlerts={maintenanceAlerts}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <React.Fragment>
      <Header enableBackBtn={false} />
      <Container sx={{ padding: "20px", height: "calc(100vh - 200px)" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            Equipment
          </Typography>
          {isAdministrator ? (
            <Box>
              <UploadDialog
                onUpload={(data: any) => {
                  fetchEquipment();
                }}
              />
              <Tooltip title="Customize Table">
                <IconButton
                  color="primary"
                  component="label"
                  onClick={toggleCustomizeModal}
                >
                  <Badge
                    color="secondary"
                    badgeContent=" "
                    variant="dot"
                    invisible={!hasHeaderCustomizations}
                  >
                    <SettingsIcon fontSize="large" />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title="Print">
                <IconButton
                  color="primary"
                  component="label"
                  disabled={!selectedRows.length}
                  onClick={handlePrint}
                >
                  <PrintIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add Equipment">
                <IconButton
                  color="primary"
                  component="label"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <AddCircle fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Tooltip title="Customize Table">
                <IconButton
                  color="primary"
                  component="label"
                  onClick={toggleCustomizeModal}
                >
                  <Badge
                    color="secondary"
                    badgeContent=" "
                    variant="dot"
                    invisible={!hasHeaderCustomizations}
                  >
                    <SettingsIcon fontSize="large" />
                  </Badge>
                </IconButton>
              </Tooltip>
              {Object.keys(user?.writeProcessAreasMap || {})?.length > 0 && (
                <Tooltip title="Add Equipment">
                  <IconButton
                    color="primary"
                    component="label"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <AddCircle fontSize="large" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </Box>

        <Box
          marginBottom={2}
          display="flex"
          gap={1}
          flexWrap="wrap"
          width="100%"
        >
          <EquipmentSearch />
          <FiltersPopover />
          <SortPopover />
        </Box>
        <FilterChips />

        <Paper
          sx={{ maxHeight: "100%", overflow: "auto", position: "relative" }}
        >
          {renderTable()}

          <TablePagination
            rowsPerPageOptions={DEFAULT_PAGE_OPTIONS}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ position: "sticky", bottom: 0, "background-color": "#fff" }}
          />
        </Paper>
      </Container>

      {isAdministrator && (
        <Box
          className="print-container"
          display={"none"}
          ref={printContainerRef}
        >
          {selectedEquipments.map((equipment, index) => (
            <Box
              key={equipment.id}
              style={{
                pageBreakBefore: "always",
                marginTop: index ? "40px" : "0",
              }}
            >
              <ResponsiveQRCode
                value={`${process.env.REACT_APP_BASE_URL}/equipment/${equipment.id}`}
                centerText={equipment.eid}
                removeBranding={equipment?.organization?.remove_branding}
              />
            </Box>
          ))}
        </Box>
      )}
      <EquipmentModal
        extEquipment={null}
        setOpenModal={() => setOpenModal(false)}
        openModal={openModal}
        onCreateCB={() => {
          fetchEquipment();
        }}
        availableAreas={processAreas}
      />
      <ColumnCustomizationModal
        open={openCustomizeModal}
        headers={equipmentHeaders}
        defaultHeaders={defaultHeadersRef.current}
        onClose={toggleCustomizeModal}
        onSave={handleCustomizationSave}
      />
    </React.Fragment>
  );
};

export default Equipments;
