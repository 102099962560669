import axios from "axios";
import { Filter } from "../../hooks/table/useFieldFilters";
import { PaginationMeta } from "../../types/api";
import { CONDITIONS_MAP } from "./constants";
import { getDefaultFieldName } from "./helpers";
import { Equipment } from "./types";
import qs from "qs";

const API_BASE_URL = process.env.REACT_APP_SERVER_API;

enum FetchSearchParams {
  Page = "page",
  PageSize = "pageSize",
  Query = "q",
  Filters = "filters",
  SortBy = "sort",
  Fields = "fields",
}

type FetchArgs = {
  pagination: {
    page: number;
    pageSize: number;
  };
  query?: string;
  filters?: Filter[];
  sortBy?: string[];
  fields?: string[];
};

const instance = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchQueriedEquipment = async ({
  pagination,
  query,
  filters,
  sortBy,
  fields,
}: FetchArgs): Promise<{
  equipment: Equipment[];
  pagination: PaginationMeta;
}> => {
  const formattedFilters = filters?.length
    ? filters.map((f) => {
        const { name, cond, value, type } = f;
        const formattedName = getDefaultFieldName(name) || name;
        const formattedCond =
          "$" + CONDITIONS_MAP[cond as keyof typeof CONDITIONS_MAP];

        return `${formattedName}:${formattedCond}:${
          type === "date" ? value.split("T")[0] : value
        }:${type}`;
      })
    : null;

  const formattedFields = fields?.length
    ? fields.map((f) => getDefaultFieldName(f) || f)
    : null;

  const qObject = {
    [FetchSearchParams.Page]: pagination.page + 1,
    [FetchSearchParams.PageSize]: pagination.pageSize,
    [FetchSearchParams.Query]: query ? query : null,
    [FetchSearchParams.Filters]: formattedFilters,
    [FetchSearchParams.SortBy]: sortBy?.length ? sortBy : null,
    [FetchSearchParams.Fields]: formattedFields,
  };

  const q = qs.stringify(qObject, { strictNullHandling: true });

  try {
    const token = localStorage.getItem("token");

    if (!token) throw new Error("No JWT token");

    const { data } = await instance.get<{
      result: Equipment[];
      pagination: PaginationMeta;
    }>(`/equipment/query?${q}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return {
      equipment: data.result || [],
      pagination: data.pagination || {
        page: 0,
        pageSize: 0,
        total: 0,
        pageCount: 0,
      },
    };
  } catch (e) {
    console.error(e);
    return {
      equipment: [],
      pagination: { page: 0, pageSize: 0, total: 0, pageCount: 0 },
    };
  }
};
