import { useSearchParams } from "react-router-dom";
import { DefaultHeaderTitles } from "../../types/table";
import { CommonParams } from "../../types/common";
import { useMemo } from "react";
import { getDefaultFieldName } from "../../pages/Equipments/helpers";
import { PaginationSearchParams } from "./usePagination";

export type SortItem = {
  fieldName: DefaultHeaderTitles | string;
  direction: "asc" | "desc";
  type: string;
};

type SortConfig = {
  withPageReset?: boolean;
};

const useSortBy = (config?: SortConfig) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sortBy = useMemo(() => {
    const sortByParam = searchParams.get(CommonParams.SortBy) || "";
    const sortBy = sortByParam.split(",").filter(Boolean);
    return sortBy;
  }, [searchParams]);

  const addSortingInParams = (sortItems: SortItem[]) => {
    if (!sortItems.length) return removeSortingFromParams();

    const formattedSorting = sortItems
      .map((si) => {
        return `${getDefaultFieldName(si.fieldName) || si.fieldName}:${
          si.direction
        }:${si.type}`;
      })
      .filter(Boolean);

    searchParams.set(CommonParams.SortBy, formattedSorting.join(","));

    if (
      config?.withPageReset &&
      formattedSorting.join(",") !== sortBy.join(",")
    ) {
      searchParams.delete(PaginationSearchParams.PAGE);
    }

    setSearchParams(searchParams);
  };

  const removeSortingFromParams = () => {
    searchParams.delete(CommonParams.SortBy);
    if (config?.withPageReset) {
      searchParams.delete(PaginationSearchParams.PAGE);
    }

    setSearchParams(searchParams);
  };

  return {
    sortBy,
    addSortingInParams,
    removeSortingFromParams,
  };
};

export default useSortBy;
