import React, { useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import ConfirmDialog from "../components/ConfirmDialog";
import { CustomEquipmentField, Equipment, EquipmentFieldValue } from "./types";
import {
  CREATE_EQUIPMENT,
  UPDATE_EQUIPMENT,
  DELETE_EQUIPMENT,
  CREATE_EQUIPMENT_FIELD_VALUE,
  UPDATE_EQUIPMENT_FIELD_VALUE,
} from "./graphql";
import BasicDatePicker from "../components/BasicDatePicker";
import { ROUTES } from "../../Router";
import { useAppSelector } from "../../redux/hooks";
import { selectCustomFields } from "../../redux/reducers/custom-field";
import { selectUserState } from "../../redux/reducers/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DefaultHeaderTitles } from "../../types/table";
import { DEFAULT_FIELDS_MAP } from "./constants";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";
import { ProcessArea } from "../Settings/ProcessAreasAndRolesSection/types";
import dayjs from "dayjs";

const isValidLink = (url: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(url);
};

const emptyEquipment = {
  id: 0,
  eid: "",
  type: "",
  manufacturer: "",
  date_of_manufacture: null,
  serial_number: "",
  part_number: "",
  construction_material: "",
  fields: [],
  default_fields_config: null,
} as Equipment;

const CustomFieldValueInput = ({
  field,
  fieldValue,
  onChange,
  onError,
}: {
  field: CustomEquipmentField;
  fieldValue: EquipmentFieldValue | undefined;
  onChange: (
    event: any,
    field: EquipmentFieldValue | undefined,
    customField: CustomEquipmentField
  ) => void;
  onError?: (id: number, msg: string) => void;
}) => {
  switch (field.type) {
    case "ShortText":
      return (
        <TextField
          margin="dense"
          fullWidth
          label={field.name}
          value={fieldValue?.value || ""}
          onChange={(e) => onChange(e, fieldValue, field)}
        />
      );
    case "LongText":
      return (
        <TextField
          margin="dense"
          fullWidth
          multiline
          rows={3}
          label={field.name}
          value={fieldValue?.value_long || ""}
          onChange={(e) => onChange(e, fieldValue, field)}
        />
      );
    case "Number":
      return (
        <TextField
          margin="dense"
          fullWidth
          type="number"
          label={field.name}
          value={fieldValue?.value || ""}
          onChange={(e) => onChange(e, fieldValue, field)}
        />
      );
    case "Date":
      return (
        <BasicDatePicker
          label={field.name}
          value={dayjs(fieldValue?.value) || null}
          handleOnChange={(e) => onChange(e, fieldValue, field)}
        />
      );
    case "Checkbox":
      return (
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => onChange(e, fieldValue, field)}
              checked={fieldValue?.value === "true" ? true : false}
            />
          }
          label={field.name}
        />
      );
    case "Link":
      return (
        <TextField
          margin="dense"
          fullWidth
          label={field.name}
          value={fieldValue?.value || ""}
          onChange={(e) => {
            const res = !isValidLink(e.target.value);

            if (e.target.value && res) {
              onError && onError(field.id, "Invalid Link");
            } else {
              onError && onError(field.id, "");
            }

            onChange(e, fieldValue, field);
          }}
          FormHelperTextProps={{
            error: true,
          }}
          helperText={
            fieldValue?.value && !isValidLink(fieldValue?.value)
              ? "Invalid Link"
              : ""
          }
        />
      );
    default:
      throw new Error("Unknown field type: " + field.type);
  }
};

const EquipmentModal: React.FC<{
  extEquipment?: any | null;
  setOpenModal: any;
  openModal: boolean;
  availableAreas?: ProcessArea[];
  onCreateCB?: (data: any) => void;
  onUpdateCB?: (data: any) => void;
}> = ({
  extEquipment,
  setOpenModal,
  openModal,
  onCreateCB,
  onUpdateCB,
  availableAreas,
}) => {
  const { logEvent } = useLogger();
  const isEditing = Boolean(extEquipment?.id);
  const navigate = useNavigate();
  const customEquipmentFields = useAppSelector(selectCustomFields);

  const { user } = useAppSelector(selectUserState);
  const isAdmin = user?.role.type === "administrator";
  const selectedOrg = useMemo(
    () =>
      user?.organizations?.find(
        (org) => org.id === user?.selected_organization_id
      ),
    [user]
  );

  const [equipment, setEquipment] = useState<Equipment>(emptyEquipment);
  const [defaultFieldsConfig, setDefaultFieldsConfig] = useState<
    NonNullable<Equipment["default_fields_config"]>
  >({});
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalErrMsg, setModalErrMsg] = useState<string>("");
  const [cFieldsErrMap, setCFieldsErrMap] = useState<{
    [key: number]: string;
  }>({});

  const [createEquipmentReq] = useMutation(CREATE_EQUIPMENT, {
    onError: (e) => {
      console.error(e);
      setModalErrMsg(e.message);
      setLoading(false);
    },
    onCompleted: async (data) => {
      await saveEquipmentFields({
        ...equipment,
        id: data.createEquipment.data.id,
      });

      logEvent(LogType.EQUIPMENT, {
        message: "New equipment created",
        resourceId: data.createEquipment.data.id,
      });

      if (onCreateCB) onCreateCB(data);

      setLoading(false);
      closeModal();
    },
  });

  const [updateEquipmentReq] = useMutation(UPDATE_EQUIPMENT, {
    onError: (e) => {
      console.error(e);
      setModalErrMsg(e.message);
      setLoading(false);
    },
    onCompleted: async (data) => {
      await saveEquipmentFields({
        ...equipment,
      });

      logEvent(LogType.EQUIPMENT, {
        message: "Equipment updated",
        resourceId: equipment.id,
      });

      if (onUpdateCB) onUpdateCB(data);

      setLoading(false);
      closeModal();
    },
  });

  const [deleteEquipmentReq] = useMutation(DELETE_EQUIPMENT, {
    onError: (e) => {
      console.error(e);
      setModalErrMsg(e.message);
      setLoading(false);
    },
    onCompleted: () => {
      setLoading(false);

      logEvent(LogType.EQUIPMENT, {
        message: "Equipment deleted",
        resourceId: equipment.id,
      });

      closeModal();
      navigate(ROUTES.EQUIPMENT, { replace: true });
    },
  });

  const [createEquipmentFieldValueReq] = useMutation(
    CREATE_EQUIPMENT_FIELD_VALUE,
    {
      onError: (e) => {
        console.error(e);
        setModalErrMsg(e.message);
        setLoading(false);
      },
      onCompleted: () => {
        logEvent(LogType.EQUIPMENT, {
          message: "Equipment value created",
          resourceId: equipment.id,
        });
      },
    }
  );

  const [updateEquipmentFieldValueReq] = useMutation(
    UPDATE_EQUIPMENT_FIELD_VALUE,
    {
      onError: (e) => {
        console.error(e);
        setModalErrMsg(e.message);
        setLoading(false);
      },
      onCompleted: () => {
        logEvent(LogType.EQUIPMENT, {
          message: "Equipment value updated",
          resourceId: equipment.id,
        });
      },
    }
  );

  const saveEquipmentFields = async (equipment: Equipment) => {
    for (const fieldValue of equipment.fields) {
      if (Number(fieldValue.id) > 0) {
        await updateEquipmentFieldValueReq({
          variables: {
            id: fieldValue.id,
            value: fieldValue.value,
            value_long: fieldValue.value_long,
            custom_equipment_field_id: fieldValue.custom_equipment_field.id,
            equipment_id: equipment.id,
            admin_only: fieldValue.admin_only || false,
          },
        });
      } else {
        await createEquipmentFieldValueReq({
          variables: {
            value: fieldValue.value,
            value_long: fieldValue.value_long,
            custom_equipment_field_id: fieldValue.custom_equipment_field.id,
            equipment_id: equipment.id,
            admin_only: fieldValue.admin_only || false,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (extEquipment) {
      let date = null;

      if (extEquipment.date) {
        date = new Date(extEquipment.date);
      }

      setEquipment({
        ...extEquipment,
        date,
      });

      setDefaultFieldsConfig(extEquipment.default_fields_config || {});
    }
  }, [extEquipment, setEquipment, openModal]);

  const equipmentFieldValuesMap = equipment.fields.reduce<
    Record<string, EquipmentFieldValue>
  >((acc, field) => {
    acc[field.custom_equipment_field.id] = field;
    return acc;
  }, {});

  const closeModal = () => {
    setOpenModal(false);

    setTimeout(() => {
      setEquipment(emptyEquipment);
      setModalErrMsg("");
    }, 200);
  };

  const handleDelete = () => {
    deleteEquipmentReq({
      variables: {
        id: equipment.id,
      },
    });
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setEquipment({
        ...equipment,
        [event.target.name]: event.target.value,
      });
    } else {
      setEquipment({
        ...equipment,
        [name]: event.toString(),
      });
    }
  };

  const validateEquipment = () => {
    for (const field of customEquipmentFields) {
      const value = equipmentFieldValuesMap[field.id]?.value;

      if (field.type === "Link" && value) {
        if (!isValidLink(value)) {
          setCFieldsErrMap({
            ...cFieldsErrMap,
            [field.id]: "Invalid link",
          });

          return false;
        }
      }
    }

    return true;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateEquipment()) {
      return null;
    }

    setLoading(true);

    const date_of_manufacture = equipment.date_of_manufacture?.$d
      ? moment(equipment.date_of_manufacture.$d).format("YYYY-MM-DD")
      : equipment.date_of_manufacture;

    if (isEditing) {
      updateEquipmentReq({
        variables: {
          ...equipment,
          date_of_manufacture,
          default_fields_config: defaultFieldsConfig,
          fields: Object.values(equipmentFieldValuesMap),
          process_areas: equipment.process_areas?.map((pa) => pa.id) || [],
        },
      });
    } else {
      const newEquipment = {
        ...equipment,
        default_fields_config: defaultFieldsConfig,
        date_of_manufacture,
        fields: Object.values(equipmentFieldValuesMap),
        process_areas: equipment.process_areas?.map((pa) => pa.id) || [],
      } as any;

      delete newEquipment.id;

      createEquipmentReq({
        variables: newEquipment,
      });
    }
  };

  const getModalTitle = () => {
    return isEditing ? "Edit Equipment" : "New Equipment";
  };

  const handleCustomFieldChange = (
    event: any,
    field: EquipmentFieldValue | undefined,
    customField: CustomEquipmentField
  ) => {
    let value = "";
    let value_long = "";

    if (event.$d) {
      value = event;
    } else if (customField.type === "Checkbox") {
      value = String(event.target.checked);
    } else if (customField.type === "LongText") {
      value_long = event.target.value;
    } else {
      value = event.target.value;
    }

    const fieldValue = {
      id: field?.id || -1,
      value: value,
      value_long,
      admin_only: field?.admin_only || false,
      custom_equipment_field: customField,
    };

    const newEq = {
      ...equipment,
      fields: [
        ...equipment.fields.filter((f) => {
          return f.custom_equipment_field.id !== customField.id;
        }),
        fieldValue,
      ],
    };

    setEquipment(newEq);
  };

  const handleCustomFieldVisibilityChange = (
    admin_only: boolean,
    field: EquipmentFieldValue | undefined,
    customField: CustomEquipmentField
  ) => {
    const fieldValue = {
      id: field?.id || -1,
      value: field?.value || "",
      value_long: field?.value_long || "",
      admin_only,
      custom_equipment_field: customField,
    };

    const newEq = {
      ...equipment,
      fields: [
        ...equipment.fields.filter((f) => {
          return f.custom_equipment_field.id !== customField.id;
        }),
        fieldValue,
      ],
    };

    setEquipment(newEq);
  };

  const allProcessAreas = useMemo(() => {
    if (isAdmin) {
      return availableAreas || [];
    }

    return Object.values(user?.writeProcessAreasMap || {});
  }, [isAdmin, availableAreas, user?.writeProcessAreasMap]);

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: 600,
              maxWidth: "100%",
            }}
          >
            <DialogContent>
              {Boolean(allProcessAreas.length) && (
                <FormControl margin="dense" fullWidth>
                  <Autocomplete
                    disabled={loading}
                    multiple
                    value={equipment.process_areas || []}
                    onChange={(_, v) => {
                      if (v) {
                        setEquipment((prev) => ({ ...prev, process_areas: v }));
                      }
                    }}
                    options={allProcessAreas}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Process Areas" />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterSelectedOptions
                  />
                </FormControl>
              )}

              {Object.values(DefaultHeaderTitles).map((t) => {
                const defaultName =
                  DEFAULT_FIELDS_MAP[t as keyof typeof DEFAULT_FIELDS_MAP];

                if (t === DefaultHeaderTitles.DATE) {
                  let date = equipment.date_of_manufacture;

                  if (typeof date === "string") {
                    date = dayjs(date);
                  }

                  return (
                    <Box
                      key={t}
                      display="flex"
                      justifyContent="space-between"
                      gap={0.5}
                      alignItems="center"
                    >
                      <BasicDatePicker
                        label={t}
                        value={date}
                        handleOnChange={(event: any) => {
                          setEquipment({
                            ...equipment,
                            date_of_manufacture: event,
                          });
                        }}
                      />
                      {selectedOrg?.enable_per_equipment_visibility && (
                        <Tooltip
                          placement="left"
                          title={
                            defaultFieldsConfig[t]?.admin_only
                              ? "Hidden from regular users"
                              : "Visible for all users"
                          }
                        >
                          <IconButton
                            onClick={() =>
                              setDefaultFieldsConfig((prev) => ({
                                ...prev,
                                [t]: { admin_only: !prev[t]?.admin_only },
                              }))
                            }
                          >
                            {defaultFieldsConfig[t]?.admin_only ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  );
                }

                return (
                  <Box
                    key={t}
                    display="flex"
                    justifyContent="space-between"
                    gap={0.5}
                    alignItems="center"
                  >
                    <TextField
                      margin="dense"
                      id={defaultName}
                      name={defaultName}
                      value={equipment[defaultName as keyof Equipment]}
                      label={t}
                      type="text"
                      fullWidth
                      variant="outlined"
                      onChange={(event) => handleOnChangeFormField(event)}
                    />
                    {selectedOrg?.enable_per_equipment_visibility &&
                      defaultName !== "eid" && (
                        <Tooltip
                          placement="left"
                          title={
                            defaultFieldsConfig[t]?.admin_only
                              ? "Hidden from regular users"
                              : "Visible for all users"
                          }
                        >
                          <IconButton
                            onClick={() =>
                              setDefaultFieldsConfig((prev) => ({
                                ...prev,
                                [t]: { admin_only: !prev[t]?.admin_only },
                              }))
                            }
                          >
                            {defaultFieldsConfig[t]?.admin_only ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                  </Box>
                );
              })}

              {customEquipmentFields &&
                customEquipmentFields.map((field, i) => (
                  <Box
                    key={i}
                    display="flex"
                    justifyContent="space-between"
                    gap={0.5}
                    alignItems="center"
                  >
                    <CustomFieldValueInput
                      field={field}
                      fieldValue={equipmentFieldValuesMap[field.id]}
                      onChange={handleCustomFieldChange}
                      onError={(id: number, msg: string) => {
                        if (msg) {
                          setCFieldsErrMap({
                            ...cFieldsErrMap,
                            [id]: msg,
                          });
                        } else {
                          const newErrMap = { ...cFieldsErrMap };
                          delete newErrMap[id];
                          setCFieldsErrMap(newErrMap);
                        }
                      }}
                    />
                    {selectedOrg?.enable_per_equipment_visibility && (
                      <Tooltip
                        placement="left"
                        title={
                          equipmentFieldValuesMap[field.id]?.admin_only
                            ? "Hidden from regular users"
                            : "Visible for all users"
                        }
                      >
                        <IconButton
                          onClick={() =>
                            handleCustomFieldVisibilityChange(
                              !equipmentFieldValuesMap[field.id]?.admin_only,
                              equipmentFieldValuesMap[field.id],
                              field
                            )
                          }
                        >
                          {equipmentFieldValuesMap[field.id]?.admin_only ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                ))}

              {Boolean(modalErrMsg.length) && (
                <Typography variant="body1" component="p" color={"red"}>
                  {modalErrMsg}
                </Typography>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditing && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    Delete
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={Object.keys(cFieldsErrMap).length > 0}
                >
                  {isEditing ? "Update" : "Create"}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditing) {
            handleDelete();
          }
        }}
        text="Do you really want to delete the equipment?"
      />
    </div>
  );
};

export default EquipmentModal;
