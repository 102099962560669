import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "@apollo/client";
import { IconButton, Link, Typography } from "@mui/material";
import { AddCircle, Visibility } from "@mui/icons-material";
import { Container } from "@mui/system";

import { MaintenanceRequest } from "./types";
import Header from "../components/Header";
import { GET_MAINTENANCE_REQUESTS } from "./graphql";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../Equipments/constants";
import MaintenanceModal from "./modal";
import { ROUTES } from "../../Router";

const MaintenanceRequests = () => {
  const [maintenanceRequests, setMaintenanceRequests] = React.useState<
    MaintenanceRequest[]
  >([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [extMaintenanceRequest, setExtMaintenanceRequest] =
    React.useState<MaintenanceRequest | null>(null);

  const { loading, refetch } = useQuery(GET_MAINTENANCE_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted: (data: any) => {
      const parsed = data.maintenanceRequests.data.map(
        (maintenanceRequest: any) => {
          let equipment = null;
          let requested_by = null;

          if (maintenanceRequest.attributes.equipment.data) {
            equipment = {
              id: maintenanceRequest.attributes.equipment.data.id,
              ...maintenanceRequest.attributes.equipment.data.attributes,
            };
          }

          if (maintenanceRequest.attributes.requested_by.data) {
            requested_by = {
              id: maintenanceRequest.attributes.requested_by.data.id,
              ...maintenanceRequest.attributes.requested_by.data.attributes,
            };
          }

          return {
            id: maintenanceRequest.id,
            ...maintenanceRequest.attributes,
            equipment,
            requested_by,
          };
        }
      );

      setMaintenanceRequests(parsed);
    },
  });

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "cetner",
          justifyContent: "center",
          height: "40px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Header enableBackBtn={false} />
      <Container>
        <Box sx={{ padding: "20px", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4" component="h6">
              Maintenance Requests
            </Typography>
            <IconButton
              color="primary"
              component="label"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="Maintenance Requests">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Equipment ID</TableCell>
                    <TableCell>Request Date</TableCell>
                    <TableCell>Requester Name</TableCell>
                    <TableCell>Comments</TableCell>
                    <TableCell>Resolved On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {maintenanceRequests.map((row) => (
                    <TableRow
                      hover
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      key={row.id}
                      onClick={() => {
                        setExtMaintenanceRequest(row);
                        setOpenModal(true);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>
                        {Boolean(row.equipment?.id) && (
                          <Link
                            onClick={(e) => e.stopPropagation()}
                            href={`${ROUTES.EQUIPMENTS}/${row.equipment?.id}`}
                            target="_blank"
                            underline="none"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                            title="View Equipment"
                          >
                            <span style={{ marginRight: 5 }}>
                              {row.equipment?.eid}
                            </span>
                            <Visibility />
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(row.date).format(TABLE_DATE_FORMAT)}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <span
                          style={{
                            maxWidth: 200,
                            display: "block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.comments}
                        </span>
                      </TableCell>
                      <TableCell>
                        {row.resolved_on &&
                          moment(row.resolved_on).format(TABLE_DATE_FORMAT)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
      <MaintenanceModal
        extMaintenanceRequest={extMaintenanceRequest}
        equipment={null}
        setOpenModal={() => setOpenModal(false)}
        openModal={openModal}
        onCreateCB={() => refetch()}
        onUpdateCB={() => refetch()}
        onDeleteCB={() => refetch()}
        onCloseCB={() =>
          setTimeout(() => {
            setExtMaintenanceRequest(null);
          }, 100)
        }
      />
    </React.Fragment>
  );
};

export default MaintenanceRequests;
