import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { FC, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import theme from "../../../theme";
import { EquipmentTableHeaders } from "../types";
import { useAppSelector } from "../../../redux/hooks";
import { selectUserState } from "../../../redux/reducers/user";
import ConfirmDialog from "../../components/ConfirmDialog";
import useFieldFilters from "../../../hooks/table/useFieldFilters";
import useSortBy from "../../../hooks/table/useSortBy";
import { getFormattedDefaultFieldName } from "../helpers";

interface Props {
  open: boolean;
  onClose: () => void;
  headers: EquipmentTableHeaders;
  defaultHeaders: EquipmentTableHeaders | null;
  onSave: (headers: EquipmentTableHeaders) => void;
}

const ColumnCustomizationModal: FC<Props> = ({
  open,
  onClose,
  headers,
  defaultHeaders,
  onSave,
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (!open) {
      setOpenConfirmModal(false);
    }
  }, [open]);

  const { filters } = useFieldFilters();
  const { sortBy } = useSortBy();

  const usedFields = useMemo(() => {
    return new Set([
      ...filters.map((f) => f.name),
      ...sortBy.map((s) => s.split(":")[0]),
      ...sortBy.map((s) => getFormattedDefaultFieldName(s.split(":")[0])),
    ]);
  }, [filters, sortBy]);

  const { user } = useAppSelector(selectUserState);
  const isAdmin = user?.role.type === "administrator";

  const selectedOrg = useMemo(() => {
    return user?.organizations?.find(
      (org) => org.id === user.selected_organization_id,
    );
  }, [user]);

  const [customizedHeaders, setCustomizedHeaders] = useState(headers);

  useEffect(() => {
    setCustomizedHeaders(headers);
  }, [headers, open]);

  const resetHeaders = () => {
    if (defaultHeaders) {
      setCustomizedHeaders(defaultHeaders);
    }
  };

  const toggleVisibility = (index: number) => {
    const updatedHeaders = customizedHeaders.map((h, idx) =>
      idx === index ? { ...h, isActive: !h.isActive } : h,
    );

    setCustomizedHeaders(updatedHeaders);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(customizedHeaders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCustomizedHeaders(items);
  };

  useEffect(() => {
    setCustomizedHeaders(headers);
  }, [headers]);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Columns Customization</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Welcome to your customizable table view! Here's what you can do:
          </Typography>
          <Typography variant="body2">
            Reorder Columns: Simply click and hold the column header, then drag
            it up or down to reorder it as you see fit. The layout will
            automatically update to reflect your preferred order.
          </Typography>
          <Typography variant="body2">
            Show/Hide Columns: Decide what information is important to you.
          </Typography>

          <Box sx={{ marginTop: 2 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-headers" direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {customizedHeaders.map((header, index) => {
                      const isAdminOnly =
                        header.isAdminOnly &&
                        selectedOrg?.enable_field_visibility_toggle;

                      if (!isAdmin && isAdminOnly) return null;

                      return (
                        <Draggable
                          key={header.title}
                          draggableId={header.title}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              px={2}
                              bgcolor={"white"}
                              border={1}
                              borderColor={theme.color.primary}
                              borderRadius={1}
                              fontSize={"sm"}
                              sx={{ marginBottom: 1 }}
                            >
                              <Typography variant="subtitle2">
                                {header.title}
                              </Typography>
                              <div>
                                {isAdminOnly && (
                                  <Typography variant="caption">
                                    Administrators Only
                                  </Typography>
                                )}
                                <IconButton
                                  color="primary"
                                  onClick={() => toggleVisibility(index)}
                                  sx={{ marginLeft: 5 }}
                                >
                                  {header.isActive ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </div>
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Box display="flex" gap={1}>
            <Button onClick={resetHeaders}>Reset</Button>
            <Button
              onClick={() =>
                customizedHeaders
                  .filter((h) => !h.isActive)
                  .some(({ title }) => usedFields.has(title))
                  ? setOpenConfirmModal(true)
                  : onSave(customizedHeaders)
              }
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={(b) => setOpenConfirmModal(b)}
        text="Some of the hidden fields are used in filtering or sorting. Those settings will be removed."
        confirmHandler={() => onSave(customizedHeaders)}
      />
    </>
  );
};

export default ColumnCustomizationModal;
