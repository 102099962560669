import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const DEFAULT_PAGE_OPTIONS = [10, 50, 100];

export enum PaginationSearchParams {
  ROWS = "rows",
  PAGE = "page",
}

const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { page, rowsPerPage } = useMemo(() => {
    const rowsPerPage = searchParams.has(PaginationSearchParams.ROWS)
      ? parseInt(searchParams.get(PaginationSearchParams.ROWS)!)
      : DEFAULT_PAGE_OPTIONS[0];
    const page = searchParams.has(PaginationSearchParams.PAGE)
      ? parseInt(searchParams.get(PaginationSearchParams.PAGE)!)
      : 0;

    return { page, rowsPerPage };
  }, [searchParams]);

  const populateSearchParams = (page: number, rows: number) => {
    if (!page) {
      searchParams.delete(PaginationSearchParams.PAGE);
    } else {
      searchParams.set(PaginationSearchParams.PAGE, page.toString());
    }

    if (!rows || rows === DEFAULT_PAGE_OPTIONS[0]) {
      searchParams.delete(PaginationSearchParams.ROWS);
    } else {
      searchParams.set(PaginationSearchParams.ROWS, rows.toString());
    }

    setSearchParams(searchParams);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    populateSearchParams(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    populateSearchParams(0, rowsPerPage);
  };

  return { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage };
};

export default usePagination;
