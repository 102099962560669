import React, { useEffect, useState } from "react";
import { AddCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AreaModal from "./AreaModal";
import { Access, ProcessArea, ProcessAreaRole } from "./types";
import { useLazyQuery } from "@apollo/client";
import { GET_PROCESS_AREAS, GET_PROCESS_AREA_ROLES } from "./graphql";
import RoleModal from "./RoleModal";

const ProcessAreasAndRolesSection = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalProcessArea, setModalProcessArea] = useState<ProcessArea | null>(
    null
  );
  const [processAreas, setProcessAreas] = useState<ProcessArea[]>([]);
  const [modalProcessAreaRole, setModalProcessAreaRole] =
    useState<ProcessAreaRole | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [processAreaRoles, setProcessAreaRoles] = useState<ProcessAreaRole[]>(
    []
  );

  const [getProcessAreas] = useLazyQuery(GET_PROCESS_AREAS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const processAreas = data.processAreas.data.map((processArea: any) => {
        const processAreaRoles =
          processArea.attributes.process_area_roles.data.map(
            (processAreaRole: any) => {
              return {
                id: processAreaRole.id,
                name: processAreaRole.attributes.name,
                access: processAreaRole.attributes.access,
              };
            }
          );

        return {
          id: processArea.id,
          name: processArea.attributes.name,
          process_area_roles: processAreaRoles,
        };
      });

      setProcessAreas(processAreas);
    },
  });

  const [getProcessAreaRoles] = useLazyQuery(GET_PROCESS_AREA_ROLES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const processAreaRoles = data.processAreaRoles.data.map(
        (processAreaRole: any) => {
          const processAreas =
            processAreaRole.attributes.process_areas.data.map(
              (processArea: any) => {
                return {
                  id: processArea.id,
                  name: processArea.attributes.name,
                };
              }
            );

          return {
            id: processAreaRole.id,
            name: processAreaRole.attributes.name,
            access: processAreaRole.attributes.access,
            process_areas: processAreas,
          };
        }
      );

      setProcessAreaRoles(processAreaRoles);
    },
  });

  const refreshData = () => {
    getProcessAreas();
    getProcessAreaRoles();
  };

  useEffect(() => {
    getProcessAreas();
    getProcessAreaRoles();
  }, [getProcessAreas, getProcessAreaRoles]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h6">
              Process Areas
            </Typography>
            <IconButton
              color="primary"
              component="label"
              onClick={() => {
                setModalProcessArea(null);
                setOpenModal(true);
              }}
            >
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
          <Divider />
          <TableContainer>
            <Table stickyHeader aria-label="Process Areas Table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {processAreas.map((processArea) => (
                  <TableRow key={processArea.id}>
                    <TableCell>{processArea.id}</TableCell>
                    <TableCell>{processArea.name}</TableCell>
                    <TableCell>
                      {processArea.process_area_roles?.map(
                        (processAreaRole) => (
                          <Typography key={processAreaRole.id}>
                            {processAreaRole.name}
                          </Typography>
                        )
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ ml: 2 }}
                        onClick={() => {
                          setModalProcessArea(processArea);
                          setOpenModal(true);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h6">
              Process Area Roles
            </Typography>
            <IconButton
              color="primary"
              component="label"
              onClick={() => {
                setModalProcessAreaRole(null);
                setOpenConfirmModal(true);
              }}
            >
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
          <Divider />
          <TableContainer>
            <Table stickyHeader aria-label="Process Area Roles Table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Access</TableCell>
                  <TableCell>Areas</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {processAreaRoles.map((processAreaRole) => (
                  <TableRow key={processAreaRole.id}>
                    <TableCell>{processAreaRole.id}</TableCell>
                    <TableCell>{processAreaRole.name}</TableCell>
                    <TableCell>
                      {
                        Access[
                        processAreaRole.access as any as keyof typeof Access
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      {processAreaRole.process_areas?.map((processArea) => (
                        <Typography key={processArea.id}>
                          {processArea.name}
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ ml: 2 }}
                        onClick={() => {
                          setModalProcessAreaRole(processAreaRole);
                          setOpenConfirmModal(true);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <AreaModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        extProcessArea={modalProcessArea}
        onSubmitCB={refreshData}
        availableRoles={processAreaRoles}
      />
      <RoleModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        extRole={modalProcessAreaRole}
        onSubmitCB={refreshData}
        availableAreas={processAreas}
      />
    </Paper>
  );
};

export default ProcessAreasAndRolesSection;
