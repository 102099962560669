import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setLoggerEnabled } from "../redux/reducers/logger";
import { LogType } from "../types/logger";
import { useMutation } from "@apollo/client";
import { CREATE_LOG } from "../redux/reducers/logger/graphql";

export const useLogger = () => {
  const dispatch = useAppDispatch();

  const { isEnabled } = useAppSelector((s) => s.logger);

  const setLoggerIsEnabled = useCallback(
    (enabled: boolean) => {
      dispatch(setLoggerEnabled(enabled));
    },
    [dispatch]
  );

  const [createLog] = useMutation(CREATE_LOG, {
    onError: (e) => {
      console.error(e);
    },
  });

  const logEvent = (
    type: LogType,
    payload?: { message?: string; resourceId?: number }
  ) => {
    createLog({
      variables: {
        logType: type,
        loggedAt: new Date().toISOString(),
        message: payload?.message,
        resourceId: Number(payload?.resourceId) ?? undefined,
      },
    });
  };

  const logEventAsync = async (
    type: LogType,
    payload?: { message?: string; resourceId?: number }
  ) => {
    await createLog({
      variables: {
        logType: type,
        loggedAt: new Date().toISOString(),
        message: payload?.message,
        resourceId: Number(payload?.resourceId) ?? undefined,
      },
    });
  };

  return {
    isEnabled,
    setLoggerIsEnabled,
    logEvent,
    logEventAsync
  };
};
