import { Equipment } from "../../Equipments/types";
import { User } from "../../Users/types";

export enum Access {
  read_only = "Read Only",
  read_write = "Read Write",
}

export type ProcessAreaRole = {
  id: number;
  name: string;
  access: Access;
  process_areas?: ProcessArea[];
  users?: User[];
};

export type ProcessArea = {
  id: number;
  name: string;
  process_area_roles?: ProcessAreaRole[];
  equipments?: Equipment[];
};
