import React from "react";
import { HexColorPicker } from "react-colorful";
import CheckIcon from "@mui/icons-material/Check";

const colors = [
  "#d32f2f",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#FF9800",
  "#FF5722",
  "#795548",
];

const AlertColorPicker: React.FC<{
  selectedColor: string;
  setSelectedColor: (color: string) => void;
}> = ({ selectedColor, setSelectedColor }) => {
  const handleColorChange = (newColor: any) => {
    setSelectedColor(newColor);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        marginTop: 12,
      }}
    >
      <div>
        <HexColorPicker
          style={{
            width: 170,
            height: 130,
          }}
          color={selectedColor}
          onChange={handleColorChange}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 5,
        }}
      >
        {colors.map((color, i) => (
          <div
            key={i}
            className="color"
            style={{
              backgroundColor: color,
              width: 35,
              height: 35,
              borderRadius: "50%",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() => {
              setSelectedColor(color);
            }}
          >
            {selectedColor === color && (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                }}
              >
                <CheckIcon />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlertColorPicker;
