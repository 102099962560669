import { DEFAULT_FIELDS_MAP, TABLE_DATE_FORMAT } from "../constants";
import { Equipment, EquipmentFieldValue } from "../types";
import moment from "moment";

export const processCustomFields = (data: Equipment[]) =>
  data.map((equipment) => {
    let customFieldsRes = {};

    if (equipment.fields) {
      customFieldsRes = equipment.fields.reduce((acc, field) => {
        const fieldName = field.custom_equipment_field.name;

        return {
          ...acc,
          [fieldName]: renderCustomFieldValue(field),
        };
      }, {});
    }

    return { ...customFieldsRes, ...equipment };
  });

export const renderCustomFieldValue = (
  field: EquipmentFieldValue | undefined
) => {
  if (!field) {
    return null;
  }

  switch (field.custom_equipment_field.type) {
    case "ShortText":
    case "Number":
      return field.value;
    case "LongText":
      return field.value_long;
    case "Date":
      return moment(field.value).format(TABLE_DATE_FORMAT);
    case "Checkbox":
      return field.value === "true" ? "Yes" : "No";
    case "Link":
      let link = field.value;

      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = `http://${link}`;
      }
      return (
        <a href={link} target="_blank" rel="noreferrer">
          {field.value}
        </a>
      );
    default:
      return null;
  }
};

export const obfuscateString = (str: string) => {
  return (
    <span style={{ filter: "blur(3px)" }}>{str.replace(/[^ ]/g, "x")}</span>
  );
}

export const getDefaultFieldName = (str: string) => {
  const fieldName = DEFAULT_FIELDS_MAP[str as keyof typeof DEFAULT_FIELDS_MAP];
  return fieldName ? fieldName : undefined;
};

export const getFormattedDefaultFieldName = (str: string) => {
  const entries = Object.entries(DEFAULT_FIELDS_MAP);
  return entries.find((e) => e[1] === str)?.[0];
};
