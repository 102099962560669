import { Box } from "@mui/material";
import useFieldFilters from "../../../hooks/table/useFieldFilters";
import {
  conditionNameMap,
  getFieldsMap,
} from "../../Settings/CustomAlertsSection/helpers";
import { FieldProps } from "../../Settings/CustomAlertsSection/types";
import dayjs from "dayjs";
import FiltersPopover from "./FiltersPopover";
import { useAppSelector } from "../../../redux/hooks";
import { selectCustomFields } from "../../../redux/reducers/custom-field";
import { useMemo } from "react";
import { TABLE_DATE_FORMAT } from "../constants";

const FilterChips = () => {
  const customEquipmentFields = useAppSelector(selectCustomFields);
  const fieldProps = useMemo(
    () => Object.values(getFieldsMap(customEquipmentFields)),
    [customEquipmentFields],
  );

  const { filters } = useFieldFilters();

  if (!filters.length) return null;

  return (
    <Box marginY={2} display="flex" width="100%" gap={1} flexWrap="wrap">
      {[...filters].reverse().map((f) => {
        const label = `${f.name} ${
          conditionNameMap[f.cond as keyof typeof conditionNameMap]
        } ${getFormattedValue(f.value, f.type)}`;
        return (
          <FiltersPopover
            key={label}
            editMode
            field={fieldProps.find((p) => p.name === f.name)}
            condition={f.cond}
            value={f.value}
            label={label}
          />
        );
      })}
    </Box>
  );
};

const getFormattedValue = (v: string, type: FieldProps["type"]) => {
  switch (type) {
    case "boolean":
      return `"${v === "true" ? "yes" : "no"}"`;
    case "text":
      return `"${v}"`;
    case "date":
      return dayjs(v).format(TABLE_DATE_FORMAT);
    case "number":
    default:
      return v;
  }
};

export default FilterChips;
