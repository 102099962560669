import { gql } from "@apollo/client";

export const GET_EQUIPMENT_LOGINS = gql`
  query GetEquipmentLogins($eqId: ID!) {
    equipmentLogins(
      filters: { equipment: { id: { eq: $eqId } } }
      sort: ["createdAt:desc"]
    ) {
      data {
        id
        attributes {
          createdAt
          name {
            data {
              id
              attributes {
                name
              }
            }
          }
          date
          start_time
          end_time
          signature
        }
      }
    }
  }
`;

export const CREATE_EQUIPMENT_LOGIN = gql`
  mutation createEquipmentLogin(
    $nameId: ID!
    $date: Date!
    $start_time: Time!
    $end_time: Time!
    $signature: String!
    $equipment: ID!
    $organization: ID!
  ) {
    createEquipmentLogin(
      data: {
        name: $nameId
        date: $date
        start_time: $start_time
        end_time: $end_time
        signature: $signature
        equipment: $equipment
        organization: $organization
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const GET_ORGANIZATION_BY_ID = (id?: number) => gql`
  query GetSelectedOrganization {
    organization(id: ${id}) {
      data {
        id
        attributes {
          name
          enable_branding_removal
          remove_branding
          export_analytics
          enable_custom_alerts
          enable_field_visibility_toggle
          enable_per_equipment_visibility
          default_fields_config
          enable_equipment_login
          eq_login_names {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_EQUIPMENT_BY_ID = (
  id: any,
  isAuthed: boolean,
  isAdmin: boolean
) => {
  let queryMaintenanceRequests = "";
  let queryAdminOnlyFields = "";

  if (isAuthed) {
    queryMaintenanceRequests = `
      maintenance_requests(filters: { resolved_on: null }) {
        data {
          id
          attributes {
            name
            date
            comments
            resolved_on
          }
        }
      }
      process_areas {
        data {
          id
          attributes {
            name
          }
        }
      }
    `;
  }

  if (isAdmin) {
    queryAdminOnlyFields = `
      process_areas {
        data {
          id
          attributes {
            name
          }
        }
      }
    `;
  }

  return gql`
    query GetEquipment {
      equipment(id: ${id}) {
        data {
          id
          attributes {
            eid
            type
            manufacturer
            date_of_manufacture
            serial_number
            part_number
            construction_material
            default_fields_config
            organization {
              data {
                id
                attributes {
                  name
                  remove_branding
                }
              }
            }
            fields {
              data {
                id
                attributes {
                  value
                  value_long
                  admin_only
                  custom_equipment_field {
                    data {
                      id
                      attributes {
                        name
                        type
                      }
                    }
                  }
                }
              }
            }
            ${queryMaintenanceRequests}
            ${queryAdminOnlyFields}
          }
        }
      }
    }
  `;
};

export const CREATE_EQUIPMENT = gql`
  mutation createEquipment(
    $eid: String!
    $type: String
    $manufacturer: String
    $date_of_manufacture: Date
    $serial_number: String
    $part_number: String
    $construction_material: String
    $default_fields_config: JSON
    $process_areas: [ID]
  ) {
    createEquipment(
      data: {
        eid: $eid
        type: $type
        manufacturer: $manufacturer
        date_of_manufacture: $date_of_manufacture
        serial_number: $serial_number
        part_number: $part_number
        construction_material: $construction_material
        default_fields_config: $default_fields_config
        process_areas: $process_areas
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_EQUIPMENT = gql`
  mutation updateEquipment(
    $id: ID!
    $eid: String
    $type: String
    $manufacturer: String
    $date_of_manufacture: Date
    $serial_number: String
    $part_number: String
    $construction_material: String
    $default_fields_config: JSON
    $process_areas: [ID]
  ) {
    updateEquipment(
      id: $id
      data: {
        eid: $eid
        type: $type
        manufacturer: $manufacturer
        date_of_manufacture: $date_of_manufacture
        serial_number: $serial_number
        part_number: $part_number
        construction_material: $construction_material
        default_fields_config: $default_fields_config
        process_areas: $process_areas
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_EQUIPMENT = gql`
  mutation deleteEquipment($id: ID!) {
    deleteEquipment(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPLOAD_EQUIPMENTS = gql`
  mutation uploadEquipments($equipments: String!) {
    uploadEquipments(data: { equipments: $equipments })
  }
`;

export const CREATE_EQUIPMENT_FIELD_VALUE = gql`
  mutation createEquipmentFieldValue(
    $value: String!
    $value_long: String!
    $custom_equipment_field_id: ID!
    $equipment_id: ID!
    $admin_only: Boolean!
  ) {
    createEquipmentFieldValue(
      data: {
        value: $value
        value_long: $value_long
        custom_equipment_field: $custom_equipment_field_id
        equipment: $equipment_id
        admin_only: $admin_only
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_EQUIPMENT_FIELD_VALUE = gql`
  mutation updateEquipmentFieldValue(
    $id: ID!
    $value: String!
    $value_long: String!
    $custom_equipment_field_id: ID!
    $equipment_id: ID!
    $admin_only: Boolean!
  ) {
    updateEquipmentFieldValue(
      id: $id
      data: {
        value: $value
        value_long: $value_long
        custom_equipment_field: $custom_equipment_field_id
        equipment: $equipment_id
        admin_only: $admin_only
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_EQUIPMENT_FIELD_VALUE = gql`
  mutation deleteEquipmentFieldValue($id: ID!) {
    deleteEquipmentFieldValue(id: $id) {
      data {
        id
      }
    }
  }
`;

export const SEARCH_EQUIPMENTS_BY_EID = gql`
  query SearchEquipmentsById($eid: String) {
    equipments(
      filters: { eid: { containsi: $eid } }
      sort: ["createdAt:desc"]
    ) {
      data {
        id
        attributes {
          eid
        }
      }
    }
  }
`;
