import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Log, LogType } from "../../types/logger";
import { useLazyQuery } from "@apollo/client";
import { GET_LOGS_IN_DATE_RANGE } from "./graphql";
import moment from "moment";
import * as XLSX from "xlsx";
import BasicDatePicker from "../components/BasicDatePicker";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { Organization } from "../../redux/reducers/user/types";
import { useLogger } from "../../hooks/useLogger";
import { DATETIME_FORMAT, DATE_FORMAT } from "../../constants/date";

type Props = {
  selectedOrg: Organization;
};

export const ExportLogsSection: FC<Props> = ({ selectedOrg }) => {
  const { logEvent } = useLogger();

  const [{ startDate, endDate }, setPeriod] = useState({
    startDate: dayjs().subtract(1, "week"),
    endDate: dayjs(),
  });

  const [getLogsInRange] = useLazyQuery(GET_LOGS_IN_DATE_RANGE, {
    fetchPolicy: "network-only",
    onCompleted: ({ logs: { data } }) => {
      const logs: Log[] = data.map((l: any) => ({
        id: l.id,
        ...l.attributes,
        user: l.attributes.user.data
          ? {
              id: l.attributes.user.data.id,
              ...l.attributes.user.data.attributes,
            }
          : null,
      }));

      const logRows = logs.map((l) => ({
        ID: l.id,
        Type: l.log_type,
        "Logged at": moment(l.logged_at).format(DATETIME_FORMAT),
        "User ID": l.user?.id ?? "-",
        "User Email": l.user?.email ?? "-",
        "Request IP": l.ip ?? "-",
        "Resource ID": l.resource_id ?? "-",
        Message: l.message ?? "-",
        "Created At": moment(l.createdAt).format(DATETIME_FORMAT),
        "Updated At": moment(l.updatedAt).format(DATETIME_FORMAT),
      }));

      logEvent(LogType.ACTION, {
        message: "Logs export",
      });

      try {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(logRows);

        XLSX.utils.book_append_sheet(wb, ws, "Logs");
        XLSX.writeFile(
          wb,
          `logs-(${startDate.format(DATE_FORMAT)} - ${endDate.format(
            DATE_FORMAT
          )}).xlsx`
        );
      } catch (e) {
        console.error(e);
      }
    },
  });

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h6">
              Export Logs
            </Typography>
            <Box display="flex" gap={1}>
              <BasicDatePicker
                label="Start Date"
                value={startDate}
                handleOnChange={(date) => {
                  if (date) {
                    setPeriod((prev) => ({ ...prev, startDate: date }));
                  }
                }}
              />
              <BasicDatePicker
                label="End Date"
                value={endDate}
                handleOnChange={(date) => {
                  if (date) {
                    setPeriod((prev) => ({ ...prev, endDate: date }));
                  }
                }}
              />
            </Box>
            <Button
              variant="contained"
              onClick={() => {
                getLogsInRange({
                  variables: {
                    startDate: startDate.startOf("day").toISOString(),
                    endDate: endDate.endOf("day").toISOString(),
                  },
                });
              }}
            >
              Export
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
