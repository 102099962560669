import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer, { initialUserState } from "./reducers/user";
import customFieldReducer, {
  initialCustomEqFieldsState,
} from "./reducers/custom-field";
import layoutReducer, { initialLayoutState } from "./reducers/leyout";
import customAlertReducer, {
  initialCustomAlertState,
} from "./reducers/custom-alert";
import loggerReducer from "./reducers/logger";
import equipmentReducer from "./reducers/equipment";

const preloadedState = {
  user: { ...initialUserState },
  customEquipmentFields: [...initialCustomEqFieldsState],
  layout: { ...initialLayoutState },
  customAlerts: [...initialCustomAlertState],
};

const token = localStorage.getItem("token");
const userJSON = localStorage.getItem("user");

if (token) {
  preloadedState.user.jwt = token;
}

if (userJSON) {
  try {
    preloadedState.user.user = JSON.parse(userJSON);
  } catch (e) {
    console.error("Could not load user. Logging out...");
    localStorage.clear();
    window.location.reload();
  }
}

export const store = configureStore({
  reducer: {
    user: userReducer,
    customEquipmentFields: customFieldReducer,
    layout: layoutReducer,
    customAlerts: customAlertReducer,
    logger: loggerReducer,
    equipment: equipmentReducer,
  },
  preloadedState,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
