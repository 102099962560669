import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import {
  CREATE_CUSTOM_EQUIPMENT_FIELD,
  DELETE_CUSTOM_EQUIPMENT_FIELD,
  GET_CUSTOM_EQUIPMENT_FIELDS,
  UPDATE_CUSTOM_EQUIPMENT_FIELD,
} from "./graphql";
import { useMutation, useQuery } from "@apollo/client";
import { CustomEquipmentField } from "../Equipments/types";
import { AddCircle } from "@mui/icons-material";
import ConfirmDialog from "../components/ConfirmDialog";
import {
  selectCustomFields,
  setCustomEquipmentFields,
} from "../../redux/reducers/custom-field";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Organization } from "../../redux/reducers/user/types";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";

const emptyField = {
  id: 0,
  name: "",
  type: "ShortText",
  admin_only: false,
} as CustomEquipmentField;

type ModalProps = {
  open: boolean;
  onClose: () => void;
  extField?: CustomEquipmentField;
  onSubmitCB?: () => void;
  withVisibilityToggle?: boolean;
};

const FieldModal: FC<ModalProps> = ({
  open,
  onClose,
  extField,
  onSubmitCB,
  withVisibilityToggle = false,
}) => {
  const isEditing = extField?.id !== 0;

  const { logEvent } = useLogger();

  const [field, setField] = useState<CustomEquipmentField>(emptyField);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  React.useEffect(() => {
    if (!open) {
      setError("");
    }
  }, [open]);

  const closeModal = () => {
    setOpenConfirmModal(false);
    setLoading(false);
    onClose();
  };

  const [deleteField] = useMutation(DELETE_CUSTOM_EQUIPMENT_FIELD, {
    onCompleted: () => {
      logEvent(LogType.FIELD, {
        message: "Custom field deleted",
        resourceId: extField?.id,
      });
      if (onSubmitCB) onSubmitCB();
      closeModal();
    },
  });

  const [createField] = useMutation(CREATE_CUSTOM_EQUIPMENT_FIELD, {
    onCompleted: ({ createCustomEquipmentField: { data } }) => {
      logEvent(LogType.FIELD, {
        message: "Custom field created",
        resourceId: extField?.id,
      });
      if (onSubmitCB) onSubmitCB();
      closeModal();
    },
    onError: (e) => {
      setError(e.message);
    },
  });

  const [updateField] = useMutation(UPDATE_CUSTOM_EQUIPMENT_FIELD, {
    onCompleted: () => {
      logEvent(LogType.FIELD, {
        message: "Custom field updated",
        resourceId: extField?.id,
      });
      if (onSubmitCB) onSubmitCB();
      closeModal();
    },
    onError: (e) => {
      setError(e.message);
    },
  });

  const saveField = () => {
    setError("");
    if (isEditing) {
      updateField({
        variables: {
          id: field.id,
          name: field.name,
          type: field.type,
          admin_only: field.admin_only ?? false,
        },
      });
    } else {
      createField({
        variables: {
          name: field.name,
          type: field.type,
          admin_only: field.admin_only ?? false,
        },
      });
    }
  };

  React.useEffect(() => {
    if (extField) {
      setField(extField);
    } else {
      setField(emptyField);
    }
  }, [extField]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 56.5,
          width: 400,
          maxWidth: "100%",
          paddingBottom: 45,
        }}
      >
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{field.id ? "Edit" : "Add"} Field</DialogTitle>
        <DialogContent
          style={{
            width: "400px",
            maxWidth: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  label="Name"
                  value={field.name}
                  onChange={(newValue: any) => {
                    setField({ ...field, name: newValue.target.value });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  labelId="type"
                  id="type"
                  value={field.type}
                  label="Type"
                  disabled={isEditing}
                  onChange={(newValue: any) => {
                    setField({ ...field, type: newValue.target.value });
                  }}
                >
                  <MenuItem value="ShortText">Short Text</MenuItem>
                  <MenuItem value="LongText">Long Text</MenuItem>
                  <MenuItem value="Date">Date</MenuItem>
                  <MenuItem value="Link">Link</MenuItem>
                  <MenuItem value="Number">Number</MenuItem>
                  <MenuItem value="Checkbox">Checkbox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <FormHelperText error>{error}</FormHelperText>
            {withVisibilityToggle && (
              <Grid item xs={12}>
                <Box
                  marginTop={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(_, admin_only) => {
                          setField({ ...field, admin_only });
                        }}
                        checked={field.admin_only}
                      />
                    }
                    label="Administrators Only"
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 24px 20px",
          }}
        >
          <div>
            {isEditing && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenConfirmModal(true);
                }}
                color="error"
              >
                Delete
              </Button>
            )}
          </div>
          <div>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={(event) => {
                event.stopPropagation();
                saveField();
              }}
            >
              {isEditing ? "Update" : "Create"}
            </Button>
          </div>
        </DialogActions>
        <ConfirmDialog
          openModal={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          confirmHandler={() => {
            if (isEditing) {
              deleteField({
                variables: {
                  id: field.id,
                },
              });
            }
          }}
          text="Do you really want to delete this custom field?"
        />
      </Dialog>
    </div>
  );
};

const FieldRow = ({
  field,
  handleRowClick,
  withVisibility = false,
}: {
  field: CustomEquipmentField;
  handleRowClick: (field: CustomEquipmentField) => void;
  withVisibility?: boolean;
}) => {
  const renderFieldType = (type: string) => {
    switch (type) {
      case "ShortText":
        return "Short Text";
      case "LongText":
        return "Long Text";
      case "Date":
        return "Date";
      case "Link":
        return "Link";
      case "Number":
        return "Number";
      case "Checkbox":
        return "Checkbox";
      default:
        return "Unknown";
    }
  };

  return (
    <TableRow>
      <TableCell>{field.id}</TableCell>
      <TableCell>{field.name}</TableCell>
      <TableCell>{renderFieldType(field.type)}</TableCell>
      {withVisibility && (
        <TableCell>
          {field.admin_only ? "Administrators Only" : "Everyone"}
        </TableCell>
      )}
      <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 2 }}
          onClick={() => {
            handleRowClick(field);
          }}
        >
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
};

type Props = {
  selectedOrg: Organization;
};

const CustomEqFieldsSection: FC<Props> = ({ selectedOrg }) => {
  const { enable_field_visibility_toggle: enableVisibilityToggle } =
    selectedOrg;

  const dispatch = useAppDispatch();
  const customEquipmentFields = useAppSelector(selectCustomFields);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalField, setModalField] =
    useState<CustomEquipmentField>(emptyField);

  const { loading: loadingCustomFields, refetch } = useQuery(
    GET_CUSTOM_EQUIPMENT_FIELDS,
    {
      onCompleted: (data: any) => {
        dispatch(setCustomEquipmentFields(data));
      },
    }
  );

  const handleRowClick = (field: CustomEquipmentField) => {
    setModalField(field);
    setOpenModal(true);
  };

  if (loadingCustomFields) return <div>Loading...</div>;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h6">
              Custom Equipment Fields
            </Typography>
            <IconButton
              color="primary"
              component="label"
              onClick={() => {
                setModalField(emptyField);
                setOpenModal(true);
              }}
            >
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="Equipments table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              {enableVisibilityToggle && <TableCell>Visibility</TableCell>}
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {customEquipmentFields.map((field) => (
              <FieldRow
                key={field.id}
                field={field}
                handleRowClick={handleRowClick}
                withVisibility={enableVisibilityToggle}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FieldModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        extField={modalField}
        onSubmitCB={() => {
          refetch();
        }}
        withVisibilityToggle={enableVisibilityToggle}
      />
    </Paper>
  );
};

export default CustomEqFieldsSection;
