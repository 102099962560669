import {
  StrapiAttributes,
  StrapiCollection,
  StrapiData,
} from "../../../types/api";
import { MaintenanceRequest } from "../../MaintenanceRequests/types";
import {
  CustomEquipmentField,
  Equipment,
  EquipmentFieldValue,
  EquipmentFieldValueDTO,
  EquipmentsDTO,
} from "../types";

// Parses equipment data.
export const parseEquipments = (equipmentData?: EquipmentsDTO): Equipment[] => {
  if (
    !equipmentData ||
    !equipmentData.equipments ||
    !equipmentData.equipments.data
  ) {
    return [];
  }

  return equipmentData.equipments.data.map((equipment) => {
    const parsedEquipment = {
      ...equipment.attributes,
      id: equipment.id,
      fields: parseEquipmentFields(
        equipment.attributes.fields
      ) as EquipmentFieldValue[],
      maintenance_requests: parseMaintenanceRequests(
        equipment.attributes.maintenance_requests
      ) as MaintenanceRequest[],
    };

    return parsedEquipment;
  });
};

export const parseMaintenanceRequests = (requests: any) => {
  if (!requests || !requests.data) {
    return [];
  }

  return requests.data.map((request: any) => {
    return {
      ...request.attributes,
      id: request.id,
    };
  });
};

// Parses field(s) of an equipment.
export const parseEquipmentFields = (
  fields: StrapiCollection<EquipmentFieldValueDTO>
): EquipmentFieldValue | EquipmentFieldValue[] => {
  if (!fields || !fields.data) {
    return [];
  }

  const processField = (f: StrapiAttributes<EquipmentFieldValueDTO>) => {
    return {
      ...f.attributes,
      id: f.id,
      custom_equipment_field: parseCustomEquipmentFields(
        f.attributes.custom_equipment_field
      ) as CustomEquipmentField,
    };
  };

  const { data } = fields;

  if (Array.isArray(data)) {
    return data.map(processField);
  }

  return processField(data);
};

// Parses custom field(s) of an equipment.
export const parseCustomEquipmentFields = (
  fields?:
    | StrapiData<CustomEquipmentField>
    | StrapiCollection<CustomEquipmentField>
): CustomEquipmentField | CustomEquipmentField[] => {
  if (!fields || !fields.data) {
    return [] as CustomEquipmentField[];
  }

  const { data } = fields;

  const processField = (f: StrapiAttributes<CustomEquipmentField>) => {
    return {
      ...f.attributes,
      id: f.id,
    };
  };

  if (Array.isArray(data)) {
    return data.map(processField);
  }

  return processField(data);
};
