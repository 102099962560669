import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GET_EQUIPMENT_LOGINS } from "../graphql";
import React, { FC } from "react";
import { Equipment, EquipmentLogin } from "../types";
import dayjs from "dayjs";
import { TABLE_DATE_FORMAT } from "../constants";
import { EquipmentLoginModal } from "../../components/EquipmentLoginModal";
import { useAppSelector } from "../../../redux/hooks";

type Props = {
  eq: Equipment;
  selectedOrg?: any;
};

export const EquipmentLoginTable: FC<Props> = ({ eq, selectedOrg }) => {
  const { user } = useAppSelector((s) => s.user);

  const isAdmin = user?.role.type === "administrator";

  const [openLoginModal, setOpenLoginModal] = React.useState(false);

  const [logins, setLogins] = React.useState<EquipmentLogin[]>([]);

  const { loading, refetch } = useQuery(GET_EQUIPMENT_LOGINS, {
    variables: { eqId: eq.id },
    onCompleted: ({ equipmentLogins: { data } }) => {
      const formatted = data.map((el: any) => ({
        ...el.attributes,
        id: el.id,
        name: el.attributes.name.data?.attributes.name,
      }));
      setLogins(formatted);
    },
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingBottom={4}
      >
        <Button onClick={() => setOpenLoginModal(true)} variant="contained">
          Log in to {eq.type ?? "Equiupment"}
        </Button>
      </Box>
      {isAdmin && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography
            variant="h5"
            marginBottom={2}
            sx={{ width: "100%", maxWidth: 800 }}
          >
            Equipment Logins
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="logins table">
              {!logins.length && <caption>No equipment logins</caption>}
              <TableHead>
                <TableRow>
                  <TableCell>Created At</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Start Time</TableCell>
                  <TableCell align="right">End Time</TableCell>
                  <TableCell align="right">Signature</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logins.map((l) => (
                  <TableRow
                    key={l.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {dayjs(l.createdAt).format(TABLE_DATE_FORMAT)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {l.name}
                    </TableCell>
                    <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                      {dayjs(l.date).format(TABLE_DATE_FORMAT)}
                    </TableCell>
                    <TableCell align="right">
                      {formatTime(l.start_time)}
                    </TableCell>
                    <TableCell align="right">
                      {formatTime(l.end_time)}
                    </TableCell>
                    <TableCell width={1} align="right">
                      <img
                        style={{ aspectRatio: "16/9", height: 100 }}
                        src={l.signature}
                        alt="Signature"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <EquipmentLoginModal
        onSuccess={refetch}
        open={openLoginModal}
        onClose={() => setOpenLoginModal(false)}
        eq={eq}
        selectedOrg={selectedOrg}
      />
    </>
  );
};

const formatTime = (time: string) => {
  return time.split(":").slice(0, 2).join(":");
};
