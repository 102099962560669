import { CustomEquipmentField } from "../../Equipments/types";

export type EqType =
  | "ShortText"
  | "LongText"
  | "Link"
  | "Date"
  | "Number"
  | "Checkbox";

export enum NotificationType {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export interface CustomAlertProps {
  id: number;
  relation: "equipment" | "custom_equipment_field" | "maintenance_request";
  type: "text" | "date" | "number" | "boolean";
  condition:
    | "equals"
    | "startsWith"
    | "endsWith"
    | "contains"
    | "before"
    | "beforeOrOn"
    | "on"
    | "onOrAfter"
    | "after"
    | "lessThan"
    | "lessThanOrEqual"
    | "equal"
    | "greaterThanOrEqual"
    | "greaterThan"
    | "is";
  field_key: string;
  value: string;
  color: string;
  custom_equipment_field?: CustomEquipmentField | null;
  notification_type: NotificationType;
  notification_recipients: string;
  notification_subject: string;
  notification_body: string;
  enable_notifications: boolean;
}

export type FieldProps = {
  id: number;
  relation: "equipment" | "custom_equipment_field" | "maintenance_request";
  name: string;
  type: "text" | "date" | "number" | "boolean";
  custom_equipment_field?: CustomEquipmentField;
  custom_alerts?: CustomAlertProps[];
};

export type FieldsMap = {
  [key: string]: FieldProps;
};

export type ConditionValusMap = {
  [key in "text" | "date" | "number" | "boolean"]: Array<
    | "equals"
    | "startsWith"
    | "endsWith"
    | "contains"
    | "before"
    | "beforeOrOn"
    | "on"
    | "onOrAfter"
    | "after"
    | "lessThan"
    | "lessThanOrEqual"
    | "equal"
    | "greaterThanOrEqual"
    | "greaterThan"
    | "is"
  >;
};

export type ConditionNameMap = {
  [key in
    | "equals"
    | "startsWith"
    | "endsWith"
    | "contains"
    | "before"
    | "beforeOrOn"
    | "on"
    | "onOrAfter"
    | "after"
    | "lessThan"
    | "lessThanOrEqual"
    | "equal"
    | "greaterThanOrEqual"
    | "greaterThan"
    | "is"]: string;
};
