import { gql } from "@apollo/client";

export const GET_PROCESS_AREAS = gql`
  query GetProcessAreas {
    processAreas {
      data {
        id
        attributes {
          name
          process_area_roles {
            data {
              id
              attributes {
                name
                access
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROCESS_AREAS_ONLY = gql`
  query GetProcessAreas {
    processAreas {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const CREATE_PROCESS_AREA = gql`
  mutation CreateProcessArea($name: String!, $process_area_roles: [ID]) {
    createProcessArea(
      data: { name: $name, process_area_roles: $process_area_roles }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_PROCESS_AREA = gql`
  mutation UpdateProcessArea(
    $id: ID!
    $name: String!
    $process_area_roles: [ID]
  ) {
    updateProcessArea(
      id: $id

      data: { name: $name, process_area_roles: $process_area_roles }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_PROCESS_AREA = gql`
  mutation DeleteProcessArea($id: ID!) {
    deleteProcessArea(id: $id) {
      data {
        id
      }
    }
  }
`;

export const GET_PROCESS_AREA_ROLES = gql`
  query GetProcessAreaRoles {
    processAreaRoles {
      data {
        id
        attributes {
          name
          access
          process_areas {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROCESS_AREA_ROLES_ONLY = gql`
  query GetProcessAreaRoles {
    processAreaRoles {
      data {
        id
        attributes {
          name
          access
        }
      }
    }
  }
`;

export const CREATE_PROCESS_AREA_ROLE = gql`
  mutation CreateProcessAreaRole(
    $name: String!
    $access: ENUM_PROCESSAREAROLE_ACCESS!
    $process_areas: [ID]
  ) {
    createProcessAreaRole(
      data: { name: $name, access: $access, process_areas: $process_areas }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_PROCESS_AREA_ROLE = gql`
  mutation UpdateProcessAreaRole(
    $id: ID!
    $name: String!
    $access: ENUM_PROCESSAREAROLE_ACCESS!
    $process_areas: [ID]
  ) {
    updateProcessAreaRole(
      id: $id
      data: { name: $name, access: $access, process_areas: $process_areas }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_PROCESS_AREA_ROLE = gql`
  mutation DeleteProcessAreaRole($id: ID!) {
    deleteProcessAreaRole(id: $id) {
      data {
        id
      }
    }
  }
`;
