import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { formatExcelDate, getEqFieldVal } from "./helpers";

const PreviewTableCell: React.FC<{
  value: string;
}> = ({ value }) => {
  return (
    <TableCell
      title={value}
      style={{
        whiteSpace: "nowrap",
        maxWidth: "200px",
        border: "1px solid #ccc",
        padding: "4px",
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {value}
    </TableCell>
  );
};

const PreviewDialog: React.FC<{
  open: boolean;
  data: any[];
  onClose: () => void;
  onConfirm: () => void;
  equipmentRowHeader: string[];
  customFields: any[];
}> = ({ open, data, onClose, onConfirm, equipmentRowHeader, customFields }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" scroll="body">
      <DialogTitle>The following equipment will be imported:</DialogTitle>
      <DialogContent>
        <Box>
          <Box
            sx={{
              overflow: "auto",
              border: "1px solid #ccc",
              borderRadius: "4px",
              mt: 2,
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {equipmentRowHeader.map((_header, i) => {
                      const header = _header.replace(/^[0-9]+ - /, "");

                      return <PreviewTableCell key={i} value={header} />;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((eq, i) => {
                    return (
                      <TableRow key={i}>
                        <PreviewTableCell value={eq["Equipment ID"]} />
                        <PreviewTableCell value={eq["Type"]} />
                        <PreviewTableCell value={eq["Manufacturer"]} />
                        <PreviewTableCell
                          value={formatExcelDate(eq["Date of manufacture"])}
                        />
                        <PreviewTableCell value={eq["Serial number"]} />
                        <PreviewTableCell value={eq["Part number"]} />
                        <PreviewTableCell
                          value={eq["Material of construction"]}
                        />
                        {customFields.map((field, j) => {
                          return (
                            <PreviewTableCell
                              key={j}
                              value={getEqFieldVal(eq, field)}
                            />
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();

                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();

                // onUpload(data);
                // importEquipment();
                onConfirm();
              }}
            >
              Import
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewDialog;
