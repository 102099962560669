import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { FC } from "react";
import { Equipment } from "../../Equipments/types";
import dayjs, { Dayjs } from "dayjs";
import BasicDatePicker from "../BasicDatePicker";
import SignatureCanvas from "react-signature-canvas";
import { useMutation } from "@apollo/client";
import { CREATE_EQUIPMENT_LOGIN } from "../../Equipments/graphql";
import {
  STRAPI_DATE_FORMAT,
  STRAPI_TIME_FORMAT,
} from "../../../constants/strapi";
import { useLogger } from "../../../hooks/useLogger";
import { LogType } from "../../../types/logger";
import { usePrevious } from "../../../hooks/usePrevious";

type LoginData = {
  name: { id: string; name: string };
  date: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs | null;
};

const initialData: LoginData = {
  name: {
    id: "",
    name: "",
  },
  date: dayjs(),
  startTime: dayjs(),
  endTime: null,
};

type Props = {
  onClose: DialogProps["onClose"];
  open: DialogProps["open"];
  eq: Equipment;
  onSuccess?: () => void;
  selectedOrg?: any;
};

export const EquipmentLoginModal: FC<Props> = ({
  eq,
  onClose,
  onSuccess,
  open,
  selectedOrg,
}) => {
  const { logEvent } = useLogger();
  const sigCanvas = React.useRef<SignatureCanvas>(null);
  const [sigError, setSigError] = React.useState("");
  const [loginData, setLoginData] = React.useState<LoginData>(initialData);

  const [createEqLogin] = useMutation(CREATE_EQUIPMENT_LOGIN, {
    onCompleted: ({ createEquipmentLogin: { data } }) => {
      logEvent(LogType.EQUIPMENT_LOGIN, {
        message: "New equipemnt login",
        resourceId: data.id,
      });
      onSuccess?.();
      onClose?.({}, "backdropClick");

      reset();
    },
  });

  const reset = () => {
    setLoginData(initialData);
    sigCanvas.current?.clear();
    setSigError("");
  };

  const previoslyOpen = usePrevious(open);
  React.useEffect(() => {
    if (!open && previoslyOpen) {
      reset();
    }
  }, [open, previoslyOpen]);

  const handleSubmit = () => {
    const sig = sigCanvas.current;
    if (!sig) return;

    if (sig.isEmpty()) {
      return setSigError("Signature is required");
    }

    const signature = sig.toDataURL();

    createEqLogin({
      variables: {
        equipment: eq.id,
        organization: eq.organization?.id,
        nameId: loginData.name.id,
        date: loginData.date.format(STRAPI_DATE_FORMAT),
        start_time: loginData.startTime.format(STRAPI_TIME_FORMAT),
        end_time: loginData.endTime?.format(STRAPI_TIME_FORMAT),
        signature,
      },
    });
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Log in to {eq.type ?? "Equipment"}</DialogTitle>
      <DialogContent sx={{ display: "grid", gap: 1, placeItems: "center" }}>
        <FormControl sx={{ marginTop: 2 }} fullWidth>
          <Autocomplete
            // disabled={loading}
            value={loginData.name}
            onChange={(_, v) => {
              if (v) {
                setLoginData((prev) => ({ ...prev, name: v }));
              }
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={selectedOrg.eq_login_names}
            // renderInput={(params) => <TextField {...params} label="Name" />}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Name"
                helperText={!loginData.name ? "Name is required" : ""}
              />
            )}
            getOptionLabel={(option) => option.name}
          />
        </FormControl>
        <BasicDatePicker
          value={loginData.date}
          handleOnChange={(date) => {
            if (date) {
              setLoginData((prev) => ({ ...prev, date }));
            }
          }}
          label="Date"
        />
        <Box display="flex" width="100%" gap={2}>
          <BasicDatePicker
            value={loginData.startTime}
            handleOnChange={(startTime) => {
              if (startTime) {
                setLoginData((prev) => ({ ...prev, startTime }));
              }
            }}
            label="Start Time"
            type="multiSectionDigitalClock"
          />
          <BasicDatePicker
            value={loginData.endTime}
            handleOnChange={(endTime) =>
              setLoginData((prev) => ({ ...prev, endTime }))
            }
            label="End Time"
            type="multiSectionDigitalClock"
          />
        </Box>
        <Box>
          <InputLabel>Signature</InputLabel>
          <Box
            border={1}
            borderRadius={1}
            borderColor={"ActiveBorder"}
            width="fit-content"
          >
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{
                width: 300,
                height: 150,
              }}
            />
          </Box>
          <FormHelperText error>{sigError}</FormHelperText>
          <Button onClick={() => sigCanvas.current?.clear()} size="small">
            Clear
          </Button>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={(e) => onClose?.(e, "backdropClick")}>Cancel</Button>
        <Button
          disabled={Object.values(loginData).some((v) => !v)}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
