import { gql } from "@apollo/client";

export const GET_MAINTENANCE_REQUESTS = gql`
  query GetMaintenanceRequests {
    maintenanceRequests(sort: ["resolved:asc", "createdAt:desc"]) {
      data {
        id
        attributes {
          name
          date
          comments
          resolved_on
          equipment {
            data {
              id
              attributes {
                eid
                type
                manufacturer
                date_of_manufacture
                serial_number
                part_number
                construction_material
              }
            }
          }
          requested_by {
            data {
              id
              attributes {
                username
                email
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_MAINTENANCE_REQUEST = gql`
  mutation createMaintenanceRequest(
    $name: String!
    $date: DateTime!
    $comments: String
    $equipment: ID!
    $resolved_on: DateTime
  ) {
    createMaintenanceRequest(
      data: {
        name: $name
        date: $date
        comments: $comments
        equipment: $equipment
        resolved_on: $resolved_on
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_MAINTENANCE_REQUEST = gql`
  mutation updateMaintenanceRequest(
    $id: ID!
    $name: String!
    $date: DateTime!
    $comments: String
    $resolved_on: DateTime
    $resolved: Boolean
  ) {
    updateMaintenanceRequest(
      id: $id
      data: {
        name: $name
        date: $date
        comments: $comments
        resolved_on: $resolved_on
        resolved: $resolved
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_MAINTENANCE_REQUEST = gql`
  mutation deleteMaintenanceRequest($id: ID!) {
    deleteMaintenanceRequest(id: $id) {
      data {
        id
      }
    }
  }
`;
