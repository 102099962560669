import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "@apollo/client";
import { IconButton, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { Container } from "@mui/system";

import { User } from "./types";
import Header from "../components/Header";
import UserModal from "./modal";
import { GET_USERS } from "./graphql";
import { useAppSelector } from "../../redux/hooks";
import { selectUserState } from "../../redux/reducers/user";
import { ProcessAreaRole } from "../Settings/ProcessAreasAndRolesSection/types";
import { GET_PROCESS_AREA_ROLES_ONLY } from "../Settings/ProcessAreasAndRolesSection/graphql";

const Users = () => {
  const { user } = useAppSelector(selectUserState);

  const [users, setUsers] = React.useState<User[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [extUser, setExtUser] = React.useState<User | null>(null);
  const [processAreaRoles, setProcessAreaRoles] = React.useState<
    ProcessAreaRole[]
  >([]);

  const { loading, refetch } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
    onCompleted: (data: any) => {
      setUsers(
        data.usersPermissionsUsers.data.map((user: any) => ({
          id: user.id,
          ...user.attributes,
          role: {
            id: parseInt(user.attributes.role?.data.id),
            ...user.attributes.role?.data.attributes,
          },
          process_area_roles: user.attributes.process_area_roles.data.map(
            (processAreaRole: any) => {
              return {
                id: parseInt(processAreaRole.id),
                ...processAreaRole.attributes,
              };
            }
          ),
        }))
      );
    },
  });

  useQuery(GET_PROCESS_AREA_ROLES_ONLY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const processAreaRoles = data.processAreaRoles.data.map(
        (processAreaRole: any) => {
          return {
            id: parseInt(processAreaRole.id),
            name: processAreaRole.attributes.name,
            access: processAreaRole.attributes.access,
          };
        }
      );

      setProcessAreaRoles(processAreaRoles);
    },
  });

  React.useEffect(() => {
    refetch();
  }, [user?.selected_organization_id, refetch]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "cetner",
          justifyContent: "center",
          height: "40px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Header enableBackBtn={false} />
      <Container>
        <Box sx={{ padding: "20px", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4" component="h6">
              Users
            </Typography>
            {user?.role.type === "administrator" && (
              <IconButton
                color="primary"
                component="label"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <AddCircle fontSize="large" />
              </IconButton>
            )}
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="Users table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row) => (
                    <TableRow
                      hover
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      key={row.id}
                      onClick={() => {
                        setExtUser(row);
                        setOpenModal(true);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.role.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
      <UserModal
        extUser={extUser}
        setOpenModal={() => setOpenModal(false)}
        openModal={openModal}
        availableRoles={processAreaRoles}
        onCreateCB={() => refetch()}
        onUpdateCB={() => refetch()}
        onDeleteCB={() => refetch()}
        onCloseCB={() =>
          setTimeout(() => {
            setExtUser(null);
          }, 100)
        }
      />
    </React.Fragment>
  );
};

export default Users;
