import { gql } from "@apollo/client";

export const GET_CUSTOM_ALERTS = gql`
  query GetCustomAlerts {
    customAlerts {
      data {
        id
        attributes {
          custom_equipment_field {
            data {
              id
              attributes {
                name
                type
              }
            }
          }
          relation
          type
          condition
          field_key
          value
          color
          notification_type
          notification_recipients
          notification_subject
          notification_body
          enable_notifications
        }
      }
    }
  }
`;

export const GET_CUSTOM_ALERTS_BY_RELATION = gql`
  query GetCustomAlertsByRelation($relation: String!) {
    customAlerts(filters: { relation: { eq: $relation } }) {
      data {
        id
        attributes {
          relation
          type
          condition
          field_key
          value
          color
          notification_type
          notification_recipients
          notification_subject
          notification_body
          enable_notifications
        }
      }
    }
  }
`;

export const GET_DEFAULT_FIELDS_ALERTS = gql`
  query GetCustomAlertsWhereTypeEqEquipment {
    customAlerts(filters: { relation: { eq: "equipment" } }) {
      data {
        id
        attributes {
          relation
          type
          condition
          field_key
          value
          color
        }
      }
    }
  }
`;

export const CREATE_CUSTOM_ALERT = gql`
  mutation createCustomAlert(
    $relation: ENUM_CUSTOMALERT_RELATION!
    $type: ENUM_CUSTOMALERT_TYPE!
    $condition: ENUM_CUSTOMALERT_CONDITION!
    $color: String!
    $field_key: String!
    $value: String
    $custom_equipment_field: ID
    $notification_type: ENUM_CUSTOMALERT_NOTIFICATION_TYPE!
    $notification_recipients: String!
    $notification_subject: String!
    $notification_body: String!
    $enable_notifications: Boolean!
  ) {
    createCustomAlert(
      data: {
        relation: $relation
        type: $type
        condition: $condition
        color: $color
        field_key: $field_key
        value: $value
        custom_equipment_field: $custom_equipment_field
        notification_type: $notification_type
        notification_recipients: $notification_recipients
        notification_subject: $notification_subject
        notification_body: $notification_body
        enable_notifications: $enable_notifications
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_CUSTOM_ALERT = gql`
  mutation updateCustomAlert(
    $id: ID!
    $relation: ENUM_CUSTOMALERT_RELATION!
    $type: ENUM_CUSTOMALERT_TYPE!
    $condition: ENUM_CUSTOMALERT_CONDITION!
    $color: String!
    $field_key: String!
    $value: String
    $custom_equipment_field: ID
    $notification_type: ENUM_CUSTOMALERT_NOTIFICATION_TYPE!
    $notification_recipients: String!
    $notification_subject: String!
    $notification_body: String!
    $enable_notifications: Boolean!
  ) {
    updateCustomAlert(
      id: $id
      data: {
        relation: $relation
        type: $type
        condition: $condition
        color: $color
        field_key: $field_key
        value: $value
        custom_equipment_field: $custom_equipment_field
        notification_type: $notification_type
        notification_recipients: $notification_recipients
        notification_subject: $notification_subject
        notification_body: $notification_body
        enable_notifications: $enable_notifications
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_CUSTOM_ALERT = gql`
  mutation deleteCustomAlert($id: ID!) {
    deleteCustomAlert(id: $id) {
      data {
        id
      }
    }
  }
`;
