import { User } from "../redux/reducers/user/types";

export enum LogType {
  USER = "user",
  EQUIPMENT = "equipment",
  FIELD = "field",
  ACTION = "action",
  OTHER = "other",
  CUSTOM_ALERT = "custom_alert",
  MAINTENANCE_REQUEST = "maintenance_request",
  PROCESS_AREA = "process_area",
  PROCESS_AREA_ROLE = "process_area_role",
  EQUIPMENT_LOGIN = "equipment_login",
  NAME = "name",
}

export type Log = {
  id: number;
  log_type: LogType;
  logged_at: string;
  createdAt: string;
  updatedAt: string;
  message: string | null;
  resource_id: number | null;
  user: User | null;
  ip: string;
};
