import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { Autocomplete, Box, CircularProgress, IconButton } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import ConfirmDialog from "../components/ConfirmDialog";
import { MaintenanceRequest } from "./types";
import {
  CREATE_MAINTENANCE_REQUEST,
  DELETE_MAINTENANCE_REQUEST,
  UPDATE_MAINTENANCE_REQUEST,
} from "./graphql";
import { Visibility } from "@mui/icons-material";
import { useAppSelector } from "../../redux/hooks";
import { ROUTES } from "../../Router";
import { Equipment } from "../Equipments/types";
import BasicDatePicker from "../components/BasicDatePicker";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";
import { SEARCH_EQUIPMENTS_BY_EID } from "../Equipments/graphql";

const emptyMaintenanceRequest: MaintenanceRequest = {
  id: 0,
  name: "",
  comments: "",
  date: new Date(),
  resolved_on: null,
};

const MaintenanceModal: React.FC<{
  extMaintenanceRequest: MaintenanceRequest | null;
  equipment: Equipment | null;
  setOpenModal: any;
  openModal: boolean;
  openFromEqPage?: boolean;
  onCreateCB?: (data: any) => void;
  onUpdateCB?: (data: any) => void;
  onDeleteCB?: (data: any) => void;
  onCloseCB?: () => void;
}> = ({
  extMaintenanceRequest,
  equipment,
  setOpenModal,
  openModal,
  openFromEqPage,
  onCreateCB,
  onUpdateCB,
  onDeleteCB,
  onCloseCB,
}) => {
  const { logEvent } = useLogger();
  const isEditing = Boolean(extMaintenanceRequest?.id);
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);
  const idAdmin = user?.role?.type === "administrator";

  const [maintenanceRequest, setMaintenance] = useState<MaintenanceRequest>(
    emptyMaintenanceRequest
  );
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalErrMsg, setModalErrMsg] = useState<string>("");
  const [nameErr, setNameErr] = useState<string>("");
  const [commentsErr, setCommentsErr] = useState<string>("");
  const [eqOptions, setEqOptions] = useState<Equipment[]>([]);
  const [eqError, setEqError] = useState<string>("");
  const [eqSearchTerm, setEqSearchTerm] = useState<string>("");

  const [searchEq] = useLazyQuery(SEARCH_EQUIPMENTS_BY_EID, {
    onCompleted: (data) => {
      setEqOptions(
        data.equipments.data.map((eq: any) => ({
          id: eq.id,
          ...eq.attributes,
        }))
      );
    },
    onError: (e) => {
      console.error(e);
      setEqError(e.message);
    },
  });

  useEffect(() => {
    if (!isEditing) {
      searchEq({
        variables: {
          eid: eqSearchTerm,
        },
      });
    }
  }, [eqSearchTerm, isEditing, searchEq]);

  useEffect(() => {
    if (extMaintenanceRequest) {
      setMaintenance(extMaintenanceRequest);
    } else if (equipment) {
      setMaintenance({
        ...emptyMaintenanceRequest,
        equipment,
      });
    } else {
      setMaintenance({
        ...emptyMaintenanceRequest,
      });
    }
  }, [equipment, setMaintenance, openModal, extMaintenanceRequest]);

  const parseErrorMessage = (msg: string) => {
    if (msg) {
      setModalErrMsg(msg);
    }

    setLoading(false);
  };

  const [createMaintenanceReq] = useMutation(CREATE_MAINTENANCE_REQUEST, {
    onError: (e) => {
      console.error(e);

      parseErrorMessage(e.message);
    },
    onCompleted: (data) => {
      if (onCreateCB) onCreateCB(data);
      const {
        createMaintenanceRequest: {
          data: { id },
        },
      } = data;
      logEvent(LogType.MAINTENANCE_REQUEST, {
        message: "Maintenance request created",
        resourceId: id,
      });
      closeModal();
    },
  });

  const [updateMaintenanceReq] = useMutation(UPDATE_MAINTENANCE_REQUEST, {
    onError: (e) => {
      console.error(e);
      parseErrorMessage(e.message);
    },
    onCompleted: (data) => {
      if (onUpdateCB) onUpdateCB(data);
      logEvent(LogType.MAINTENANCE_REQUEST, {
        message: "Maintenance request updated",
        resourceId: maintenanceRequest?.id,
      });
      closeModal();
    },
  });

  const [deleteMaintenanceReq] = useMutation(DELETE_MAINTENANCE_REQUEST, {
    onError: (e) => {
      console.error(e);
      setModalErrMsg(e.message);
      setLoading(false);
    },
    onCompleted: (data) => {
      if (onDeleteCB) onDeleteCB(data);
      logEvent(LogType.MAINTENANCE_REQUEST, {
        message: "Maintenance request deleted",
        resourceId: maintenanceRequest?.id,
      });
      closeModal();
      navigate(ROUTES.USERS, { replace: true });
    },
  });

  useEffect(() => {
    if (extMaintenanceRequest) {
      let date = new Date();

      if (extMaintenanceRequest.date) {
        date = new Date(extMaintenanceRequest.date);
      }

      setMaintenance({
        ...extMaintenanceRequest,
        date,
      });
    }
  }, [extMaintenanceRequest, setMaintenance, openModal]);

  const closeModal = () => {
    setOpenModal(false);
    setOpenConfirmModal(false);
    setModalErrMsg("");
    setLoading(false);
    setMaintenance(emptyMaintenanceRequest);

    if (onCloseCB) onCloseCB();
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (!maintenanceRequest) return;

    if (name === null) {
      setMaintenance({
        ...maintenanceRequest,
        [event.target.name]: event.target.value,
      });
    } else {
      setMaintenance({
        ...maintenanceRequest,
        [name]: event.toString(),
      });
    }
  };

  const validateRequest = () => {
    let isValid = true;

    if (!maintenanceRequest?.name) {
      isValid = false;
      setNameErr("Name is required");
    }

    if (!maintenanceRequest?.comments) {
      isValid = false;
      setCommentsErr("Comments is required");
    }

    if (!maintenanceRequest?.equipment) {
      isValid = false;
      setEqError("Equipment is required");
    }

    return isValid;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const getModalTitle = () => {
    const title = isEditing ? "Edit" : "New";

    return `${title} Maintenance Request`;
  };

  const showViewEqBtn = !openFromEqPage && !!maintenanceRequest?.equipment?.id;

  const renderDialogContent = () => {
    if (loading || !maintenanceRequest) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "cetner",
              justifyContent: "center",
              height: "40px",
            }}
          >
            <CircularProgress />
          </div>
        </Box>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Grid item xs={10}> */}
            <Autocomplete
              fullWidth
              id="equipment_id"
              options={eqOptions}
              getOptionLabel={(option) => option.eid}
              value={maintenanceRequest?.equipment || null}
              disabled={isEditing || openFromEqPage}
              onChange={(event, newValue) => {
                if (newValue) {
                  setMaintenance({
                    ...maintenanceRequest,
                    equipment: newValue,
                  });
                  setEqError("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Equipment ID"
                  variant="outlined"
                  margin="dense"
                  error={!!eqError}
                  helperText={eqError}
                  onChange={(e) => {
                    setEqSearchTerm(e.target.value);
                  }}
                />
              )}
            />
            {showViewEqBtn && (
              <IconButton
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                title="View Equipment"
                onClick={() => {
                  window.open(
                    `${ROUTES.EQUIPMENTS}/${maintenanceRequest?.equipment?.id}`
                  );
                }}
              >
                <Visibility />
              </IconButton>
            )}
          </Box>

          <TextField
            margin="dense"
            id="name"
            name="name"
            label="Name"
            value={maintenanceRequest.name || ""}
            onChange={(e) => {
              handleOnChangeFormField(e);
              setNameErr("");
            }}
            variant="outlined"
            required
            error={!!nameErr}
            helperText={nameErr}
          />
          <TextField
            margin="dense"
            id="comments"
            name="comments"
            label="Comments"
            multiline
            rows={4}
            value={maintenanceRequest.comments || ""}
            onChange={(e) => {
              handleOnChangeFormField(e);
              setCommentsErr("");
            }}
            variant="outlined"
            required
            error={!!commentsErr}
            helperText={commentsErr}
          />
          <BasicDatePicker
            label="Date"
            value={dayjs(maintenanceRequest.date)}
            handleOnChange={(event: any) => {
              setMaintenance({
                ...maintenanceRequest,
                date: event,
              });
            }}
          />
          {idAdmin && (
            <BasicDatePicker
              label="Resolved On"
              value={
                maintenanceRequest.resolved_on
                  ? dayjs(maintenanceRequest.resolved_on)
                  : null
              }
              handleOnChange={(event: any) => {
                setMaintenance({
                  ...maintenanceRequest,
                  resolved_on: event,
                });
              }}
            />
          )}
        </Box>
        {!!modalErrMsg && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 1,
              m: 1,
              width: "100%",
            }}
          >
            <span style={{ color: "red" }}>{modalErrMsg}</span>
          </Box>
        )}
      </form>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        <DialogContent>{renderDialogContent()}</DialogContent>
        <DialogActions
          style={{
            padding: "0 20px 20px",
            width: "480px",
            maxWidth: "100%",
          }}
        >
          <Box
            // margin="dense"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              {isEditing && (
                <Button
                  onClick={() => {
                    setOpenConfirmModal(true);
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Box>
              <Button
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (validateRequest()) {
                    setLoading(true);

                    if (isEditing) {
                      updateMaintenanceReq({
                        variables: {
                          id: maintenanceRequest.id,
                          name: maintenanceRequest.name,
                          comments: maintenanceRequest.comments,
                          date: maintenanceRequest.date,
                          resolved_on: maintenanceRequest.resolved_on,
                          resolved: !!maintenanceRequest.resolved_on,
                        },
                      });
                    } else {
                      createMaintenanceReq({
                        variables: {
                          name: maintenanceRequest.name,
                          comments: maintenanceRequest.comments,
                          date: maintenanceRequest.date,
                          equipment: maintenanceRequest.equipment?.id,
                          resolved_on: maintenanceRequest.resolved_on,
                        },
                      });
                    }
                  }
                }}
              >
                {isEditing ? "Update" : "Create"}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditing) {
            deleteMaintenanceReq({
              variables: {
                id: maintenanceRequest.id,
              },
            });
          }
        }}
        text={`Do you really want to delete ${maintenanceRequest?.name} maintenance request?`}
      />
    </div>
  );
};

export default MaintenanceModal;
