import React from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import moment from "moment";

import { CustomAlertProps, FieldProps } from "./types";

const AlertRow = ({
  alert,
  handleRowClick,
  field,
}: {
  alert: CustomAlertProps;
  handleRowClick: (alert: CustomAlertProps) => void;
  field?: FieldProps;
}) => {
  let { value } = alert;

  if (field?.type === "date") {
    value = moment(value).format("DD-MMM-YYYY");
  }

  return (
    <TableRow>
      <TableCell>{alert.id}</TableCell>
      <TableCell>{field?.name}</TableCell>
      <TableCell
        style={{
          textTransform: "capitalize",
        }}
      >
        {alert.type}
      </TableCell>
      <TableCell>{alert.condition}</TableCell>
      <TableCell>{value}</TableCell>
      <TableCell>
        {
          <ErrorOutlineIcon
            style={{
              color: alert.color || "#fff",
            }}
            fontSize="large"
          />
        }
      </TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 2 }}
          onClick={() => {
            handleRowClick(alert);
          }}
        >
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AlertRow;
