import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";

import { useAppSelector } from "../../../redux/hooks";
import { selectCustomFields } from "../../../redux/reducers/custom-field";
import CustomAlertModal from "./CustomAlertModal";
import { getFieldsMap, useCustomAlerts } from "./helpers";
import { CustomAlertProps, FieldsMap } from "./types";
import AlertRow from "./AlertRow";

const CustomAlertsSection = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalAlert, setModalAlert] = useState<CustomAlertProps | null>(null);
  const [fieldsMap, setFieldsMap] = useState<FieldsMap>({});
  const [loading, setLoading] = useState<boolean>(true);

  const customEquipmentFields = useAppSelector(selectCustomFields);
  const { customAlerts, refetch } = useCustomAlerts({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const map: FieldsMap = getFieldsMap(customEquipmentFields);

    setFieldsMap(map);
    setLoading(false);
  }, [customEquipmentFields]);

  const handleRowClick = (alert: CustomAlertProps) => {
    setModalAlert(alert);
    setOpenModal(true);
  };

  const renderTable = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: 150,
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      );
    }

    return (
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="Equipments table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Field</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Color</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customAlerts.map((alert) => {
              return (
                <AlertRow
                  key={alert.id}
                  alert={alert}
                  handleRowClick={handleRowClick}
                  field={fieldsMap[alert.field_key]}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h6">
              Custom Alerts
            </Typography>
            <IconButton
              color="primary"
              component="label"
              onClick={() => {
                setModalAlert(null);
                setOpenModal(true);
              }}
            >
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
          <Divider />
        </Grid>
      </Grid>
      {renderTable()}
      <CustomAlertModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        extAlert={modalAlert}
        onSubmitCB={() => {
          refetch();
        }}
        fieldsMap={fieldsMap}
      />
    </Paper>
  );
};

export default CustomAlertsSection;
