import { gql } from "@apollo/client";

export const CREATE_LOG = gql`
  mutation createLog(
    $logType: ENUM_LOG_LOG_TYPE!
    $loggedAt: DateTime!
    $message: String
    $resourceId: Int
  ) {
    createLog(
      data: {
        log_type: $logType
        logged_at: $loggedAt
        message: $message
        resource_id: $resourceId
      }
    ) {
      data {
        id
      }
    }
  }
`;
