import React, { useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import ConfirmDialog from "../components/ConfirmDialog";
import { User } from "./types";
import { CREATE_USER, UPDATE_USER, DELETE_USER } from "./graphql";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppSelector } from "../../redux/hooks";
import { selectUserState } from "../../redux/reducers/user";
import { ROUTES } from "../../Router";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";
import { ProcessAreaRole } from "../Settings/ProcessAreasAndRolesSection/types";

const emptyUser = {
  id: 0,
  email: "",
  password: "",
  role: {
    id: 1,
    name: "Regular",
    type: "regular",
  },
} as User;

const UserModal: React.FC<{
  extUser?: any | null;
  setOpenModal: any;
  openModal: boolean;
  availableRoles?: ProcessAreaRole[];
  onCreateCB?: (data: any) => void;
  onUpdateCB?: (data: any) => void;
  onDeleteCB?: (data: any) => void;
  onCloseCB?: () => void;
}> = ({
  extUser,
  setOpenModal,
  openModal,
  availableRoles,
  onCreateCB,
  onUpdateCB,
  onDeleteCB,
  onCloseCB,
}) => {
  const { logEvent } = useLogger();
  const isEditing = Boolean(extUser?.id);
  const navigate = useNavigate();

  const { user: authUser } = useAppSelector(selectUserState);
  const selectedOrg = useMemo(
    () =>
      authUser?.organizations?.find(
        (org) => org.id === authUser?.selected_organization_id
      ),
    [authUser]
  );

  const [user, setUser] = useState<User>(emptyUser);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalErrMsg, setModalErrMsg] = useState<string>("");
  const [confirmPw, setConfirmPw] = useState<string>("");
  const [confirmPwBlured, setConfirmPwBlured] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [emailValidationErr, setEmailValidationErr] = useState<string>("");
  const [enableChangePw, setEnableChangePw] = useState<boolean>(false);

  const parseErrorMessage = (msg: string) => {
    if (
      msg === "This attribute must be unique" ||
      msg === "Email already taken"
    ) {
      setEmailValidationErr("Email address already taken");
    } else if (msg === "password must be at least 6 characters") {
      setPasswordError("Password must be at least 6 characters");
    } else {
      setModalErrMsg(msg);
    }

    setLoading(false);
  };

  const [createUserReq] = useMutation(CREATE_USER, {
    onError: (e) => {
      console.error(e);

      parseErrorMessage(e.message);
    },
    onCompleted: (data) => {
      if (onCreateCB) onCreateCB(data);
      logEvent(LogType.USER, {
        message: "User created",
        resourceId: data.createUsersPermissionsUser.data.id,
      });
      closeModal();
    },
  });

  const [updateUserReq] = useMutation(UPDATE_USER, {
    onError: (e) => {
      console.error(e);
      parseErrorMessage(e.message);
    },
    onCompleted: (data) => {
      if (onUpdateCB) onUpdateCB(data);
      logEvent(LogType.USER, {
        message: "User updated",
        resourceId: extUser.id,
      });
      closeModal();
    },
  });

  const [deleteUserReq] = useMutation(DELETE_USER, {
    onError: (e) => {
      console.error(e);
      setModalErrMsg(e.message);
      setLoading(false);
    },
    onCompleted: (data) => {
      if (onDeleteCB) onDeleteCB(data);
      logEvent(LogType.USER, {
        message: "User deleted",
        resourceId: extUser.id,
      });
      closeModal();
      navigate(ROUTES.USERS, { replace: true });
    },
  });

  useEffect(() => {
    if (extUser) {
      let date = null;

      if (extUser.date) {
        date = new Date(extUser.date);
      }

      setUser({
        ...extUser,
        date,
      });
    }
  }, [extUser, setUser, openModal]);

  const closeModal = () => {
    setOpenModal(false);
    setOpenConfirmModal(false);
    setModalErrMsg("");
    setConfirmPw("");
    setConfirmPwBlured(false);
    setEmailValidationErr("");
    setPasswordError("");
    setEnableChangePw(false);

    if (onCloseCB) onCloseCB();

    setTimeout(() => {
      setLoading(false);
      setUser(emptyUser);
    }, 100);
  };

  const handleDelete = () => {
    deleteUserReq({
      variables: {
        id: user.id,
      },
    });
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
      });
    } else {
      setUser({
        ...user,
        [name]: event.toString(),
      });
    }
  };

  const validateConfirmPassword = () => {
    if (user.password !== confirmPw) {
      return false;
    }

    return true;
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateUser = () => {
    let isValid = true;

    if (!validateEmail(user.email)) {
      isValid = false;
      setEmailValidationErr("Please, provide valid email address");
    }

    if (!user.password && (!isEditing || enableChangePw)) {
      setPasswordError("Password is required");
      isValid = false;
    }

    if (user.password) {
      if (!validateConfirmPassword()) {
        setConfirmPwBlured(true);
        isValid = false;
      }
    }

    return isValid;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateUser()) {
      return null;
    }

    setLoading(true);

    if (isEditing) {
      updateUserReq({
        variables: {
          ...user,
          role: user.role.id,
          process_area_roles: user.process_area_roles?.map((a) => a.id) || [],
        },
      });
    } else {
      const newUser: any = {
        ...user,
        role: user.role.id,
        process_area_roles: user.process_area_roles?.map((a) => a.id) || [],
      };

      delete newUser.id;

      createUserReq({
        variables: newUser,
      });
    }
  };

  const getModalTitle = () => {
    return isEditing ? "Edit User" : "New User";
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const renderPasswordFields = () => {
    if (isEditing && !enableChangePw) {
      return null;
    }

    return (
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl margin="dense" variant="outlined">
          <TextField
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={user.password}
            onChange={(e) => {
              setUser({
                ...user,
                password: e.target.value,
              });

              if (confirmPwBlured) {
                validateConfirmPassword();
              }

              setPasswordError("");
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Password"
            error={Boolean(passwordError)}
            helperText={passwordError || ""}
          />
        </FormControl>
        <FormControl margin="dense" variant="outlined">
          <TextField
            id="outlined-adornment-password2"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={confirmPw}
            onChange={(e) => {
              setConfirmPw(e.target.value);

              if (confirmPwBlured) {
                validateConfirmPassword();
              }
            }}
            onBlur={() => {
              setConfirmPwBlured(true);
              validateConfirmPassword();
            }}
            label="Confirm password"
            error={confirmPwBlured && !validateConfirmPassword()}
            helperText={
              (confirmPwBlured &&
                !validateConfirmPassword() &&
                "Passwords don't match") ||
              ""
            }
          />
        </FormControl>
      </Box>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: 600,
              maxWidth: "100%",
            }}
          >
            <DialogContent>
              <TextField
                margin="dense"
                id="email"
                name="email"
                value={user.email}
                label="Email"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(event) => {
                  handleOnChangeFormField(event);

                  if (emailValidationErr) {
                    if (validateEmail(event.target.value)) {
                      setEmailValidationErr("");
                    }
                  }
                }}
                error={Boolean(emailValidationErr)}
                helperText={emailValidationErr || ""}
              />
              {isEditing && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={enableChangePw}
                      onChange={() => setEnableChangePw(!enableChangePw)}
                    />
                  }
                  label="Change password"
                />
              )}
              {renderPasswordFields()}
              <FormControl margin="dense" fullWidth>
                <InputLabel id="user-role-label">Is Administrator</InputLabel>
                <Select
                  labelId="user-role"
                  id="user-role"
                  value={user.role.id}
                  label="Is Administrator"
                  disabled={authUser?.id === user.id}
                  onChange={(newValue) => {
                    setUser({
                      ...user,
                      role: {
                        ...user.role,
                        id: newValue.target.value,
                      },
                    });
                  }}
                >
                  <MenuItem value={1}>False</MenuItem>
                  <MenuItem value={3}>True</MenuItem>
                </Select>
              </FormControl>

              {selectedOrg?.enable_process_areas && (
                <FormControl margin="dense" fullWidth>
                  <Autocomplete
                    disabled={loading}
                    multiple
                    value={user.process_area_roles || []}
                    onChange={(_, v) => {
                      if (v) {
                        setUser((prev) => ({
                          ...prev,
                          process_area_roles: v,
                        }));
                      }
                    }}
                    options={availableRoles || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Process Area Roles" />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterSelectedOptions
                  />
                </FormControl>
              )}

              {Boolean(modalErrMsg.length) && (
                <Typography variant="body1" component="p" color={"red"}>
                  {modalErrMsg}
                </Typography>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditing && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    Delete
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit">{isEditing ? "Update" : "Create"}</Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditing) {
            if (authUser?.id === user.id) {
              setModalErrMsg("You cannot delete yourself");
              setOpenConfirmModal(false);
              return null;
            }

            handleDelete();
          }
        }}
        text="Do you really want to delete the user?"
      />
    </div>
  );
};

export default UserModal;
