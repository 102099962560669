import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link";

type CombinedLinkProps = MuiLinkProps & RouterLinkProps;

const CombinedLink = React.forwardRef<HTMLAnchorElement, CombinedLinkProps>(
  (props, ref) => <MuiLink component={RouterLink} ref={ref} {...props} />
);

export default CombinedLink;
