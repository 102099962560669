import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import {
  CREATE_PROCESS_AREA_ROLE,
  UPDATE_PROCESS_AREA_ROLE,
  DELETE_PROCESS_AREA_ROLE,
} from "./graphql";
import { useMutation } from "@apollo/client";
import ConfirmDialog from "../../components/ConfirmDialog";
import { ProcessAreaRole, Access, ProcessArea } from "./types";
import { LogType } from "../../../types/logger";
import { useLogger } from "../../../hooks/useLogger";

const emptyAreaRole: ProcessAreaRole = {
  id: 0,
  name: "",
  access: Access.read_only,
  process_areas: [],
};

const RoleModal = ({
  open,
  onClose,
  extRole,
  onSubmitCB,
  availableAreas,
}: {
  open: boolean;
  onClose: () => void;
  extRole: ProcessAreaRole | null;
  onSubmitCB?: () => void;
  availableAreas: ProcessArea[];
}) => {
  const { logEvent } = useLogger();

  const isEditing = Boolean(extRole);

  const [areaRole, setAreaRole] = useState<ProcessAreaRole>(emptyAreaRole);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const closeModal = async () => {
    onClose();
    setOpenConfirmModal(false);
    setAreaRole(emptyAreaRole);

    setTimeout(() => {
      setLoading(false);
    }, 350);
  };

  const beforeOnSubmitCB = () => {
    if (onSubmitCB) onSubmitCB();

    closeModal();
  };

  const [updateAreaRole] = useMutation(UPDATE_PROCESS_AREA_ROLE, {
    onCompleted: () => {
      logEvent(LogType.PROCESS_AREA_ROLE, {
        message: "Process area role updated",
        resourceId: extRole?.id,
      });
    },
  });
  const [createAreaRole] = useMutation(CREATE_PROCESS_AREA_ROLE, {
    onCompleted: ({ createProcessAreaRole: { data } }) => {
      logEvent(LogType.PROCESS_AREA_ROLE, {
        message: "Process area role created",
        resourceId: data?.id,
      });
    },
  });

  const [deleteField] = useMutation(DELETE_PROCESS_AREA_ROLE, {
    onCompleted: () => {
      logEvent(LogType.PROCESS_AREA_ROLE, {
        message: "Process area role deleted",
        resourceId: extRole?.id,
      });

      beforeOnSubmitCB();
    },
  });

  const saveAreaRole = async () => {
    setLoading(true);

    if (isEditing) {
      await updateAreaRole({
        variables: {
          id: areaRole.id,
          name: areaRole.name,
          access: areaRole.access,
          process_areas: areaRole.process_areas?.map((area) => area.id) || [],
        },
      });
    } else {
      await createAreaRole({
        variables: {
          name: areaRole.name,
          access: areaRole.access,
          process_areas: areaRole.process_areas?.map((area) => area.id) || [],
        },
      });
    }

    beforeOnSubmitCB();
  };

  React.useEffect(() => {
    if (extRole) {
      setAreaRole(extRole);
    } else {
      setAreaRole(emptyAreaRole);
    }
  }, [extRole, open]);

  const renderDialogBody = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
            width: 400,
            maxWidth: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <TextField
              label="Name"
              autoFocus
              value={areaRole.name}
              onChange={(event) => {
                setAreaRole({
                  ...areaRole,
                  name: event.target.value,
                });
              }}
              margin="dense"
              fullWidth
            />
            {/* select access */}
            <FormControl margin="dense" fullWidth>
              <InputLabel>Access</InputLabel>
              <Select
                label="Access"
                value={areaRole.access}
                onChange={(e) => {
                  setAreaRole({
                    ...areaRole,
                    access: e.target.value as Access,
                  });
                }}
              >
                {Object.keys(Access).map((key) => {
                  const value = Access[key as keyof typeof Access];

                  return (
                    <MenuItem value={key} key={key}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <Autocomplete
                disabled={loading}
                multiple
                value={areaRole.process_areas}
                onChange={(_, v) => {
                  if (v) {
                    setAreaRole((prev) => ({ ...prev, process_areas: v }));
                  }
                }}
                options={availableAreas}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Process Areas" />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
              />
            </FormControl>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={closeModal}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{isEditing ? "Edit" : "Create"} Process Area Role</span>
        </DialogTitle>
        <DialogContent
          style={{
            width: "400px",
            maxWidth: "100%",
          }}
        >
          {renderDialogBody()}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 24px 20px",
          }}
        >
          <div>
            {isEditing && (
              <Button
                disabled={loading}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenConfirmModal(true);
                }}
                color="error"
              >
                Delete
              </Button>
            )}
          </div>
          <div>
            <Button
              disabled={loading}
              onClick={(event) => {
                event.stopPropagation();
                closeModal();
              }}
            >
              Cancel
            </Button>
            <span>
              <Button
                disabled={loading}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  saveAreaRole();
                }}
              >
                {isEditing ? "Update" : "Create"}
              </Button>
            </span>
          </div>
        </DialogActions>
        <ConfirmDialog
          openModal={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          confirmHandler={() => {
            if (isEditing) {
              setLoading(true);
              deleteField({
                variables: {
                  id: areaRole.id,
                },
              });

              setOpenConfirmModal(false);
            }
          }}
          text="Do you really want to delete this process area role?"
        />
      </Dialog>
    </div>
  );
};

export default RoleModal;
