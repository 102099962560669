import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_NAME, DELETE_NAME, GET_NAMES, UPDATE_NAME } from "./graphql";
import React, { FC } from "react";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { usePrevious } from "../../hooks/usePrevious";
import ConfirmDialog from "../components/ConfirmDialog";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";

const NameListSection = () => {
  const { logEvent } = useLogger();
  const [openNameModal, setOpenNameModal] = React.useState(false);
  const [namesMap, setValuesMap] = React.useState<Record<number, string>>({});

  const { loading, refetch } = useQuery(GET_NAMES, {
    onCompleted: ({ names: { data } }) => {
      const map = data.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr.attributes.name;
        return acc;
      }, {});

      setValuesMap(map);
    },
  });

  const [createName] = useMutation(CREATE_NAME, {
    onCompleted: ({ createName: { data } }) => {
      logEvent(LogType.NAME, { message: "Name created", resourceId: data.id });
      refetch();
    },
  });

  const handleSubmit = (name: string) => {
    if (name) {
      createName({ variables: { name } });
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              padding="20px"
              justifyContent="space-between"
              alignItems="center"
              gap="8px"
            >
              <Typography variant="h6" component="h6">
                Names List
              </Typography>
              <IconButton
                color="primary"
                component="label"
                onClick={() => setOpenNameModal(true)}
              >
                <AddCircle fontSize="large" />
              </IconButton>
            </Box>
            <Divider />
          </Grid>
        </Grid>
        <List dense={true}>
          {Object.entries(namesMap).map(([id, name]) => (
            <NameItem key={id} {...{ refetch, id, name }} />
          ))}
        </List>
      </Paper>
      <NameModal
        onSubmit={handleSubmit}
        open={openNameModal}
        onClose={() => setOpenNameModal(false)}
      />
    </>
  );
};

type ItemProps = {
  refetch: () => void;
  name: string;
  id: string | number;
};

const NameItem: FC<ItemProps> = ({ refetch, name, id }) => {
  const { logEvent } = useLogger();

  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const [deleteName] = useMutation(DELETE_NAME, {
    onCompleted: () => {
      logEvent(LogType.NAME, { message: "Name deleted", resourceId: +id });
      refetch();
    },
  });

  const [updateName] = useMutation(UPDATE_NAME, {
    onCompleted: () => {
      logEvent(LogType.NAME, { message: "Name updated", resourceId: +id });
      refetch();
    },
  });

  return (
    <>
      <ListItem
        key={`${id}-${name}`}
        secondaryAction={
          <>
            <IconButton
              sx={{ marginRight: 1 }}
              edge="end"
              aria-label="edit"
              onClick={() => setOpen(true)}
            >
              <Edit />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => setOpenConfirm(true)}
            >
              <Delete />
            </IconButton>
          </>
        }
      >
        <ListItemText primary={name} />
      </ListItem>
      <NameModal
        name={name}
        onSubmit={(name) => updateName({ variables: { name, id } })}
        open={open}
        onClose={() => setOpen(false)}
      />
      <ConfirmDialog
        confirmHandler={() => deleteName({ variables: { id } })}
        text={`Do you realy want to delete "${name}"`}
        openModal={openConfirm}
        setOpenModal={(b) => setOpenConfirm(b)}
      />
    </>
  );
};

type ModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  name?: string;
  id?: string | number;
};

const NameModal: FC<ModalProps> = ({ open, onClose, onSubmit, name = "" }) => {
  const [value, setValue] = React.useState(name);

  const previuosOpen = usePrevious(open);
  React.useEffect(() => {
    if (!previuosOpen && open && name) {
      setValue(name);
    }
  }, [name, open, previuosOpen]);

  return (
    <Dialog {...{ open, onClose }}>
      <DialogTitle>{name ? "Edit name" : "Add new name"}</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ marginTop: 2 }}
          required
          label="Name"
          value={value ?? name}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSubmit(value);
            setValue("");
            onClose();
          }}
        >
          {name ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NameListSection;
