import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import HomePage from "./pages/Home";
import EquipmentsPage from "./pages/Equipments";
import UsersPage from "./pages/Users";
import EquipmentIdPage from "./pages/Equipments/id";
import SignInPage from "./pages/SignIn";
import PageNotFound from "./pages/NotFound";
import PageForbidden from "./pages/Forbidden";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { selectUserState } from "./redux/reducers/user";
import SettingsPage from "./pages/Settings";
import { useLazyQuery } from "@apollo/client";
import { GET_CUSTOM_EQUIPMENT_FIELDS } from "./pages/Settings/graphql";
import { setCustomEquipmentFields } from "./redux/reducers/custom-field";
import ForgotPasswordPage from "./pages/ForgotPassword";
import CheckEmailPage from "./pages/ForgotPassword/CheckEmail";
import ResetPasswordPage from "./pages/ForgotPassword/Reset";
import MaintenanceRequestsPage from "./pages/MaintenanceRequests";

export const ROUTES = {
  HOME: "/",
  SIGN_IN: "/sign-in",
  EQUIPMENT: "/equipment",
  EQUIPMENTS: "/equipments",
  USERS: "/users",
  SETTINGS: "/settings",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_CHECK_EMAIL: "/forgot-password/check-email",
  FORGOT_PASSWORD_RESET: "/forgot-password/reset",
  MAINTANENCE_REQUESTS: "/maintenance-requests",
} as const;

const ProtectedRoute: React.FC<{
  children: JSX.Element;
  allowedFor: Array<String>;
}> = ({ children, allowedFor }) => {
  const { user } = useAppSelector(selectUserState);
  const location = useLocation();

  if (!user) {
    return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} replace />;
  }

  if (!allowedFor.includes(user?.role?.type)) {
    return <PageForbidden />;
  }

  return children;
};

const OnlyPublicRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user } = useAppSelector(selectUserState);
  const location = useLocation();
  return user ? (
    <Navigate to={ROUTES.HOME} state={{ from: location }} replace />
  ) : (
    children
  );
};

const RedirectEquipment = () => {
  const { id } = useParams();

  return <Navigate replace to={`${ROUTES.EQUIPMENT}/${id}`} />;
};

const Router = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUserState);

  const [refetch] = useLazyQuery(GET_CUSTOM_EQUIPMENT_FIELDS, {
    onCompleted: (data: any) => {
      dispatch(setCustomEquipmentFields(data));
    },
  });

  React.useEffect(() => {
    if (user?.selected_organization_id) {
      refetch();
    }
  }, [user?.selected_organization_id, refetch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route
          path={ROUTES.SIGN_IN}
          element={
            <OnlyPublicRoute>
              <SignInPage />
            </OnlyPublicRoute>
          }
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={
            <OnlyPublicRoute>
              <ForgotPasswordPage />
            </OnlyPublicRoute>
          }
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD_CHECK_EMAIL}
          element={
            <OnlyPublicRoute>
              <CheckEmailPage />
            </OnlyPublicRoute>
          }
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD_RESET}
          element={
            <OnlyPublicRoute>
              <ResetPasswordPage />
            </OnlyPublicRoute>
          }
        />

        <Route
          path={ROUTES.EQUIPMENTS}
          element={<Navigate replace to={ROUTES.EQUIPMENT} />}
        />
        <Route
          path={`${ROUTES.EQUIPMENTS}/:id`}
          element={<RedirectEquipment />}
        />
        <Route path={ROUTES.EQUIPMENT}>
          <Route
            index
            element={
              <ProtectedRoute allowedFor={["administrator", "authenticated"]}>
                <EquipmentsPage />
              </ProtectedRoute>
            }
          />
          <Route path=":id" element={<EquipmentIdPage />} />
        </Route>
        <Route
          path={ROUTES.MAINTANENCE_REQUESTS}
          element={
            <ProtectedRoute allowedFor={["administrator"]}>
              <MaintenanceRequestsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.USERS}
          element={
            <ProtectedRoute allowedFor={["administrator"]}>
              <UsersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS}
          element={
            <ProtectedRoute allowedFor={["administrator"]}>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
