import { Box, Button, IconButton } from "@mui/material";
import { SortItem } from "../../../../hooks/table/useSortBy";
import { FC } from "react";
import { Close } from "@mui/icons-material";

type Props = {
  sortItem: SortItem;
  onDelete: (sortItem: SortItem) => void;
  onDirectionChange: (sortItem: SortItem) => void;
};

const SortItemChip: FC<Props> = ({ sortItem, onDelete, onDirectionChange }) => {
  return (
    <Box
      sx={{
        bgcolor: "#ebebeb",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 2,
        paddingX: 1,
        paddingY: 0.5,
        gap: 2,
      }}
    >
      <label style={{ fontSize: "14px" }}>{sortItem.fieldName}</label>

      <Box display="flex" gap={1}>
        <Button
          onClick={() => onDirectionChange(sortItem)}
          sx={{ padding: 0, fontSize: "12px" }}
          size="small"
        >
          {sortItem.direction}
        </Button>
        <IconButton onClick={() => onDelete(sortItem)} sx={{ padding: 0 }}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SortItemChip;
