import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";

import Header from "../components/Header";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUserState, setUser } from "../../redux/reducers/user";
import { Organization } from "../../redux/reducers/user/types";
import CustomEqFieldsSection from "./CustomEqFieldsSection";
import ExportEqSection from "./ExportEqSection";
import { setLayout } from "../../redux/reducers/leyout";
import CustomAlertsSection from "./CustomAlertsSection";
import EqFieldsSection from "./EqFieldsSection";
import { ExportLogsSection } from "./ExportLogsSection";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";
import ProcessAreasAndRolesSection from "./ProcessAreasAndRolesSection";
import NameListSection from "./NameListSection";

const Settings = () => {
  const { isEnabled: isLoggerEnabled, logEvent } = useLogger();
  const dispatch = useAppDispatch();
  const { user, jwt } = useAppSelector(selectUserState);
  const selectedOrg: Organization | undefined = user?.organizations?.find(
    (org) => org.id === user?.selected_organization_id
  );

  if (!selectedOrg) {
    return (
      <Typography variant="h4" component="h6">
        Please select an organization
      </Typography>
    );
  }

  const { enable_custom_alerts, enable_process_areas } = selectedOrg;

  if (!user) {
    return null;
  }

  const handleRemoveBranding = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const resBuf = await fetch(`${process.env.REACT_APP_SERVER_API}/users/me`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        remove_branding: event.target.checked,
      }),
    });
    const res = await resBuf.json();

    logEvent(LogType.ACTION, {
      message: !event.target.checked ? "Branding enabled" : "Branding disabled",
    });

    dispatch(
      setLayout({
        removeBranding: !event.target.checked,
      })
    );
    dispatch(
      setUser({
        ...res,
      })
    );
  };

  return (
    <React.Fragment>
      <Header enableBackBtn={false} />
      <Container>
        <Box sx={{ padding: "20px", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4" component="h6">
              Settings
            </Typography>
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ padding: "20px" }}>
                  <Typography variant="h6" component="h6">
                    QR Code
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={handleRemoveBranding}
                          disabled={
                            !selectedOrg?.enable_branding_removal || false
                          }
                          checked={selectedOrg?.remove_branding || false}
                        />
                      }
                      label="Remove QuneUp branding"
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          {enable_process_areas && <ProcessAreasAndRolesSection />}
          {selectedOrg.enable_field_visibility_toggle && (
            <EqFieldsSection selectedOrgId={selectedOrg.id} />
          )}
          <CustomEqFieldsSection selectedOrg={selectedOrg} />
          {enable_custom_alerts && <CustomAlertsSection />}
          {selectedOrg.enable_equipment_login && <NameListSection />}
          <ExportEqSection />
          {isLoggerEnabled && <ExportLogsSection selectedOrg={selectedOrg} />}
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Settings;
