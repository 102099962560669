import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface LayoutState {
  removeBranding: boolean;
}

export const initialLayoutState: LayoutState = {
  removeBranding: true,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState: initialLayoutState,
  reducers: {
    setLayout: (state, action: PayloadAction<LayoutState>) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
});

export const { setLayout } = layoutSlice.actions;
export const selectLayoutState = (state: RootState) => state;

export default layoutSlice.reducer;
