import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type LoggerState = {
  isEnabled: boolean;
};

const initialState: LoggerState = {
  isEnabled: true,
};

export const loggerSlice = createSlice({
  name: "logger",
  initialState,
  reducers: {
    setLoggerEnabled: (state, action: PayloadAction<boolean>) => {
      state.isEnabled = action.payload;
    },
  },
});

export const { setLoggerEnabled } = loggerSlice.actions;

export default loggerSlice.reducer;
