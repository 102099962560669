import TextField from "@mui/material/TextField";
import { MouseEventHandler, useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import useSearchQuery from "../../../hooks/useSearchQuery";
import useDebouncedValue from "../../../hooks/useDebouncedValue";

const EquipmentSearch = () => {
  const {
    queryString,
    isQueryInParams,
    removeQueryParam,
    populateParamsWithQuery,
  } = useSearchQuery({ withPageReset: true });

  const [query, setQuery] = useState(queryString);
  const debouncedQuery = useDebouncedValue(query);

  useEffect(() => {
    if (debouncedQuery && debouncedQuery !== queryString) {
      populateParamsWithQuery(debouncedQuery);
    }
    if (!debouncedQuery && isQueryInParams) {
      removeQueryParam();
    }
    // ? we want this effect only on `debouncedQuery` change
    // ? otherwise it breaks navigation in history
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useEffect(() => {
    setQuery(queryString ?? "");
  }, [queryString]);

  const handleSearchReset: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    setQuery("");
    removeQueryParam();
  };

  return (
    <Box position="relative" width={400}>
      <TextField
        inputProps={{
          style: isQueryInParams ? { paddingRight: "40px" } : {},
        }}
        size="small"
        fullWidth
        label="Search for Equipment"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {isQueryInParams && (
        <IconButton
          style={{
            position: "absolute",
            top: "50%",
            right: "5px",
            transform: "translate(0, -50%)",
          }}
          onClick={handleSearchReset}
          color="default"
          size="small"
          type="reset"
          aria-label="reset search"
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default EquipmentSearch;
