import { FC } from "react";
import { FieldProps } from "../../../Settings/CustomAlertsSection/types";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import BasicDatePicker from "../../../components/BasicDatePicker";
import dayjs from "dayjs";

type Props = {
  fieldType: FieldProps["type"];
  value: string;
  onChange: (v: string) => void;
};

const ValueInput: FC<Props> = ({ fieldType, value, onChange }) => {
  switch (fieldType) {
    case "text":
      return (
        <Box>
          <FormControl margin="dense" fullWidth>
            <TextField
              label="Value"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </FormControl>
        </Box>
      );
    case "date":
      return (
        <Box>
          <BasicDatePicker
            label="Value"
            value={dayjs(value)}
            handleOnChange={(v) => {
              if (v) {
                const formatted = v.toISOString();
                onChange(formatted);
              }
            }}
          />
        </Box>
      );
    case "number":
      return (
        <Box>
          <FormControl margin="dense" fullWidth>
            <TextField
              label="Value"
              type="number"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </FormControl>
        </Box>
      );
    case "boolean":
      return (
        <Box>
          <FormControl margin="dense" fullWidth>
            <InputLabel id="value-label">Value</InputLabel>
            <Select
              label="Value"
              onChange={(e) => onChange(e.target.value as string)}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Box>
      );
    default:
      return null;
  }
};

export default ValueInput;
