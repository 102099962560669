import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "./theme";
import Router from "./Router";
import "./App.css";
import React from "react";
import { useDispatch } from "react-redux";
import { selectUserState, setUser } from "./redux/reducers/user";
import { useAppSelector } from "./redux/hooks";
import { setLayout } from "./redux/reducers/leyout";
import { useLogger } from "./hooks/useLogger";
import { Organization } from "./redux/reducers/user/types";

const App = () => {
  const { setLoggerIsEnabled } = useLogger();
  const dispatch = useDispatch();
  const { jwt } = useAppSelector(selectUserState);

  React.useEffect(() => {
    const refreshMe = async () => {
      if (!jwt) {
        return;
      }

      const resBuf = await fetch(
        `${process.env.REACT_APP_SERVER_API}/users/me`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const res = await resBuf.json();
      const selectedOrg: Organization = res.organizations.find(
        (o: any) => o.id === res?.selected_organization_id
      );

      setLoggerIsEnabled(selectedOrg.enable_logger);
      dispatch(setUser(res));
      dispatch(
        setLayout({
          removeBranding: Boolean(selectedOrg?.remove_branding),
        })
      );
    };

    refreshMe();
  }, [dispatch, jwt, setLoggerIsEnabled]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
};

export default App;
