import { gql } from "@apollo/client";

export const GET_NAMES = gql`
  query getNames {
    names {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const CREATE_NAME = gql`
  mutation createName($name: String!) {
    createName(data: { name: $name }) {
      data {
        id
      }
    }
  }
`;

export const DELETE_NAME = gql`
  mutation deleteName($id: ID!) {
    deleteName(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_NAME = gql`
  mutation updateName($id: ID!, $name: String!) {
    updateName(id: $id, data: { name: $name }) {
      data {
        id
      }
    }
  }
`;

export const GET_DEFAULT_FIELDS_CONFIG = (organizationId: number) => gql`
  query GetDefaultFieldsConfig {
    organization(id: ${organizationId}) {
      data {
        attributes {
          default_fields_config
        }
      }
    }
  }
`;

export const UPDATE_DEFAULT_FIELDS_CONFIG = gql`
  mutation updateDefaultFieldsConfig($id: ID!, $newConfig: JSON!) {
    updateOrganization(id: $id, data: { default_fields_config: $newConfig }) {
      data {
        id
      }
    }
  }
`;

export const GET_CUSTOM_EQUIPMENT_FIELDS = gql`
  query GetCustomEquipmentFields {
    customEquipmentFields(sort: "id") {
      data {
        id
        attributes {
          name
          type
          admin_only
        }
      }
    }
  }
`;

export const GET_CUSTOM_EQP_FIELDS_WITH_ALERTS = gql`
  query GetCustomEquipmentFields {
    customEquipmentFields {
      data {
        id
        attributes {
          name
          type
          custom_alerts {
            data {
              id
              attributes {
                relation
                type
                condition
                value
                color
                field_key
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CUSTOM_EQUIPMENT_FIELD = gql`
  mutation createCustomEquipmentField(
    $name: String!
    $type: ENUM_CUSTOMEQUIPMENTFIELD_TYPE!
    $admin_only: Boolean!
  ) {
    createCustomEquipmentField(
      data: { name: $name, type: $type, admin_only: $admin_only }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_CUSTOM_EQUIPMENT_FIELD = gql`
  mutation updateCustomEquipmentField(
    $id: ID!
    $name: String
    $admin_only: Boolean!
  ) {
    updateCustomEquipmentField(
      id: $id
      data: { name: $name, admin_only: $admin_only }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_CUSTOM_EQUIPMENT_FIELD = gql`
  mutation deleteCustomEquipmentField($id: ID!) {
    deleteCustomEquipmentField(id: $id) {
      data {
        id
      }
    }
  }
`;

export const GET_ANALYTICS_IN_DATE_RANGE = gql`
  query GetAnalytics($startDate: DateTime, $endDate: DateTime) {
    analytics(filters: { createdAt: { gte: $startDate, lte: $endDate } }) {
      data {
        id
        attributes {
          country
          region
          timezone
          city
          latitude
          longitude
          metro
          area
          device
          browser
          ip
          createdAt
          equipment {
            data {
              id
              attributes {
                eid
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LOGS_IN_DATE_RANGE = gql`
  query GetLogs($startDate: DateTime!, $endDate: DateTime!) {
    logs(
      filters: { and: [{ logged_at: { gte: $startDate, lte: $endDate } }] }
    ) {
      data {
        id
        attributes {
          logged_at
          log_type
          logged_at
          createdAt
          updatedAt
          message
          resource_id
          ip
          user {
            data {
              id
              attributes {
                email
              }
            }
          }
        }
      }
    }
  }
`;
