import { OperationVariables, QueryHookOptions, useQuery } from "@apollo/client";

import {
  ConditionValusMap,
  ConditionNameMap,
  EqType,
  CustomAlertProps,
  FieldsMap,
} from "./types";
import { GET_CUSTOM_ALERTS, GET_DEFAULT_FIELDS_ALERTS } from "./graphql";
import {
  CustomEquipmentField,
  defaultFieldAlertsMap,
} from "../../Equipments/types";
import { GET_CUSTOM_EQP_FIELDS_WITH_ALERTS } from "../graphql";
import { Location } from "react-router-dom";
import { DefaultHeaderTitles } from "../../../types/table";

export const parseEqTypeToAlertType = (
  type: EqType,
): keyof typeof typeConditionsMap => {
  switch (type) {
    case "ShortText":
    case "LongText":
    case "Link":
      return "text";
    case "Date":
      return "date";
    case "Number":
      return "number";
    case "Checkbox":
      return "boolean";
    default:
      throw new Error("Invalid type");
  }
};

const typeConditionsMap: ConditionValusMap = {
  text: ["equals", "startsWith", "endsWith", "contains"],
  date: ["before", "beforeOrOn", "on", "onOrAfter", "after"],
  number: [
    "lessThan",
    "lessThanOrEqual",
    "equal",
    "greaterThanOrEqual",
    "greaterThan",
  ],
  boolean: ["is"],
};

export const getConditionsForType = (type: keyof typeof typeConditionsMap) => {
  return typeConditionsMap[type];
};

export const conditionNameMap: ConditionNameMap = {
  equals: "Equals",
  startsWith: "Starts With",
  endsWith: "Ends With",
  contains: "Contains",
  before: "Before",
  beforeOrOn: "Before or On",
  on: "On",
  onOrAfter: "On or After",
  after: "After",
  lessThan: "<",
  lessThanOrEqual: "<=",
  equal: "=",
  greaterThanOrEqual: ">=",
  greaterThan: ">",
  is: "Is",
};

export const getConditionName = (condition: keyof typeof conditionNameMap) => {
  return conditionNameMap[condition];
};

export const useCustomAlerts = (
  options?: QueryHookOptions<any, OperationVariables> | undefined,
) => {
  const { loading, refetch, data } = useQuery(GET_CUSTOM_ALERTS, options);

  const customAlerts: CustomAlertProps[] =
    data?.customAlerts.data.map((alert: any) => {
      let custom_equipment_field = null;

      if (alert.attributes.custom_equipment_field?.data?.id) {
        custom_equipment_field = {
          id: alert.attributes.custom_equipment_field.data.id || "",
          ...alert.attributes.custom_equipment_field.data.attributes,
        };
      }

      return {
        id: alert.id,
        test: "123",
        ...alert.attributes,
        custom_equipment_field,
      };
    }) || [];

  return { loading, refetch, customAlerts };
};

export const useEqFieldsWithAlerts = (
  options?: QueryHookOptions<any, OperationVariables> | undefined,
) => {
  const { loading, refetch, data } = useQuery(
    GET_CUSTOM_EQP_FIELDS_WITH_ALERTS,
    options,
  );

  const eqFieldsWithAlerts =
    data?.customEquipmentFields.data.map((field: any) => {
      let custom_alerts = null;

      if (field.attributes.custom_alerts?.data?.length > 0) {
        custom_alerts = [
          ...field.attributes.custom_alerts.data.map((alert: any) => ({
            id: alert.id,
            ...alert.attributes,
          })),
        ];
      }

      return {
        id: field.id,
        ...field.attributes,
        custom_alerts,
      };
    }) || [];

  return { loading, refetch, eqFieldsWithAlerts };
};

export const useDefaultFieldsAlerts = (
  options?: QueryHookOptions<any, OperationVariables> | undefined,
) => {
  const { loading, refetch, data } = useQuery(
    GET_DEFAULT_FIELDS_ALERTS,
    options,
  );

  const defaultFieldAlertsMap: defaultFieldAlertsMap = {};
  const defaultAlerts: CustomAlertProps =
    data?.customAlerts.data.map((row: any) => {
      const alert: CustomAlertProps = {
        id: row.id,
        ...row.attributes,
      };

      if (!defaultFieldAlertsMap[alert.field_key]) {
        defaultFieldAlertsMap[alert.field_key] = [];
      }

      defaultFieldAlertsMap[alert.field_key].push(alert);

      return alert;
    }) || [];

  return { loading, refetch, defaultAlerts, defaultFieldAlertsMap };
};

export const defaultFieldsMap: FieldsMap = {
  eid: {
    id: 0,
    relation: "equipment",
    name: DefaultHeaderTitles.EID,
    type: "text",
  },
  type: {
    id: 0,
    relation: "equipment",
    name: DefaultHeaderTitles.TYPE,
    type: "text",
  },
  manufacturer: {
    id: 0,
    relation: "equipment",
    name: DefaultHeaderTitles.MANUFACTURER,
    type: "text",
  },
  date_of_manufacture: {
    id: 0,
    relation: "equipment",
    name: DefaultHeaderTitles.DATE,
    type: "date",
  },
  serial_number: {
    id: 0,
    relation: "equipment",
    name: DefaultHeaderTitles.SERIAL,
    type: "text",
  },
  part_number: {
    id: 0,
    relation: "equipment",
    name: DefaultHeaderTitles.PART_NUMBER,
    type: "text",
  },
  construction_material: {
    id: 0,
    relation: "equipment",
    name: DefaultHeaderTitles.MATERIAL,
    type: "text",
  },
  maintenance_request: {
    id: 0,
    relation: "maintenance_request",
    name: "Maintenance Request",
    type: "boolean",
  },
};

export const getFieldsMap = (
  customEquipmentFields: CustomEquipmentField[],
): FieldsMap => {
  const map: FieldsMap = { ...defaultFieldsMap };

  customEquipmentFields.forEach((field) => {
    map[`${field.id}-${field.name}`] = {
      id: field.id,
      relation: "custom_equipment_field",
      name: field.name,
      type: parseEqTypeToAlertType(field.type),
      custom_equipment_field: field,
    };
  });

  return map;
};

export const getEquipmentItemUrl = (
  equipmentId: number,
  location: Location,
) => {
  const redirectParam = location.pathname + location.search;
  return `/equipment/${equipmentId}?redirect=${encodeURIComponent(
    redirectParam,
  )}`;
};
