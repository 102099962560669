import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { FieldProps } from "../../pages/Settings/CustomAlertsSection/types";
import { CommonParams } from "../../types/common";
import { PaginationSearchParams } from "./usePagination";

const FILTER_SEPARATOR = ":";

export type Filter = {
  name: string;
  cond: string;
  value: string;
  type: FieldProps["type"];
};

type FilterConfig = {
  withPageReset?: boolean;
};

const useFieldFilters = (config?: FilterConfig) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { filters, filterParam } = useMemo(() => {
    const filterParam = searchParams.get(CommonParams.Filters);
    const filters = filterParam?.split(",") || [];

    const formatted = filters.reduce<Filter[]>((acc, curr) => {
      const [name, cond, ...rest] = curr.split(FILTER_SEPARATOR);
      const type = rest.pop();
      const value = rest.join(FILTER_SEPARATOR); // avoid ruining date
      acc.push({ name, cond, value, type: type as FieldProps["type"] });
      return acc;
    }, []);

    return { filters: formatted, filterParam };
  }, [searchParams]);

  const getFilterString = (
    name: string,
    cond: string,
    value: string,
    type: FieldProps["type"],
  ) => {
    return [name, cond, value, type].join(FILTER_SEPARATOR);
  };

  const addFilterInParams = (
    name: string,
    cond: string,
    value: string,
    type: FieldProps["type"],
  ) => {
    const existingFilters = filterParam?.split(",") || [];
    const filterString = getFilterString(name, cond, value, type);

    searchParams.delete(CommonParams.Filters);

    if (existingFilters.includes(filterString)) return;

    if (config?.withPageReset) {
      searchParams.delete(PaginationSearchParams.PAGE);
    }

    searchParams.set(
      CommonParams.Filters,
      [filterString, ...existingFilters].join(","),
    );
    setSearchParams(searchParams);
  };

  const updateFilterInParams = (old: Filter, updated: Filter) => {
    const existingFilters = filterParam?.split(",") || [];

    const oldFilterString = getFilterString(
      old.name,
      old.cond,
      old.value,
      old.type,
    );

    const newFilterString = getFilterString(
      updated.name,
      updated.cond,
      updated.value,
      updated.type,
    );

    searchParams.delete(CommonParams.Filters);

    const filtered = existingFilters.filter((f) => f !== oldFilterString);

    if (config?.withPageReset) {
      searchParams.delete(PaginationSearchParams.PAGE);
    }

    searchParams.set(
      CommonParams.Filters,
      [newFilterString, ...filtered].join(","),
    );
    setSearchParams(searchParams);
  };

  const removeFilterFromParams = (filter: Filter) => {
    const existingFilters = filterParam?.split(",") || [];
    const filterString = getFilterString(
      filter.name,
      filter.cond,
      filter.value,
      filter.type,
    );

    searchParams.delete(CommonParams.Filters);

    if (config?.withPageReset) {
      searchParams.delete(PaginationSearchParams.PAGE);
    }

    const filtered = existingFilters.filter((f) => f !== filterString);
    if (filtered.length) {
      searchParams.set(CommonParams.Filters, filtered.join(","));
    }

    setSearchParams(searchParams);
  };

  return {
    filters,
    addFilterInParams,
    removeFilterFromParams,
    updateFilterInParams,
    getFilterString,
  };
};

export default useFieldFilters;
