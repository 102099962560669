import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { CustomEquipmentField } from "../../../pages/Equipments/types";

export const initialCustomEqFieldsState: CustomEquipmentField[] = [];

export const userSlice = createSlice({
  name: "customEquipmentFields",
  initialState: initialCustomEqFieldsState,
  reducers: {
    setCustomEquipmentFields: (
      state,
      action: PayloadAction<CustomEquipmentField[]>
    ) => {
      const data = action.payload as any;

      return data.customEquipmentFields.data.map((field: any) => ({
        id: parseInt(field.id),
        ...field.attributes,
      }));
    },
  },
});

export const { setCustomEquipmentFields } = userSlice.actions;
export const selectCustomFields = (state: RootState) =>
  state.customEquipmentFields;

export default userSlice.reducer;
