import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../redux/hooks";
import { setJWT, setUser } from "../../redux/reducers/user";
import { GET_CURRENT_USER, LOGIN } from "../../redux/reducers/user/graphql";
import { ROUTES } from "../../Router";
import MuiLink from "../components/MuiLink";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";

const theme = createTheme();

const SignIn = () => {
  const { logEvent } = useLogger();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [signInErr, setSignInErr] = React.useState<string>("");

  const [fetchCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    onError: (e) => {
      setSignInErr(e.message);
    },
    onCompleted: (data) => {
      dispatch(setUser(data.me));
      navigate(ROUTES.EQUIPMENT);
    },
  });

  const [loginRequest] = useMutation(LOGIN, {
    onError: (e) => {
      setSignInErr(e.message.replace("identifier", "email"));
    },
    onCompleted: (data) => {
      dispatch(setJWT(data.login.jwt));
      fetchCurrentUser();

      logEvent(LogType.USER, { message: "Sign in" });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    loginRequest({
      variables: {
        identifier: data.get("email"),
        password: data.get("password"),
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {Boolean(signInErr) && (
              <Typography style={{ color: "red" }}>{signInErr}</Typography>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
              Sign In
            </Button>
            <MuiLink variant="body2" to={ROUTES.FORGOT_PASSWORD}>
              Forgot password?
            </MuiLink>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
