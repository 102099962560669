import * as React from "react";
import QRCode from "react-qr-code";

import QuneUpLog from "../../assets/img/QuneUp_logo.png";
import theme from "../../theme";

const useResize = (ref: any) => {
  const [width, setWidth] = React.useState(0);

  const handleResize = React.useCallback(() => {
    setWidth(ref.current.offsetWidth);
  }, [ref]);

  React.useEffect(() => {
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("load", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, handleResize]);

  return { width };
};

interface QRCProps {
  value: string;
  centerText?: string;
  removeBranding?: boolean;
}

const ResponsiveQRCode = (props: QRCProps) => {
  const qrContainerRef = React.useRef<HTMLDivElement>(null);
  const { width } = useResize(qrContainerRef);
  const [fontSize, setFontSize] = React.useState<number>(42);

  const [size, setSize] = React.useState<number>(0);

  React.useEffect(() => {
    setSize(width || qrContainerRef.current?.offsetWidth || 500);
  }, [qrContainerRef, width]);

  const idSize = size / 3 + 8;

  React.useEffect(() => {
    if (props.centerText) {
      const text = props.centerText;
      const textLength = text.length;
      const textWidth = textLength * 10;
      const textHeight = 20;
      const textRatio = textWidth / textHeight;
      const containerRatio = idSize / idSize;
      const fontSize =
        textRatio > containerRatio ? idSize / textLength : idSize / 2;

      if (fontSize < 42) {
        setFontSize(fontSize);
      } else {
        setFontSize(42);
      }
    }
  }, [props.centerText, idSize]);

  return (
    <div
      ref={qrContainerRef}
      className="qr-code-container"
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <style type="text/css" media="print">
        {`
          @page { margin: 0; padding: 0; }
          body { margin: 0; padding: 0; }
          .print-container {
            display: block;
            margin-top: 40px;
            position: relative;
          }
        `}
      </style>
      <div
        style={{
          position: "relative",
        }}
      >
        {Boolean(props.centerText) && (
          <div
            style={{
              position: "absolute",
              width: `${idSize}px`,
              height: `${idSize}px`,
              top: `${(size - idSize) / 2}px`,
              left: `${(size - idSize) / 2}px`,
              fontWeight: "900",
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              userSelect: "none",
              border: `11px solid ${theme.color.primary}`,
              backgroundColor: "#fff",
              lineHeight: 1,
            }}
          >
            <span
              style={{
                color: theme.color.primary,
                textAlign: "center",
                fontSize: fontSize,
                lineHeight: 1,
              }}
            >
              {props.centerText}
              {!props.removeBranding && (
                <img
                  style={{
                    width: "100%",
                  }}
                  className="qune-up-print-logo"
                  src={QuneUpLog}
                  alt="QuneUp logo"
                />
              )}
            </span>
          </div>
        )}
        <QRCode
          className="qr-code-svg"
          size={size}
          value={`${props.value}?s=t`}
          viewBox={`0 0 ${size} ${size}`}
          fgColor={theme.color.primary}
          level="H"
        />
      </div>
    </div>
  );
};

export default ResponsiveQRCode;
