import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { useLazyQuery, useQuery } from "@apollo/client";
import { IconButton } from "@mui/material";
import { Edit, ArrowBack, Handyman } from "@mui/icons-material";
import { useDispatch } from "react-redux";

import Header from "../components/Header";
import { Equipment, EquipmentFieldValue } from "./types";
import ResponsiveQRCode from "../components/ResponsiveQRCode";
import { GET_EQUIPMENT_BY_ID, GET_ORGANIZATION_BY_ID } from "./graphql";
import { useAppSelector } from "../../redux/hooks";
import { selectUserState } from "../../redux/reducers/user";
import EquipmentModal from "./modal";
import { ROUTES } from "../../Router";
import { selectCustomFields } from "../../redux/reducers/custom-field";
import { setLayout } from "../../redux/reducers/leyout";
import { obfuscateString, renderCustomFieldValue } from "./helpers";
import { DefaultHeaderTitles } from "../../types/table";
import { DEFAULT_FIELDS_MAP } from "./constants";
import { Organization } from "../../redux/reducers/user/types";
import MaintenanceModal from "../MaintenanceRequests/modal";
import { MaintenanceRequest } from "../MaintenanceRequests/types";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";
import { EquipmentLoginTable } from "./components/EquipmentLoginTable";
import { GET_PROCESS_AREAS_ONLY } from "../Settings/ProcessAreasAndRolesSection/graphql";
import { ProcessArea } from "../Settings/ProcessAreasAndRolesSection/types";

const EquipmentId = () => {
  const { logEvent } = useLogger();
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { user } = useAppSelector(selectUserState);
  const isAdmin = user?.role.type === "administrator";
  const isAuthed = Boolean(user);

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  const shouldHideFields =
    !!selectedOrg?.enable_per_equipment_visibility && !isAdmin;

  const customEquipmentFields = useAppSelector(selectCustomFields);
  const dispatch = useDispatch();

  const isScan = searchParams.get("s") === "t";
  const printContainerRef = useRef<HTMLDivElement>(null);
  const printQRCode = useReactToPrint({
    content: () => printContainerRef.current,
  });

  const [equipment, setEquipment] = useState<Equipment | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [maintenanceRequest, setMaintenanceRequest] =
    useState<MaintenanceRequest | null>(null);
  const [openMaintenanceModal, setOpenMaintenanceModal] =
    useState<boolean>(false);
  const [equipmentFieldValuesMap, setEquipmentFieldValuesMap] = useState<{
    [key: string]: EquipmentFieldValue;
  }>({});
  const [processAreas, setProcessAreas] = useState<ProcessArea[]>([]);

  const { loading: loadingOrg } = useQuery(
    GET_ORGANIZATION_BY_ID(equipment?.organization?.id),
    {
      onCompleted: ({ organization: { data } }) => {
        const { id, attributes } = data;
        const eq_login_names: any = [];

        if (attributes.eq_login_names) {
          attributes.eq_login_names.data.forEach((name: any) => {
            eq_login_names.push({
              id: name.id,
              name: name.attributes.name,
            });
          });
        }

        setSelectedOrg({
          id: id,
          ...attributes,
          eq_login_names,
        });
      },
      skip: !equipment?.organization?.id,
    }
  );

  useEffect(() => {
    if (typeof equipment?.organization?.remove_branding === "boolean") {
      dispatch(
        setLayout({
          removeBranding: equipment?.organization?.remove_branding,
        })
      );
    }
  }, [equipment?.organization?.remove_branding, dispatch]);

  const [getProcessAreas] = useLazyQuery(GET_PROCESS_AREAS_ONLY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const processAreas = data.processAreas.data.map((processArea: any) => {
        return {
          id: processArea.id,
          name: processArea.attributes.name,
        };
      });

      setProcessAreas(processAreas);
    },
  });

  useEffect(() => {
    if (isAdmin) {
      getProcessAreas();
    }
  }, [isAdmin, getProcessAreas]);

  const { loading, error, refetch } = useQuery(
    GET_EQUIPMENT_BY_ID(id, isAuthed, isAdmin),
    {
      context: {
        headers: {
          "x-is-scan-header": isScan ? "true" : "false",
        },
      },
      onCompleted: (data: any) => {
        if (data.equipment.data.attributes.maintenance_requests?.data.length) {
          setMaintenanceRequest({
            id: data.equipment.data.attributes.maintenance_requests.data[0].id,
            ...data.equipment.data.attributes.maintenance_requests.data[0]
              .attributes,
          });
        }

        const eqFieldsMap: { [key: string]: EquipmentFieldValue } = {};
        const equipment = {
          id: data.equipment.data.id,
          ...data.equipment.data.attributes,
          organization: {
            id: data.equipment.data.attributes.organization.data.id,
            ...data.equipment.data.attributes.organization.data.attributes,
          },
          fields: data.equipment.data.attributes.fields.data.map(
            (field: any) => {
              const fieldRes = {
                id: field.id,
                ...field.attributes,
                custom_equipment_field: {
                  id: field.attributes.custom_equipment_field.data.id,
                  ...field.attributes.custom_equipment_field.data.attributes,
                },
              };

              eqFieldsMap[field.attributes.custom_equipment_field.data.id] = {
                ...fieldRes,
              };

              return fieldRes;
            }
          ),
          process_areas: data.equipment.data.attributes.process_areas?.data.map(
            (pa: any) => {
              return {
                id: pa.id,
                ...pa.attributes,
              };
            }
          ),
        };

        setEquipmentFieldValuesMap(eqFieldsMap);
        setEquipment(equipment);

        if (isScan) {
          searchParams.delete("s");
          setSearchParams(searchParams);
        }
      },
    }
  );

  const handlePrintQRCode = () => {
    logEvent(LogType.ACTION, {
      message: "Equipment QR code print",
      resourceId: equipment?.id,
    });
    printQRCode();
  };

  const renderEquipmentCard = () => {
    if (error) {
      return <h6>{error.message}</h6>;
    }

    if (loading || loadingOrg) {
      return (
        <Card
          sx={{
            width: "300px",
            padding: "50px",
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </Card>
      );
    }

    if (!equipment) {
      return <Typography variant="h5">404 Equipment was not found</Typography>;
    }

    return (
      <Card sx={{ width: "100%", maxWidth: 500 }}>
        <div
          className="print-container"
          ref={printContainerRef}
          style={{ lineHeight: 0 }}
        >
          <ResponsiveQRCode
            value={`${process.env.REACT_APP_BASE_URL}/equipment/${equipment.id}`}
            centerText={equipment.eid}
            removeBranding={equipment?.organization?.remove_branding}
          />
        </div>
        <CardContent>
          <div className="equipment-details">
            {Object.values(DefaultHeaderTitles).map((t) => {
              const defaultName =
                DEFAULT_FIELDS_MAP[t as keyof typeof DEFAULT_FIELDS_MAP];

              const isFieldAdminOnly =
                equipment?.default_fields_config?.[t]?.admin_only;

              if (isFieldAdminOnly && shouldHideFields) {
                return (
                  <div key={t}>
                    <span>
                      <strong>{t}:</strong>
                    </span>
                    <span>
                      {obfuscateString(
                        equipment[defaultName as keyof Equipment]
                      )}
                    </span>
                  </div>
                );
              }

              if (t === DefaultHeaderTitles.DATE) {
                return (
                  <div key={t}>
                    <span>
                      <strong>{t}:</strong>
                    </span>
                    <span
                      style={{
                        color: isFieldAdminOnly ? "darkgray" : "inherit",
                      }}
                    >
                      {equipment.date_of_manufacture &&
                        moment(equipment.date_of_manufacture).format(
                          "DD-MMM-YYYY"
                        )}
                    </span>
                  </div>
                );
              }

              return (
                <div key={t}>
                  <span>
                    <strong>{t}:</strong>
                  </span>
                  <span
                    style={{
                      color: isFieldAdminOnly ? "darkgray" : "inherit",
                    }}
                  >
                    {equipment[defaultName as keyof Equipment]}
                  </span>
                </div>
              );
            })}

            {Boolean(user)
              ? customEquipmentFields.map((field) => {
                  const fieldValue = equipmentFieldValuesMap[field.id];

                  if (fieldValue?.admin_only && shouldHideFields) {
                    return null;
                  }

                  return (
                    <div key={field.id}>
                      <span>
                        <strong>{field.name}:</strong>
                      </span>
                      <span>{renderCustomFieldValue(fieldValue)}</span>
                    </div>
                  );
                })
              : Object.keys(equipmentFieldValuesMap).map((key) => {
                  const fieldValue = equipmentFieldValuesMap[key];
                  return (
                    <div key={key}>
                      <span>
                        <strong>
                          {fieldValue.custom_equipment_field.name}:
                        </strong>
                      </span>
                      <span>{renderCustomFieldValue(fieldValue)}</span>
                    </div>
                  );
                })}
          </div>
        </CardContent>
      </Card>
    );
  };

  const renderMaintenanceRequestButton = () => {
    if (!isAuthed) return null;

    if (loading || loadingOrg) {
      return null;
    }

    const hasMaintenanceRequest = Boolean(maintenanceRequest);
    let maintenanceMessage = "";

    if (hasMaintenanceRequest) {
      maintenanceMessage = `Maintenance requested on ${moment(
        maintenanceRequest?.date
      ).format("DD-MMM-YYYY")}`;
    }

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {hasMaintenanceRequest && (
          <Typography
            variant="subtitle1"
            style={{ color: "darkgray", marginRight: "10px" }}
          >
            {maintenanceMessage}
          </Typography>
        )}
        <IconButton
          color="primary"
          component="label"
          disabled={hasMaintenanceRequest}
          onClick={() => {
            setOpenMaintenanceModal(true);
          }}
        >
          <Handyman fontSize="large" />
        </IconButton>
      </Box>
    );
  };

  const canWriteEquipment =
    isAuthed &&
    equipment?.process_areas?.some(
      (pa) => user?.writeProcessAreasMap && user?.writeProcessAreasMap[pa.id]
    );

  return (
    <>
      <Header
        enableBackBtn={false}
        backButtonLink={ROUTES.HOME}
        printQRCode={() => {
          handlePrintQRCode();
        }}
      />
      <Container sx={{ paddingBottom: 8 }}>
        <Box sx={{ padding: "20px", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h4" component="h6">
              {Boolean(user) && (
                <span style={{ top: -2, position: "relative" }}>
                  <IconButton
                    onClick={() => {
                      navigate(
                        searchParams.get("redirect") || ROUTES.EQUIPMENT
                      );
                    }}
                  >
                    <ArrowBack style={{ color: "#000", fontSize: 30 }} />
                  </IconButton>
                </span>
              )}
              Equipment
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {renderMaintenanceRequestButton()}
              {(isAdmin || canWriteEquipment) && (
                <IconButton
                  color="primary"
                  component="label"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <Edit fontSize="large" />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {renderEquipmentCard()}
          </Box>
        </Box>
        {selectedOrg?.enable_equipment_login && equipment && (
          <EquipmentLoginTable eq={equipment} selectedOrg={selectedOrg} />
        )}
      </Container>
      <EquipmentModal
        extEquipment={equipment}
        setOpenModal={() => setOpenModal(false)}
        openModal={openModal}
        onUpdateCB={() => refetch()}
        availableAreas={processAreas}
      />
      <MaintenanceModal
        openFromEqPage={true}
        equipment={equipment}
        extMaintenanceRequest={null}
        setOpenModal={() => setOpenMaintenanceModal(false)}
        openModal={openMaintenanceModal}
        onCreateCB={() => refetch()}
      />
    </>
  );
};

export default EquipmentId;
