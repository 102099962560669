import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers {
    usersPermissionsUsers(pagination: { start: 0, limit: 100 }) {
      data {
        id
        attributes {
          username
          email
          role {
            data {
              id
              attributes {
                name
                type
              }
            }
          }
          process_area_roles {
            data {
              id
              attributes {
                name
                access
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $role: ID!
    $process_area_roles: [ID]
  ) {
    createUsersPermissionsUser(
      data: {
        username: $email
        email: $email
        password: $password
        role: $role
        confirmed: true
        process_area_roles: $process_area_roles
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $email: String!
    $password: String
    $role: ID!
    $process_area_roles: [ID]
  ) {
    updateUsersPermissionsUser(
      id: $id
      data: {
        username: $email
        email: $email
        password: $password
        role: $role
        process_area_roles: $process_area_roles
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUsersPermissionsUser($id: ID!) {
    deleteUsersPermissionsUser(id: $id) {
      data {
        id
      }
    }
  }
`;
