import {
  Autocomplete,
  Box,
  Button,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { selectCustomFields } from "../../../../redux/reducers/custom-field";
import { getFieldsMap } from "../../../Settings/CustomAlertsSection/helpers";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";
import useSortBy, { SortItem } from "../../../../hooks/table/useSortBy";
import SortItemChip from "./SortItemChip";
import { useSearchParams } from "react-router-dom";
import { getFormattedDefaultFieldName } from "../../helpers";
import { usePrevious } from "../../../../hooks/usePrevious";

const SortPopover = () => {
  const [searchParams] = useSearchParams();
  const { addSortingInParams, removeSortingFromParams, sortBy } = useSortBy({
    withPageReset: true,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "filters-popover" : undefined;

  const customEquipmentFields = useAppSelector(selectCustomFields);

  const activeFields = useMemo(() => {
    const activeFieldParams = searchParams.get("fields")?.split(",") || [];
    return new Set(activeFieldParams.map((param) => param.split(":")[0]));
  }, [searchParams]);

  const sortOptions = useMemo(() => {
    const fields = Object.values(getFieldsMap(customEquipmentFields));

    if (!activeFields.size) {
      return fields.map<SortItem>((f) => ({
        fieldName: f.name,
        direction: "asc",
        type: f.type,
      }));
    }

    return fields
      .filter((f) => activeFields.has(f.name))
      .map<SortItem>((f) => ({
        fieldName: f.name,
        direction: "asc",
        type: f.type,
      }));
  }, [activeFields, customEquipmentFields]);

  const [selectedItems, setSelectedItems] = useState<SortItem[]>(() => {
    const splits = sortBy.map((sb) => sb.split(":"));
    return splits.map<SortItem>((s) => ({
      fieldName: getFormattedDefaultFieldName(s[0]) || s[0],
      direction: s[1] as SortItem["direction"],
      type: s[2],
    }));
  });

  useEffect(() => {
    setSelectedItems(() => {
      const splits = sortBy.map((sb) => sb.split(":"));
      return splits.map<SortItem>((s) => ({
        fieldName: getFormattedDefaultFieldName(s[0]) || s[0],
        direction: s[1] as SortItem["direction"],
        type: s[2],
      }));
    });
  }, [sortBy]);

  const handleDragEnd: OnDragEndResponder = (r) => {
    setSelectedItems((prev) => {
      if (!r.destination) return prev;

      const [reorderedItem] = prev.splice(r.source.index, 1);
      prev.splice(r.destination.index, 0, reorderedItem);

      addSortingInParams(prev);

      return prev;
    });
  };

  const handleDelete = (sortItem: SortItem) => {
    setSelectedItems((prev) => {
      const filtered = prev.filter(
        (item) => item.fieldName !== sortItem.fieldName,
      );
      addSortingInParams(filtered);

      return filtered;
    });
  };

  const handleDirectionChange = (sortItem: SortItem) => {
    setSelectedItems((prev) => {
      return prev.map<SortItem>((item) => {
        if (item.fieldName === sortItem.fieldName) {
          return {
            ...sortItem,
            direction: sortItem.direction === "asc" ? "desc" : "asc",
          };
        }

        return item;
      });
    });
  };

  const previousLength = usePrevious(selectedItems.length);
  useEffect(() => {
    if (selectedItems.length) {
      addSortingInParams(selectedItems);
    } else {
      if (previousLength) {
        removeSortingFromParams();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleReset = () => {
    setSelectedItems([]);
    removeSortingFromParams();
  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Sort
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          padding={2}
          minWidth={320}
          minHeight={500}
        >
          <Autocomplete
            blurOnSelect
            value={null}
            options={sortOptions}
            getOptionLabel={({ fieldName }) => fieldName}
            getOptionDisabled={({ fieldName }) =>
              !!selectedItems.find((i) => i.fieldName === fieldName)
            }
            onChange={(_e, item) => {
              if (item) {
                setSelectedItems((prev) => [...prev, item]);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Fields to sort by"
                variant="standard"
              />
            )}
          />
          <Box flexGrow="1">
            {selectedItems.length ? (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable-tags">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedItems.map((i, idx) => (
                        <Draggable
                          key={i.fieldName}
                          draggableId={i.fieldName}
                          index={idx}
                        >
                          {(provided) => (
                            <Box
                              paddingY={0.5}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <SortItemChip
                                sortItem={i}
                                onDelete={handleDelete}
                                onDirectionChange={handleDirectionChange}
                              />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Typography variant="caption">No fields selected</Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="space-start">
            <Button disabled={!selectedItems.length} onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default SortPopover;
