import React, { useMemo, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import moment from "moment";

import { Equipment, EquipmentTableHeaders } from "../../Equipments/types";
import { CustomAlertProps, FieldProps, FieldsMap } from "./types";
import { Checkbox, TableCell, TableRow, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getEquipmentItemUrl } from "./helpers";
import { Organization } from "../../../redux/reducers/user/types";

interface Props {
  key: keyof Equipment;
  equipment: Equipment;
  field: FieldProps;
  cellValue: string;
  type?: "text" | "date" | "number" | "boolean";
}

const checkTextAlert = ({
  field,
  cellValue,
}: Props): CustomAlertProps[] | null => {
  let alerts: CustomAlertProps[] = [];

  const custom_alerts = field.custom_alerts || [];

  custom_alerts.forEach((alert) => {
    const { condition, value } = alert;

    switch (condition) {
      case "equals":
        if (cellValue === value) {
          alerts.push(alert);
        }
        break;
      case "startsWith":
        if (cellValue.startsWith(value)) {
          alerts.push(alert);
        }
        break;
      case "endsWith":
        if (cellValue.endsWith(value)) {
          alerts.push(alert);
        }
        break;
      case "contains":
        if (cellValue.includes(value)) {
          alerts.push(alert);
        }
        break;
      default:
    }
  });

  if (alerts.length) return alerts;

  return null;
};

const checkDateAlert = ({
  field,
  cellValue,
}: Props): CustomAlertProps[] | null => {
  let alerts: CustomAlertProps[] = [];
  const custom_alerts = field.custom_alerts || [];

  custom_alerts.forEach((alert) => {
    const { condition, value } = alert;
    const cellValueDate = moment(cellValue);
    const valueDate = moment(value);

    switch (condition) {
      case "before":
        if (cellValueDate.isBefore(valueDate)) {
          alerts.push(alert);
        }
        break;
      case "beforeOrOn":
        if (cellValueDate.isSameOrBefore(valueDate)) {
          alerts.push(alert);
        }
        break;
      case "on":
        if (cellValueDate.isSame(valueDate)) {
          alerts.push(alert);
        }
        break;
      case "onOrAfter":
        if (cellValueDate.isSameOrAfter(valueDate)) {
          alerts.push(alert);
        }
        break;
      case "after":
        if (cellValueDate.isAfter(valueDate)) {
          alerts.push(alert);
        }
        break;
      default:
    }
  });

  if (alerts.length) return alerts;

  return null;
};

const checkNumberAlert = ({
  field,
  cellValue,
}: Props): CustomAlertProps[] | null => {
  let alerts: CustomAlertProps[] = [];
  const custom_alerts = field.custom_alerts || [];

  custom_alerts.forEach((alert) => {
    const { condition, value } = alert;

    switch (condition) {
      case "lessThan":
        if (cellValue < value) {
          alerts.push(alert);
        }
        break;
      case "lessThanOrEqual":
        if (cellValue <= value) {
          alerts.push(alert);
        }
        break;
      case "equal":
        if (cellValue === value) {
          alerts.push(alert);
        }
        break;
      case "greaterThanOrEqual":
        if (cellValue >= value) {
          alerts.push(alert);
        }
        break;
      case "greaterThan":
        if (cellValue > value) {
          alerts.push(alert);
        }
        break;
      default:
        break;
    }
  });

  if (alerts.length) return alerts;

  return null;
};

const checkBooleanAlert = ({
  field,
  cellValue,
}: Props): CustomAlertProps[] | null => {
  let alerts: CustomAlertProps[] = [];
  const custom_alerts = field.custom_alerts || [];

  custom_alerts.forEach((alert) => {
    const { condition, value } = alert;

    if (cellValue === "Yes") {
      cellValue = "true";
    } else if (cellValue === "No") {
      cellValue = "false";
    }

    if (condition === "is") {
      if (cellValue === value) {
        alerts.push(alert);
      }
    }
  });

  if (alerts.length) return alerts;

  return null;
};

const checkAlert = ({
  key,
  equipment,
  field,
  cellValue,
  type,
}: Props): CustomAlertProps[] | null => {
  switch (type) {
    case "text":
      return checkTextAlert({ key, equipment, field, cellValue });
    case "date":
      return checkDateAlert({ key, equipment, field, cellValue });
    case "number":
      return checkNumberAlert({ key, equipment, field, cellValue });
    case "boolean":
      return checkBooleanAlert({ key, equipment, field, cellValue });
    default:
      return null;
  }
};

interface activeAlertsMap {
  [key: string]: CustomAlertProps;
}

type RowProps = {
  equipment: Equipment;
  equipmentHeaders: EquipmentTableHeaders;
  fieldsMap: FieldsMap;
  isAdministrator: boolean;
  handleSelectRowClick: (e: React.MouseEvent<unknown>, id: number) => void;
  isRowSelected: boolean;
  selectedOrg?: Organization;
  maintenanceAlerts?: CustomAlertProps[];
};

const EqpAlertRow: React.FC<RowProps> = ({
  equipment,
  equipmentHeaders,
  fieldsMap,
  isAdministrator,
  handleSelectRowClick,
  isRowSelected,
  selectedOrg,
  maintenanceAlerts,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeAlertsMap, setActiveAlertsMap] = useState<activeAlertsMap>({});

  const renderAlertsCell = () => {
    return (
      <>
        {maintenanceAlerts?.map((alert) => {
          let title = "";

          if (!equipment.maintenance_requests?.length) return null;

          if (alert.value === "true") {
            const maintenanceRequest = equipment.maintenance_requests[0];

            if (!maintenanceRequest.resolved_on) {
              title = "Requires maintenance";
            } else {
              return null;
            }
          } else if (alert.value === "false") {
            const maintenanceRequest = equipment.maintenance_requests[0];

            if (maintenanceRequest.resolved_on) {
              title = "Does not require maintenance";
            } else {
              return null;
            }
          }

          return (
            <Tooltip title={title} key={alert.id}>
              <ErrorOutlineIcon
                key={alert.id}
                style={{
                  color: alert.color || "#f00",
                }}
                fontSize="medium"
              />
            </Tooltip>
          );
        })}
        {Object.keys(activeAlertsMap).map((key) => {
          const alert = activeAlertsMap[key];

          let fieldName = alert.field_key.replaceAll("_", " ");

          if (fieldName.includes("-")) {
            fieldName = fieldName.split("-")[1];
          }

          fieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

          let { condition, value } = alert;

          if (alert.type === "date") {
            value = moment(value).format("DD-MMM-YYYY");
          }

          let title = `${fieldName} ${condition} ${value}`;

          return (
            <Tooltip title={title} key={alert.id}>
              <ErrorOutlineIcon
                key={alert.id}
                style={{
                  color: alert.color || "#f00",
                }}
                fontSize="medium"
              />
            </Tooltip>
          );
        })}
      </>
    );
  };

  useMemo(() => {
    equipmentHeaders.forEach((h, idx) => {
      let key = h.title as keyof Equipment;
      let activeAlertsMap: activeAlertsMap = {};

      if (h.custom_field_id) {
        key = `${h.custom_field_id}-${h.title}` as keyof Equipment;
      }

      let eqKey = key.toLowerCase().replaceAll(" ", "_") as any;

      if (eqKey === "date") eqKey = "date_of_manufacture" as keyof Equipment;
      if (eqKey === "equipment_id") eqKey = "eid" as keyof Equipment;

      if (fieldsMap[key]) {
        let cellValue = h.format(equipment) as any;

        if (typeof cellValue !== "string") {
          cellValue = cellValue?.props?.children;
        }

        if (cellValue) {
          const newAlerts = checkAlert({
            key,
            equipment,
            field: fieldsMap[key].custom_equipment_field as any,
            cellValue,
            type: fieldsMap[key].type,
          }) as CustomAlertProps[];

          newAlerts?.forEach((alert) => {
            activeAlertsMap[alert.id] = alert;
          });
        }
      } else if (fieldsMap[eqKey]) {
        key = eqKey as keyof Equipment;

        const newAlerts = checkAlert({
          key,
          equipment,
          field: fieldsMap[key],
          cellValue: equipment[key],
          type: fieldsMap[key].type,
        }) as CustomAlertProps[];

        newAlerts?.forEach((alert) => {
          activeAlertsMap[alert.id] = alert;
        });
      }

      if (Object.keys(activeAlertsMap).length) {
        setActiveAlertsMap((prev) => ({ ...prev, ...activeAlertsMap }));
      }
    });
  }, [equipment, equipmentHeaders, fieldsMap]);

  const borderBottom =
    Object.keys(activeAlertsMap).length === 1
      ? `1px solid ${Object.values(activeAlertsMap)[0].color}`
      : "";

  const shouldHideFields =
    !isAdministrator && !!selectedOrg?.enable_field_visibility_toggle;

  return (
    <TableRow
      hover
      sx={{
        cursor: "pointer",
        userSelect: "none",
        "& td": {
          borderBottom,
        },
      }}
      key={equipment.id}
      onClick={(e) => {
        e.stopPropagation();
        navigate(getEquipmentItemUrl(equipment.id, location));
      }}
    >
      {isAdministrator && (
        <TableCell padding="checkbox">
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Checkbox
              color="primary"
              checked={isRowSelected}
              onClick={(e) => handleSelectRowClick(e, equipment.id)}
            />
            {renderAlertsCell()}
          </span>
        </TableCell>
      )}
      {equipmentHeaders.map((h, idx) => {
        if (!h.isActive || (h.isAdminOnly && shouldHideFields)) return null;

        return (
          <TableCell
            scope={idx ? undefined : "row"}
            align={idx ? "right" : undefined}
            key={idx}
          >
            {h.format(equipment)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default EqpAlertRow;
