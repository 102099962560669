import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Equipment } from "../../../pages/Equipments/types";
import { PaginationMeta } from "../../../types/api";

export const initialState: {
  equipment: Equipment[];
  pagination: PaginationMeta;
  loading: boolean;
} = {
  equipment: [],
  pagination: { total: 0, page: 0, pageSize: 0, pageCount: 0 },
  loading: false,
};

export const equipmentSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFetchedEquipment: (
      state,
      action: PayloadAction<{
        equipment: Equipment[];
        pagination: PaginationMeta;
      }>
    ) => {
      const { equipment, pagination } = action.payload;
      state.equipment = equipment;
      state.pagination = pagination;
      state.loading = false;
    },
  },
});

export const { setLoading, setFetchedEquipment } = equipmentSlice.actions;
export const selectEquipment = (state: RootState) => state.equipment;

export default equipmentSlice.reducer;
