import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { CustomAlertProps } from "../../../pages/Settings/CustomAlertsSection/types";

export const initialCustomAlertState: CustomAlertProps[] = [];

export const userSlice = createSlice({
  name: "setCustomAlerts",
  initialState: initialCustomAlertState,
  reducers: {
    setCustomAlerts: (state, action: PayloadAction<CustomAlertProps[]>) => {
      return action.payload;
    },
  },
});

export const { setCustomAlerts } = userSlice.actions;
export const selectCustomAlerts = (state: RootState) => state.customAlerts;

export default userSlice.reducer;
