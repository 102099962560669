import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";

import MuiLink from "../components/MuiLink";

import { CHECK_CODE, RESET_PASSWORD } from "../../redux/reducers/user/graphql";
import { ROUTES } from "../../Router";
import { CircularProgress } from "@mui/material";

const theme = createTheme();

const SignIn = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  const [signInErr, setSignInErr] = React.useState<string>("");
  const [codeOk, setCodeOk] = React.useState<boolean>(false);

  const [checkCodeReq, { loading }] = useLazyQuery(CHECK_CODE, {
    onError: (e) => {
      setSignInErr(e.message);
    },
    onCompleted: (data) => {
      if (data.checkCode === "ok") {
        setCodeOk(true);
        setSignInErr("");
      } else {
        setCodeOk(false);
        console.error("data", data);
      }
    },
  });

  React.useEffect(() => {
    checkCodeReq({
      variables: {
        code,
      },
    });
  }, [checkCodeReq, code]);

  const [resetPasswordReq] = useMutation(RESET_PASSWORD, {
    onError: (e) => {
      setSignInErr(e.message);

      console.error(e);
    },
    onCompleted: () => {
      navigate(ROUTES.SIGN_IN);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const password = data.get("password");
    const passwordConfirmation = data.get("passwordConfirmation");

    if (password !== passwordConfirmation) {
      setSignInErr("Passwords do not match");
      return;
    }

    resetPasswordReq({
      variables: {
        password,
        passwordConfirmation,
        code,
      },
    });
  };

  const renderForm = () => {
    if (loading) {
      return (
        <Box
          style={{
            minHeight: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (!codeOk) {
      return (
        <>
          <Typography component="h2" variant="body1" mt={1}>
            The reset password link is invalid or has expired.
          </Typography>
          <Typography component="h2" variant="body1">
            <MuiLink variant="body2" to={ROUTES.FORGOT_PASSWORD}>
              Click here
            </MuiLink>
            {" to request a new one."}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography component="h2" variant="body1">
          Enter your new password below.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordConfirmation"
            label="Confirm Password"
            type="password"
            id="passwordConfirmation"
          />

          {Boolean(signInErr) && (
            <Typography style={{ color: "red" }}>{signInErr}</Typography>
          )}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
            Reset
          </Button>
        </Box>
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset My Password
          </Typography>
          {renderForm()}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
