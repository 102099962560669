import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    color: {
      primary: string;
      primaryLight: string;
      secondary: string;
      secondaryLight: string;
      secondaryA100: string;
      secondaryA200: string;
    };
  }

  interface ThemeOptions {
    color?: {
      primary?: string;
      primaryLight?: string;
      secondary?: string;
      secondaryLight?: string;
      secondaryA100?: string;
      secondaryA200?: string;
    };
  }
};

const theme = createTheme({
  color: {
    primary: '#5f3e97',
    primaryLight: '#bdaeec',
    secondary: '#065c5d',
    secondaryLight: '#0c989e',
    secondaryA100: '#6ec6ca',
    secondaryA200: '#bdaeec',
  },
  palette: {
    primary: {
      main: '#5f3e97',
      light: '#bdaeec'
    },
    secondary: {
      main: '#065c5d',
      light: '#0c989e',
      A100: '#6ec6ca',
    }
  },
});

export default theme;
