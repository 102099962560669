import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { FORGOT_PASSWORD } from "../../redux/reducers/user/graphql";
import { ROUTES } from "../../Router";
import MuiLink from "../components/MuiLink";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";

const theme = createTheme();

const ForgotPassword = () => {
  const { logEvent } = useLogger();
  const navigate = useNavigate();

  const [signInErr, setSignInErr] = React.useState<string>("");

  const [resetRequest] = useMutation(FORGOT_PASSWORD, {
    onError: (e) => {
      setSignInErr(e.message);
      console.error(e);
    },
    onCompleted: () => {
      logEvent(LogType.USER, { message: "Reset password request" });
      navigate(ROUTES.FORGOT_PASSWORD_CHECK_EMAIL);
    },
  });

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;

    return re.test(email);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    if (!email) {
      setSignInErr("Email is required");
    } else if (!validateEmail(email as string)) {
      setSignInErr("Invalid email");
    }

    resetRequest({
      variables: {
        email,
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset my password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            {Boolean(signInErr) && (
              <Typography style={{ color: "red" }}>{signInErr}</Typography>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
              Send password recovery email
            </Button>
            <MuiLink variant="body2" to={ROUTES.SIGN_IN}>
              Sign in with password
            </MuiLink>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ForgotPassword;
