import moment from "moment";

import { Equipment, EquipmentTableHeaders } from "./types";
import { DefaultHeaderTitles } from "../../types/table";
import HighlightedText from "./components/HighlightedText";

export const TABLE_DATE_FORMAT = "DD-MMM-YYYY";

export const EQUIPMENT_HEADERS: EquipmentTableHeaders = [
  {
    id: 1,
    title: DefaultHeaderTitles.EID,
    format: (x: Equipment) => (
      <HighlightedText
        isFieldAdminOnly={
          x.default_fields_config?.[DefaultHeaderTitles.EID]?.admin_only
        }
      >
        {x.eid}
      </HighlightedText>
    ),
    isDefaultActive: true,
    isActive: true,
    isAdminOnly: false,
  },
  {
    id: 2,
    title: DefaultHeaderTitles.TYPE,
    format: (x: Equipment) => (
      <HighlightedText
        isFieldAdminOnly={
          x.default_fields_config?.[DefaultHeaderTitles.TYPE]?.admin_only
        }
      >
        {x.type}
      </HighlightedText>
    ),
    isDefaultActive: true,
    isActive: true,
    isAdminOnly: false,
  },
  {
    id: 3,
    title: DefaultHeaderTitles.MANUFACTURER,
    format: (x: Equipment) => (
      <HighlightedText
        isFieldAdminOnly={
          x.default_fields_config?.[DefaultHeaderTitles.MANUFACTURER]
            ?.admin_only
        }
      >
        {x.manufacturer}
      </HighlightedText>
    ),
    isDefaultActive: true,
    isActive: true,
    isAdminOnly: false,
  },
  {
    id: 4,
    title: DefaultHeaderTitles.DATE,
    format: (x: Equipment) => {
      const formattedDate = !!x.date_of_manufacture
        ? moment(x.date_of_manufacture).format(TABLE_DATE_FORMAT)
        : "";
      return (
        <HighlightedText
          isFieldAdminOnly={
            x.default_fields_config?.[DefaultHeaderTitles.DATE]?.admin_only
          }
        >
          {formattedDate}
        </HighlightedText>
      );
    },
    isDefaultActive: true,
    isActive: true,
    isAdminOnly: false,
  },
  {
    id: 5,
    title: DefaultHeaderTitles.SERIAL,
    format: (x: Equipment) => (
      <HighlightedText
        isFieldAdminOnly={
          x.default_fields_config?.[DefaultHeaderTitles.SERIAL]?.admin_only
        }
      >
        {x.serial_number}
      </HighlightedText>
    ),
    isDefaultActive: true,
    isActive: true,
    isAdminOnly: false,
  },
  {
    id: 6,
    title: DefaultHeaderTitles.PART_NUMBER,
    format: (x: Equipment) => (
      <HighlightedText
        isFieldAdminOnly={
          x.default_fields_config?.[DefaultHeaderTitles.PART_NUMBER]?.admin_only
        }
      >
        {x.part_number}
      </HighlightedText>
    ),
    isDefaultActive: true,
    isActive: true,
    isAdminOnly: false,
  },
  {
    id: 7,
    title: DefaultHeaderTitles.MATERIAL,
    format: (x: Equipment) => (
      <HighlightedText
        isFieldAdminOnly={
          x.default_fields_config?.[DefaultHeaderTitles.MATERIAL]?.admin_only
        }
      >
        {x.construction_material}
      </HighlightedText>
    ),
    isDefaultActive: true,
    isActive: true,
    isAdminOnly: false,
  },
];

export const DEFAULT_FIELDS_MAP = {
  [DefaultHeaderTitles.EID]: "eid",
  [DefaultHeaderTitles.TYPE]: "type",
  [DefaultHeaderTitles.MANUFACTURER]: "manufacturer",
  [DefaultHeaderTitles.SERIAL]: "serial_number",
  [DefaultHeaderTitles.PART_NUMBER]: "part_number",
  [DefaultHeaderTitles.MATERIAL]: "construction_material",
  [DefaultHeaderTitles.DATE]: "date_of_manufacture",
} as const;

/**
 * Based on `getConditionsForType` function return values
 */
export const CONDITIONS_MAP = {
  equals: "eq",
  startsWith: "startsWith",
  endsWith: "endsWith",
  contains: "containsi",
  before: "lt",
  beforeOrOn: "lte",
  on: "eq",
  onOrAfter: "gte",
  after: "gt",
  lessThan: "lt",
  lessThanOrEqual: "lte",
  equal: "eq",
  greaterThanOrEqual: "gte",
  greaterThan: "gt",
  is: "eq",
} as const;
