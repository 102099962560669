import { useLazyQuery } from "@apollo/client";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import * as XLSX from "xlsx";

import { GET_ANALYTICS_IN_DATE_RANGE } from "./graphql";
import { useAppSelector } from "../../redux/hooks";
import { selectUserState } from "../../redux/reducers/user";
import { Organization } from "../Equipments/types";
import moment from "moment";
import { useLogger } from "../../hooks/useLogger";
import { LogType } from "../../types/logger";

const ExportEqSection = () => {
  const { logEvent } = useLogger();

  const { user } = useAppSelector(selectUserState);
  const selectedOrg: Organization | undefined = user?.organizations?.find(
    (org) => org.id === user?.selected_organization_id
  );

  const [getAnalyticsInRange] = useLazyQuery(GET_ANALYTICS_IN_DATE_RANGE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const analyticsRows = data.analytics.data.map((scanRaw: any) => {
        const scan = {
          id: scanRaw.id,
          ...scanRaw.attributes,
          eid: scanRaw.attributes.equipment.data?.attributes.eid || "",
        };

        return {
          ID: scan.id,
          "Equipment ID": scan.eid,
          Country: scan.country,
          Region: scan.region,
          Timezone: scan.timezone,
          City: scan.city,
          Latitude: scan.latitude,
          Longitude: scan.longitude,
          Metro: scan.metro,
          Area: scan.area,
          Device: scan.device,
          Browser: scan.browser,
          IP: scan.ip,
          "Created At": moment(scan.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        };
      });

      logEvent(LogType.ACTION, { message: "Analytics export" });

      try {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(analyticsRows);

        XLSX.utils.book_append_sheet(wb, ws, "Analytics");
        XLSX.writeFile(wb, "analytics.xlsx");
      } catch (error) {
        console.error("error", error);
      }
    },
  });

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h6">
              Export Analytics
            </Typography>
            <Button
              variant="contained"
              disabled={!selectedOrg?.export_analytics || false}
              onClick={() => {
                getAnalyticsInRange({
                  variables: {
                    startDate: "2021-07-01T13:05:19.653Z",
                    endDate: "2024-07-31T13:05:19.653Z",
                  },
                });
              }}
            >
              Export
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ExportEqSection;
