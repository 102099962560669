import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const ConfirmDialog: React.FC<{
  confirmHandler: () => void,
  openModal: boolean,
  setOpenModal: (open: boolean) => void,
  text: string,
}> = props => {
  const { confirmHandler, openModal, setOpenModal, text } = props

  return (
    <Dialog open={openModal} onClose={() => { setOpenModal(false) }} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm the action</DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={event => {
          event.stopPropagation();
          setOpenModal(false);
        }} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={event => {
          event.stopPropagation();
          confirmHandler();
        }} color="error" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;