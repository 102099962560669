import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import { UserState, User } from "./types";
import { ProcessArea } from "../../../pages/Settings/ProcessAreasAndRolesSection/types";

export const initialUserState: UserState = {
  jwt: null,
  user: null,
  status: "loading",
  errMsg: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setJWT: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
      state.status = "loading";
      localStorage.setItem("token", action.payload);
    },
    setUser: (state, action: PayloadAction<User>) => {
      const writeProcessAreasMap: { [key: string]: ProcessArea } = {};

      action.payload.process_area_roles?.forEach((role) => {
        role.process_areas?.forEach((processArea) => {
          if ((role.access as any) === "read-write") {
            writeProcessAreasMap[processArea.id] = processArea;
          }
        });
      });

      action.payload.writeProcessAreasMap = writeProcessAreasMap;
      state.user = action.payload;
      state.status = "idle";
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setUserErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.status = "error";
    },
    signOut: (state) => {
      state = initialUserState;
      localStorage.clear();
      window.location.reload();
    },
  },
});

export const { setJWT, setUser, setUserErrMsg, signOut } = userSlice.actions;
export const selectUserState = (state: RootState) => state.user;

export default userSlice.reducer;
