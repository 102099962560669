import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
    }
  }
`;

export const GET_CURRENT_USER = gql`
  {
    me {
      id
      username
      email
      confirmed
      blocked
      role {
        id
        type
        name
      }
    }
  }
`;

export const CHECK_CODE = gql`
  query CheckCode($code: String!) {
    checkCode(code: $code)
  }
`;
