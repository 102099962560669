import moment from "moment";

const SECONDS_IN_DAY = 24 * 60 * 60;
const MS_IN_DAY = SECONDS_IN_DAY * 1000;

// Excel stores dates as the number of days since 1/1/1900
// Unix time is the number of seconds since 1/1/1970
// From Jan 1 1900 to Jan 1 1970 is 70 years + 17 leap days
const EXCEL_UNIX_DIFF = 25569;

export const formatExcelDate = (excelDate: number): string => {
  try {
    const date = new Date(
      Math.round((excelDate - EXCEL_UNIX_DIFF) * MS_IN_DAY)
    );
    const dateString = moment(date).format("YYYY-MM-DD");

    if (dateString === "Invalid date") {
      return "";
    }

    return dateString;
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const getEqFieldVal = (eq: any, field: any) => {
  let value = eq[field.name];

  if (!value) {
    return '';
  }

  if (value) {
    if (field.type === "Date") {
      value = formatExcelDate(value);
    } else if (field.type === "Checkbox") {
      value = String(value).toLocaleLowerCase() === "true" ? "true" : "false";
    }
  }

  return String(value);
};
